import { CSSProperties, useMemo } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { Card } from '../Card'
import { theme } from '~/utils'

type IconTextListItem = {
  icon: JSX.Element,
  text: JSX.Element
}

export type HomeBannerProps = {
  backgroundImage?: string
  backgroundColor?: CSSProperties['backgroundColor']
  category: {
    text: string,
    icon: JSX.Element
    color?: string
  },
  title: JSX.Element
  description?: JSX.Element
  iconTextList?: IconTextListItem[]
  rightElement?: JSX.Element | null
  buttonsContainer?: JSX.Element
}

export const HomeBanner: React.FC<HomeBannerProps> = ({ backgroundImage, category, title, description, iconTextList, rightElement, backgroundColor, buttonsContainer }) => {
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const iconTextItemsList = useMemo(() => {
    return iconTextList?.map(item => {
      return (
        <Box key={item.text.toString()} sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', color: theme.palette.grey['400'] }}>
          {isLowerSm ? null : item.icon}
          <Typography sx={{ fontWeight: 400, fontSize: isLowerSm ? '.9rem' : '1rem' }}>
            {item.text}
          </Typography>
        </Box>
      )
    })
  }, [iconTextList, isLowerSm])

  return (
    <Card sx={{
      width: `100%`,
      minHeight: '500px',
      padding: isLowerSm ? '2rem 1rem' : '3rem 2rem',
      border: `1px solid ${theme.palette.grey['300']}`,
      background: `${backgroundColor || '#FFF'} url(${backgroundImage}) ${isLowerMd ? 'center left' : 'center right'}/1160px no-repeat`,
    }}>
      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          flex: 1,
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', color: category.color || theme.palette.grey['400'], textTransform: 'uppercase' }}>
            {category.icon}
            <Typography sx={{ fontWeight: 500, fontSize: '.95rem' }}>{category.text}</Typography>
          </Box>

          <Box sx={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
            {title}
            {description}
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
            {iconTextItemsList}
          </Box>

          {buttonsContainer}
        </Box>

        {!isLowerMd && rightElement && (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}>
            {rightElement}
          </Box>
        )}
      </Box>
    </Card>
  )
}
