import styled from '@emotion/styled'
import { CSSProperties } from 'react'

export type ContainerProps = {
  padding?: CSSProperties['padding']
}
export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${(props) => props?.padding ?? '2rem 0'};
  gap: 1rem;
  flex-wrap: wrap;

  & > div {
    display: flex;
    flex-flow: column;
    gap: .4rem;
  }
`
