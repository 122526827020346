import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getToken } from '~/utils/token'

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_APP_GRAPHQL_API_URL
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  name: 'Desperta Energia',
  version: '1.0',
  link: authLink.concat(httpLink)
})

export type IQueryGql<T> = {
  data: T
}
