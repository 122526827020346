import { useState } from 'react'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { PropsWithWizard } from '~/components'
import { UnifyEnergyBillLayout } from '../UnifyEnergyBillLayout'

export const UnifyEnergyBillAutomaticDebit: React.FC<PropsWithWizard> = (props) => {
  const [isAutomaticDebitActive, setAutomaticDebit] = useState(props.additionalParams?.isAutomaticDebitActive || false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutomaticDebit(event.target.value === 'true')
  }

  return (
    <UnifyEnergyBillLayout
      illustration='WomanTouchingFloatingPhone'
      backButton={{ onClick: () => props.onPrev(), title: 'Voltar', icon: <IconArrowLeft /> }}
      nextButton={{ onClick: () => props.onNext({ isAutomaticDebitActive }), title: 'Continuar', icon: <IconArrowRight /> }}
      {...props}
    >
      <Box sx={{
        gap: 2,
        display: 'flex',
        flexDirection: 'column'
      }}>

        <Typography>
          Para unificar as faturas, sua conta de luz <b>não pode</b> estar em <b>débito automático</b> em seu banco.
        </Typography>

        <Typography>Sua conta está em débito automático?</Typography>

        <FormControl>
          <RadioGroup
            defaultValue={false}
            name='automaticDebit'
            onChange={handleChange}
            value={isAutomaticDebitActive}
          >
            <FormControlLabel value={false} control={<Radio />} label='Não possuo débito automático' />
            <FormControlLabel value={true} control={<Radio />} label='Sim, utilizo débito automático' />
          </RadioGroup>
        </FormControl>
      </Box>
    </UnifyEnergyBillLayout>
  )
}
