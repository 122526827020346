import { SvgIcon, SvgIconProps } from '@mui/material'

export const BarcodeIcon: React.FC<SvgIconProps> = ({ width, height, fill, ...props }) => {
  return (
    <SvgIcon width={width || 20} height={height || 20} viewBox='0 0 20 20' fill={fill || 'none'} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M4.6875 3.125C5.20527 3.125 5.625 3.54473 5.625 4.0625L5.625 16.5625C5.625 17.0803 5.20527 17.5 4.6875 17.5C4.16973 17.5 3.75 17.0803 3.75 16.5625L3.75 4.0625C3.75 3.54473 4.16973 3.125 4.6875 3.125Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M1.875 3.125C2.22018 3.125 2.5 3.40482 2.5 3.75L2.5 16.875C2.5 17.2202 2.22018 17.5 1.875 17.5C1.52982 17.5 1.25 17.2202 1.25 16.875L1.25 3.75C1.25 3.40482 1.52982 3.125 1.875 3.125Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M8.125 3.125C8.81536 3.125 9.375 3.68464 9.375 4.375L9.375 16.25C9.375 16.9404 8.81536 17.5 8.125 17.5C7.43464 17.5 6.875 16.9404 6.875 16.25L6.875 4.375C6.875 3.68464 7.43464 3.125 8.125 3.125Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M11.25 3.125C11.5952 3.125 11.875 3.40482 11.875 3.75L11.875 16.875C11.875 17.2202 11.5952 17.5 11.25 17.5C10.9048 17.5 10.625 17.2202 10.625 16.875L10.625 3.75C10.625 3.40482 10.9048 3.125 11.25 3.125Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M17.8125 3.125C18.3303 3.125 18.75 3.54473 18.75 4.0625L18.75 16.5625C18.75 17.0803 18.3303 17.5 17.8125 17.5C17.2947 17.5 16.875 17.0803 16.875 16.5625L16.875 4.0625C16.875 3.54473 17.2947 3.125 17.8125 3.125Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M14.375 3.125C15.0654 3.125 15.625 3.68464 15.625 4.375L15.625 16.25C15.625 16.9404 15.0654 17.5 14.375 17.5C13.6846 17.5 13.125 16.9404 13.125 16.25L13.125 4.375C13.125 3.68464 13.6846 3.125 14.375 3.125Z' fill='#999999' />
    </SvgIcon>
  )
}
