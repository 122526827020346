import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Layout, PageLoader } from './components'

import {
  ContractProvider,
  useAuth, useContract,
} from './contexts'

import {
  Dashboard,
  Home,
  Login,
  RecoverPassword,
  Wallet,
  FirstAccess,
  UnifiedEnergyBill
} from './pages'
import { ContractList } from './pages/ContractList'
import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'

const PrivateRoute = () => {
  const { authUser, getUserIsLoading } = useAuth()

  if(getUserIsLoading) return <PageLoader />

  if (authUser) return <Outlet />

  return <Navigate to='/' />
}

const ContractGuardRoute = () => {
  const { selectedContract, contractsIsLoading } = useContract()

  if(contractsIsLoading) return <PageLoader />

  if (selectedContract) return <Outlet />

  return <Navigate to='/app/contracts' />
}

export const App = () => {
  
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/first-access' element={<FirstAccess />} />
      <Route path='/recover-password' element={<RecoverPassword />} />
      <Route path='/teste' element={<PageLoader />} />

      <Route path='/app' element={
        <ContractProvider>
          <Layout>
            <PrivateRoute />
          </Layout>
        </ContractProvider>
      }>

        <Route path='contracts' element={<ContractList />} />

        <Route path='contracts/:contractId/*' element={<ContractGuardRoute />}>

          <Route index element={<Home />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='wallet' element={<Wallet />} />
          <Route path='unified-energy-bill' element={<UnifiedEnergyBill />} />

          <Route path='*' element={<Navigate to='/app' />} />
        </Route>
      </Route>

    </Routes>
  )
}
