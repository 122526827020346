import { IconHomeBolt } from '@tabler/icons-react'
import { Container ,Content } from './styles'
import { Box, Typography } from '@mui/material'
import { formatAddressToText, theme } from '~/utils'
import { useContract } from '~/contexts'
import { ContractData } from '~/contexts/contract/types'

type ContractCardProps = {
  contract: ContractData
  $selected?: boolean,
}

export const ContractCard: React.FC<ContractCardProps> = ({ contract, $selected = false }) => {
  const { setSelectedContract } = useContract()

  return (
    <Container onClick={() => setSelectedContract(contract)} $selected={$selected}>
      <Content>
        <Box sx={{
          width: '44px',
          height: '44px',
          background: theme.palette.secondary.light,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '.4rem',
        }}>
          <IconHomeBolt color={theme.palette.secondary.main} />
        </Box>
        <Typography color={theme.palette.grey['700']} variant='h6' component='h2'>
          {contract.energyBillInfo?.name || `${contract.energyBillInfo?.dealershipName} - ${contract.energyBillInfo?.clientNumber}`}
        </Typography>
        <Typography color={theme.palette.grey['400']} component='span'>{formatAddressToText(contract.energyBillInfo?.address)}</Typography>
      </Content>
    </Container>
  )
}
