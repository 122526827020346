import './utils/yup'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { ApolloProvider } from '@apollo/client'
import { client } from './services/graphql'
import * as Sentry from '@sentry/react'
import { theme } from './utils/theme'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { ErrorFallback } from './components/ErrorFallback'
import { SidebarProvider } from './contexts'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '~/contexts'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { hotjar } from 'react-hotjar'
import { clarity } from 'react-microsoft-clarity'
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/app.despertaenergia.\.com/]
    }),
    new Sentry.Replay()
  ],
  release: 'customer@1.0.0',
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_APP_ENV
})

if (process.env.VITE_APP_ENV === 'prod') {
  clarity.init('nbfysr6wm9')
}

if (process.env.VITE_APP_ENV === 'prod') {
  hotjar.initialize({ id: Number(process.env.VITE_PUBLIC_HOTJAR_HJID), sv: Number(process.env.VITE_PUBLIC_HOTJAR_HJSV) })
}


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <AuthProvider>
              <SidebarProvider>
                <App />
                <ToastContainer />
              </SidebarProvider>
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  </React.StrictMode>,
)
