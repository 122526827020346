import { ReactElement } from 'react'
import { Box } from '@mui/material'
import { theme } from '~/utils'

type TableHeaderApiProps = {
  inputFilters?: {
    key: string,
    placeholder?: string,
    icon?: ReactElement
  }[]
  startElement?: ReactElement
  endElement?: ReactElement
  styles?: {
    hasBorderTop?: boolean
    hasBorderBottom?: boolean
  }
}

const defaultStyles = {
  hasBorderTop: true,
  hasBorderBottom: true,
}

export const TableHeaderApi: React.FC<TableHeaderApiProps> = ({ endElement, startElement, styles = defaultStyles }) => {

  return (
    <Box
      borderTop={styles.hasBorderTop ? `1px solid ${theme.palette.grey[300]}` : ''}
      borderBottom={styles.hasBorderBottom ? `1px solid ${theme.palette.grey[300]}` : ''}
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      padding='2rem 0'
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
        {startElement && startElement}
      </Box>

      {endElement && endElement}

    </Box>
  )
}
