import { useEffect, useState } from 'react'

/**
 * @param value digit value
 * @param delay time to execution handle function in milisseconds.
 * @example useDebounce('123123', 2000)
 */
export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay]
  )
  return debouncedValue
}

