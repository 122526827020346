import { Circle } from '@mui/icons-material'
import { matchPath, useLocation, Link } from 'react-router-dom'
import { ListItem, ListItemButton, ListItemIcon, ListItemProps, ListItemText, Typography, useMediaQuery, Box } from '@mui/material'
import { theme } from '~/utils'
import { useSidebar } from '~/contexts'
import { MouseEventHandler } from 'react'

export type SidebarListItemProps = Omit<ListItemProps, 'onClick'> & {
  isDrawerOpen: boolean,
  text: string
  path?: string
  onClick?: MouseEventHandler<HTMLLIElement> | undefined
}

export const CollapsibleListItem: React.FC<SidebarListItemProps> = ({ isDrawerOpen, text, sx, path, ...props }) => {
  const { pathname, search } = useLocation()
  const currentPathPattern = matchPath({ path: path || '' }, `${pathname}${search}`)
  const matches = Boolean(currentPathPattern)
  const { toggleSidebar } = useSidebar()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <ListItem
      disablePadding sx={{ display: 'block', ...sx }}
      {...props}
    >
      {props.onClick ? (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2.5,
          minHeight: 48,
          justifyContent: isDrawerOpen ? 'initial' : 'center',
          cursor: 'pointer'
        }}>

          <ListItemIcon
            sx={{
              minWidth: 0,
              alignItems: 'center',
              justifyContent: 'center',
              mr: isDrawerOpen ? 3 : 'auto',
              ml: '8px'
            }}
          >
            <Circle style={{ fontSize: matches ? 8 : 6, color: matches ? theme.palette.yellow.main : theme.palette.grey['400'] }} />
          </ListItemIcon>
          <ListItemText primary={<Typography fontWeight={matches ? 600 : 400} variant='body2'>{text}</Typography>} sx={{ opacity: isDrawerOpen ? 1 : 0 }} />
        </Box>
      ) : (
        <ListItemButton
          onClick={isMobile ? toggleSidebar : undefined}
          component={Link}
          to={path || ''}
          sx={{
            px: 2.5,
            minHeight: 48,
            justifyContent: isDrawerOpen ? 'initial' : 'center',
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              alignItems: 'center',
              justifyContent: 'center',
              mr: isDrawerOpen ? 3 : 'auto',
              ml: '8px'
            }}
          >
            <Circle style={{ fontSize: matches ? 8 : 6, color: matches ? theme.palette.yellow.main : theme.palette.grey['400'] }} />
          </ListItemIcon>
          <ListItemText primary={<Typography fontWeight={matches ? 600 : 400} variant='body2'>{text}</Typography>} sx={{ opacity: isDrawerOpen ? 1 : 0 }} />
        </ListItemButton>
      )}
   
    </ListItem>
  )
}
