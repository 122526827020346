import { Box, Button, Divider, Typography } from '@mui/material'
import { Banner, Card, ContentTitle } from '~/components'
import { StatusCard } from './components'
import { Link, useNavigate } from 'react-router-dom';
import { IconArrowLeft, IconHeart, IconInfoCircleFilled } from '@tabler/icons-react';
import { useContract } from '~/contexts';
import { useTheme } from '@emotion/react';

export const UnifiedEnergyBill: React.FC = () => {
  const theme = useTheme()
  const {selectedContract} = useContract()
  const isActive = selectedContract?.singleAccount?.active
  return (
    <>
      <Card>
        <ContentTitle
          title='Conta única'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          description='Ative e desative a opção de pagar em conta única'
          breadcrumbLinks={{ currentLink: 'Conta Única', links: [{ href: '/app', label: 'Configurações' }] }}
        />

        <Divider sx={{ marginBottom: 5 }} />
        {!isActive && <Box sx={{ backgroundColor: '#F7EDFF', borderRadius: '12px', padding: '24px 32px', marginBottom: 5, display: 'flex', flexFlow: 'column', gap: 1 }}>
          <Box color={'#561885'} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconHeart /> 
            <Typography variant='body1' fontWeight={600} sx={{textTransform: 'uppercase'}}>UNIFIQUE E SIMPLIFIQUE</Typography>
          </Box>
          <Typography variant='subtitle1' fontWeight={400} sx={{color: '#434343'}}>Você pode unificar a fatura da sua concessionária de energia com sua fatura da Desperta em uma única conta!</Typography>
        </Box>}
        

        <Typography variant='h3' fontWeight={500} sx={{ marginBottom: 3 }}>Status atual</Typography>

        <StatusCard />`
        
        
        {isActive && (
          <Banner icon={<IconInfoCircleFilled size={36} color={theme.palette.primary.main} />} type='warning' text='Em alguns meses, se não for possível unificar suas faturas, você terá que pagá-las separadamente. Avisaremos se isso for necessário.' />
        )}
        <Link to={`/app/contracts/${selectedContract?._id}`} >
        <Button color='secondary' sx={{width: 'fit-content', marginTop: 5}} startIcon={<IconArrowLeft />}>
              Voltar
          </Button>
        </Link>
      </Card>

      
    </>
  )
}
