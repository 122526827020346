import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import { IconHomeBolt, IconRefresh } from '@tabler/icons-react'
import { useContract } from '~/contexts'
import { theme } from '~/utils'
import { Card } from '../Card'
import { Link } from 'react-router-dom'

export const ChangeContract: React.FC = () => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const { selectedContract } = useContract()

  return (
    <Card sx={{ marginBottom: '2rem', padding: '1rem 1.6rem', border: `1px solid ${theme.palette.grey[300]}` }}>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexFlow: isLowerSm ? 'column' : 'row', gap: '1rem' }}>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Box sx={{
            width: '44px',
            height: '44px',
            background: theme.palette.secondary.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '.4rem',
          }}>
            <IconHomeBolt color={theme.palette.secondary.main} />
          </Box>
          <Box sx={{ display: 'flex', flexFlow: 'column' }}>
            <Typography sx={{ fontSize: '.9rem', fontWeight: 500 }} color={theme.palette.secondary.main} component='span'>Unidade consumidora</Typography>
            <Typography sx={{ fontSize: '1rem', fontWeight: 500 }} component='strong'>
              {selectedContract?.energyBillInfo?.name || `${selectedContract?.energyBillInfo?.dealershipName} - ${selectedContract?.energyBillInfo?.clientNumber}`}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ minWidth: 'fit-content' }}>
          <Link to='/app/contracts'>
            <Button size={isLowerSm ? 'small' : 'medium'} color='secondary' endIcon={<IconRefresh />}>Alterar UC</Button>
          </Link>
        </Box>
      </Box>
    </Card>
  )
}
