import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { IconHandFinger, IconHeart, IconPigMoney } from '@tabler/icons-react'
import { theme } from '~/utils'
import { HomeBanner } from '~/components'
import { UnifiedEnergyBill } from '~/assets/illustrations'

export type UnifyEnergyBillSlideProps = {
  toggleModal: () => void
}

export const UnifyEnergyBillSlide: React.FC<UnifyEnergyBillSlideProps> = ({ toggleModal }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <HomeBanner
      backgroundColor='#F7EDFF'
      iconTextList={[
        {
          icon: <IconHandFinger />,
          text: <Typography sx={{ fontWeight: 400, fontSize: isLowerSm ? '.9rem' : '1rem' }}>Basta um único pagamento</Typography>
        },
        {
          icon: <IconPigMoney />,
          text: <Typography sx={{ fontWeight: 400, fontSize: isLowerSm ? '.9rem' : '1rem' }}>Economize seu tempo</Typography>
        }
      ]}
      title={(
        <Typography sx={{ fontSize: isLowerSm ? '1.4rem' : '2.2rem', color: theme.palette.grey['800'], lineHeight: isLowerSm ? '1.5' : '50px' }}>
          Unifique suas contas e pague suas {!isLowerSm && <br />}faturas em uma só!
        </Typography>
      )}
      description={(
        <Typography sx={{ fontSize: '1rem', color: theme.palette.grey['800'] }}>
          Agora você pode unificar a fatura da sua concessionária de energia {!isLowerSm && <br />}com sua fatura da Desperta para um único pagamento!
        </Typography>
      )}
      category={{
        text: 'Unifique e simplifique',
        icon: <IconHeart color='#561885' />,
        color: '#561885'
      }}
      buttonsContainer={(
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Button onClick={toggleModal}>UNIFICAR MINHAS CONTAS</Button>
        </Box>
      )}
      rightElement={isLowerLg ? null : <UnifiedEnergyBill style={{ height: 300, width: 420 }} />}
    />
  )
}
