/* eslint-disable no-unused-vars */
import { Column, TableInstance } from 'react-table'
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Table as MuiTable,
  Paper, Select,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material'
import { IconArrowUp, IconArrowDown, IconChevronRight, IconChevronLeft, IconChevronsRight, IconChevronsLeft } from '@tabler/icons-react'
import { theme } from '~/utils'
import { CSSProperties, useEffect } from 'react'

type TableProps = {
  columns: Column[],
  onRowClick?: (row: any) => void
  tableMethods: TableInstance
  tableLayout?: CSSProperties['tableLayout']
  options?: {
    clearRowSelectionOnPagination?: boolean,
    itemsPerPageValues?: number[]
    lastColumnAlignRight?: boolean,
  }
}

const defaultOptions = {
  clearRowSelectionOnPagination: false,
  itemsPerPageValues: [10, 20, 30, 40, 50],
  lastColumnAlignRight: true
}

export function Table({ tableMethods, onRowClick, options = defaultOptions, tableLayout = 'fixed' }: TableProps) {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    getTableBodyProps,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize } }
    = tableMethods

  useEffect(() => {
    if (options?.clearRowSelectionOnPagination && selectedFlatRows.length !== 0) {
      toggleAllRowsSelected(false)
    }
  }, [pageIndex])

  return (
    <>
      <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
        <MuiTable sx={{ tableLayout, width: '100%' }}  {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>

                {headerGroup.headers.map((column, i) => (
                  <TableCell
                    {...column.getHeaderProps(column?.getSortByToggleProps())}
                    key={column.getHeaderProps().key}
                    align={headerGroup.headers.length === i + 1 && options.lastColumnAlignRight ? 'right' : 'left'}
                    sx={{ width: column.id === 'selection' ? '30px' : '30%', color: theme.palette.grey[400], fontWeight: 600, padding: isLowerSm ? '1rem' : '1rem 0' }}
                  >
                    {column.render('Header')}
                    {column?.isSorted && (
                      <>
                        {column?.isSortedDesc ?
                          <IconArrowDown size={18} color={theme.palette.grey[500]} />
                          :
                          <IconArrowUp size={18} color={theme.palette.grey[500]} />
                        }
                      </>
                    )}
                    {column.canFilter ? column.render('Filter') : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()} key={row.getRowProps().key} onClick={onRowClick ? () => onRowClick(row.original) : undefined}>
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell
                        align={row.cells.length === i + 1 && options.lastColumnAlignRight ? 'right' : 'left'}
                        padding={row.cells.find(item => item.column.id === 'selection')?.column.id === 'selection' ? 'checkbox' : 'normal'}
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                        sx={{ padding: isLowerSm ? '1rem' : '.4rem 0' }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={99}>
                <Box display='flex' width='100%' justifyContent='space-between'>
                  <Box display='flex' alignItems='center' gap={1}>
                    <Typography>Linhas por pagina</Typography>
                    <FormControl size='small'>
                      <Select
                        defaultValue={pageSize}
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value))
                        }}>
                        {options.itemsPerPageValues?.map((optionSize) => (
                          <MenuItem key={optionSize} value={optionSize}>
                            {optionSize}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box display='flex' alignItems='center' gap={0}>
                    <Typography fontWeight={600} display='flex' paddingRight={2}>
                      {pageIndex + 1} - {pageOptions.length}
                    </Typography>
                    <IconButton
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                      aria-label='previous page'
                    >
                      {theme.direction === 'rtl' ? <IconChevronsLeft /> : <IconChevronsLeft />}
                    </IconButton>
                    <IconButton
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                      aria-label='previous page'
                    >
                      {theme.direction === 'rtl' ? <IconChevronLeft /> : <IconChevronLeft />}
                    </IconButton>
                    <IconButton
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                      aria-label='previous page'
                    >
                      {theme.direction === 'rtl' ? <IconChevronRight /> : <IconChevronRight />}
                    </IconButton>
                    <IconButton
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                      aria-label='previous page'
                    >
                      {theme.direction === 'rtl' ? <IconChevronsRight /> : <IconChevronsRight />}
                    </IconButton>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </MuiTable>
      </TableContainer>
    </>
  )
}
