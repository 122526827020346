import { IconExclamationCircle } from '@tabler/icons-react'
import { Box, Divider, Typography, useMediaQuery } from '@mui/material'
import { useContract } from '~/contexts'
import { theme } from '~/utils'
import { UnifyEnergyBillFlow } from '~/components'
import { ActionButtons } from '../ActionButtons'
import { useToggle } from '~/hooks'
import { CancelUnifiedEnergyBill } from '../CancelUnifiedEnergyBill'
import { useNavigate } from 'react-router-dom'

export const StatusCard: React.FC = () => {
  const { selectedContract } = useContract()
  const navigate = useNavigate();
  const isActive = selectedContract?.singleAccount?.active
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { isTrue: isVisibleUnify, toggle: toggleModalUnify, setIsTrue: setIsVisibleUnify } = useToggle()
  const { isTrue: isVisibleCancel, toggle: toggleModalCancel, setIsTrue: setIsVisibleCancel } = useToggle()
  return (
    <Box sx={{
      gap: 3,
      padding: 3,
      display: 'flex',
      borderRadius: '8px',
      border: '1px solid #E6E6E6',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      borderLeft: `8px solid ${isActive ? '#00A86B' : '#CDCDCD'}`
    }}>

      <Box sx={{
        gap: 3,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      }}>

        <Box sx={{
          display: 'flex',
          alignItems: isLowerSm ? 'start' : 'center',
          flexDirection: isLowerSm ? 'column' : 'row',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            
            <Box sx={{
              display: 'flex',
              padding: '4px 16px',
              alignItems: 'center',
              borderRadius: '8px',
              background: isActive ? '#C6F6D5' : '#E6E6E6'
            }}>
              
              <Typography variant='body1' fontWeight={500} style={{ color: isActive ? '#367154' : '#999' }}> {isActive ? 'Ativa' : 'Desativada'}</Typography>
            </Box>
          </Box>
          
        </Box>
          {isActive && 
          (<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <IconExclamationCircle size={24} color='#00A86B' />
          <Typography variant='body2' style={{color: '#999'}} maxWidth={600}>Isso significa que as contas da sua concessionária de energia e da Desperta
            chegam unificadas em uma única fatura.
          </Typography>
          </Box>)
          }

        <Divider />
        <ActionButtons toggleModalCancel={toggleModalCancel} toggleModalUnify={toggleModalUnify} />
        <CancelUnifiedEnergyBill setIsVisible={setIsVisibleCancel} isVisible={isVisibleCancel} toggleModal={toggleModalCancel} />
        <UnifyEnergyBillFlow setIsVisible={setIsVisibleUnify} isVisible={isVisibleUnify} toggleModal={toggleModalUnify} />
      </Box>
      
    </Box>
  )
}
