type FormatDateOptions = {
  dateHour?: boolean
}

export function formatDate(isoDate: string, options?: FormatDateOptions) {
  if(!isoDate) return ''
  const date = new Date(isoDate)
  if(options?.dateHour) {
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute:'2-digit',
      second:'2-digit' 
    }).format(date)
  } else {
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'UTC',
    }).format(date)
  }
}
