import { useState } from 'react'
import { IconCopy } from '@tabler/icons-react'
import { Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import { copyToClipboard } from '~/utils/clipboard'
import { InvoicePaymentModel } from '~/graphql/types'
import { formatMoney, theme, transformMoney } from '~/utils'

export type PixPaymentProps = {
  paymentInfo: InvoicePaymentModel & { amountCents: number }
}

export const PixPayment: React.FC<PixPaymentProps> = ({ paymentInfo }) => {
  const [loading, setLoading] = useState(true)
  const { pixQrcode, pixText, amountCents } = paymentInfo
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{
      gap: 3,
      display: 'flex',
      flexDirection: 'column'
    }}>

      <Typography variant={isLowerSm ? 'body2' : 'body1'}>Utilize o QR Code para efetuar o pagamento:</Typography>

      <Box sx={{
        padding: isLowerSm ? '0px' : '0px 120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <Box sx={{
          display: 'flex',
          borderRadius: 2,
          border: '8px solid black',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          justifyContent: 'center'
        }}>
          <CircularProgress sx={{ margin: '100px', display: loading ? 'block' : 'none' }} size={50} />
          <img onLoad={() => setLoading(false)} width='100%' src={pixQrcode || ''} style={{ display: loading ? 'none' : 'block' }} />
        </Box>
        <Box sx={{
          display: 'flex',
          padding: 1,
          border: '8px solid black',
          justifyContent: 'center',
          borderTop: 0,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          background: 'black'
        }}>
          <Typography variant={isLowerSm ? 'body2' : 'body1'} fontWeight={500} color='common.white'>ESCANEAR</Typography>
        </Box>
      </Box>

      <Box sx={{
        gap: 3,
        borderRadius: 2,
        display: 'flex',
        background: '#FAFAFA',
        padding: '16px 24px',
        border: '1px solid #E6E6E6',
        justifyContent: 'space-between'
      }}>
        <Typography variant={isLowerSm ? 'body2' : 'body1'} fontWeight={500}>Valor</Typography>
        <Typography variant={isLowerSm ? 'body2' : 'body1'} fontWeight={500}>{formatMoney(transformMoney(amountCents, 'toReal'))}</Typography>
      </Box>

      <Typography variant={isLowerSm ? 'body2' : 'body1'}>Código PIX</Typography>

      <Box sx={{
        padding: 2,
        borderRadius: 2,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: '1px solid #E6E6E6',
        overflowWrap: 'anywhere'
      }}>
        <Typography variant={isLowerSm ? 'body2' : 'body1'}>{pixText}</Typography>
      </Box>

      <Typography variant={isLowerSm ? 'body2' : 'body1'}>
        <b>IMPORTANTE:</b> Após o pagamento, vamos notifica-lo por e-mail para informar que seu pagamento foi confirmado.
      </Typography>

      <Button onClick={() => copyToClipboard(pixText || '')} startIcon={<IconCopy />} sx={{ alignSelf: isLowerSm ? 'inherit' : 'start' }}>Copiar código Pix</Button>
    </Box>
  )
}
