import { Divider, Typography, Box, Button, useMediaQuery, CircularProgress } from '@mui/material'
import { Dialog, Input   } from '~/components'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomerWithdrawRequestInputDto } from '~/graphql/types'
import { formatMoney, theme, transformMoney } from '~/utils'
import { useState } from 'react'
import { Ul } from './styles'

type SplitModalProps = {
  isVisible: boolean
  toggle: Function
  balance: number,
  // eslint-disable-next-line no-unused-vars
  withdrawRequest: (params: CustomerWithdrawRequestInputDto) => void
  withdrawIsLoading: boolean
}

export const SplitModal: React.FC<SplitModalProps> = ({ isVisible, toggle, balance, withdrawRequest, withdrawIsLoading }) => {
  const [splitData, setSplitData] = useState<null | any>(null)
  const formMethods = useForm()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog title='Saque de cashback' onClose={() => (toggle(), setSplitData(null))} isVisible={isVisible}>
      <Divider sx={{ margin: '0 0 1.2rem 0' }}/>

      {splitData ? (
        <>
          <Typography sx={{ padding: '0 0 1rem 0' }}>Informe os dados para seguir com a transferência:</Typography>
          
          <Ul>
            {/* <li>Tipo de saque: {translateSplitType(splitData.type)}</li> */}
            <li>Nome: Nome de cliente/empresa</li>
            <li>CPF: 000.000.000-00</li>
            <li>Banco do brasil S.A.</li>
            <li>Agência: 0483-2</li>
            <li>Conta: 77294-4</li>
            <li>Conta corrente</li>
            <li>Valor da transferência: {formatMoney(splitData.value)}</li>
          </Ul>

          <Typography sx={{ fontSize: '.85rem', paddingTop: '1rem' }}>Saldo de cashback restante: {formatMoney(transformMoney(balance, 'toReal') - splitData.value)}</Typography>

          <Divider sx={{ margin: '2rem 0' }} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
            <Button onClick={() => (toggle(), setSplitData(null))} color='secondary'>Cancelar</Button>
            <Button
              endIcon={withdrawIsLoading ? <CircularProgress size={24} color='inherit'  /> : <></>}
              disabled={withdrawIsLoading} 
              onClick={() => withdrawRequest({ value: transformMoney(splitData.value, 'toCents'), password: splitData.password })}
            >
              Concluir
            </Button>
          </Box>
        </>
      )

        : (
          <>
            <Typography sx={{ padding: '0 0 1rem 0' }} >Informe os dados para seguir com a transferência</Typography>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit((data) => setSplitData(data))}>
                {/* <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey['400'] }}>Tipo de saque:</Typography> */}
                {/* <Radio sx={{ paddingBottom: '1rem' }} row name='type' options={[{ value: 'basic', label: 'Saque básico' }, { value: 'early', label: 'Saque antecipado' }]} /> */}

                <Box sx={{ display: 'flex', flexFlow: 'column', gap: '1rem' }}>
                  <Input label='valor da transferência' mask='BRL' name='value' />
                  <Input label='Confirme sua senha' type='password' name='password' />
                </Box>

                <Typography sx={{ fontSize: '.85rem', paddingTop: '.4rem' }}>Saldo de cashback restante: {formatMoney(transformMoney(balance, 'toReal'))}</Typography>

                <Divider sx={{ margin: '2rem 0' }} />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
                  <Button onClick={() => (toggle(), setSplitData(null))} color='secondary'>Cancelar</Button>
                  <Button type='submit'>Próximo</Button>
                </Box>
              </form>
            </FormProvider>
          </>
        )}
    </Dialog>
  )
}
