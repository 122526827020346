import { Box } from '@mui/material'
import { ChangeContract, HomeBanners, InvoiceHistory } from '~/components'
import { useContract } from '~/contexts'

export const Home: React.FC = () => {
  const { contracts } = useContract()

  return (
    <Box padding={0} display='flex' flexDirection='column'>

      {contracts.length > 1 &&
        <ChangeContract />
      }

      <HomeBanners />

      <InvoiceHistory />
    </Box>
  )
}
