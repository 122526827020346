/* eslint-disable no-unused-vars */
import React from 'react'
import { Box, Link, List, Typography } from '@mui/material'
import { theme } from '~/utils'
import { Collapsibles } from '../..'
import { TSidebarItem } from '~/components/Layout'
import { SidebarListItem } from '../SidebarListItem'
import { CollapsibleList } from '../CollapsibleList'
import { DrawerHeader, StyleDrawer } from '../../style'
import DespertaLogo from '~/assets/images/logo/desperta-logo.svg'
import DespertaLogoReduced from '~/assets/images/logo/desperta-logo-reduced.svg'
import { IconBulb, IconLogout } from '@tabler/icons-react'

export type SidebarContentProps = {
  isDrawerOpen: boolean
  collapsibles: Collapsibles
  sidebarItems: TSidebarItem[]
  signOut: () => void
  toggleCollapsible: (collapsibleName: keyof Collapsibles) => void
}
export const SidebarContent: React.FC<SidebarContentProps> = (props) => {
  const { isDrawerOpen, sidebarItems, collapsibles, toggleCollapsible, signOut } = props

  return (
    <StyleDrawer variant='permanent' open={isDrawerOpen}>
      <DrawerHeader sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
        <img src={isDrawerOpen ? DespertaLogo : DespertaLogoReduced} height={40} />
      </DrawerHeader >

      <List sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

        {sidebarItems.map((sidebarItem) => {
          const { collapsibleName, text, nested, ...rest } = sidebarItem

          return (
            <React.Fragment key={`sidebar-list-item-${text}`}>
              <SidebarListItem
                text={text}
                isDrawerOpen={isDrawerOpen}
                isCollapsibleOpen={collapsibleName ? collapsibles[collapsibleName] : false}
                toggleCollapsible={collapsibleName ? () => toggleCollapsible(collapsibleName) : undefined}
                {...rest}
              />
              {nested && collapsibleName && (
                <CollapsibleList
                  isDrawerOpen={isDrawerOpen}
                  sidebarItem={sidebarItem}
                  isOpen={collapsibles[collapsibleName]}
                />
              )}
            </React.Fragment>
          )
        })}
        
        <Link href="https://despertaenergia.ac-page.com/material-de-apoio-cliente-assinatura-de-energia" target="_blank" sx={{textDecoration: 'none'}}>
          <Box sx={{
            background: '#5618850F',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            padding: '16px 0',
            gap: '10px',
            margin: isDrawerOpen ? '15px 17px' : '0px',
            borderRadius: isDrawerOpen ? '8px' : 0,
            transition: 'all 0.3s ease-in-out ',
            '&:hover': {
              background: '#56188526'
            }
          }}>
            <IconBulb size={24} color='#9E78BC' />
            {isDrawerOpen &&
              <Box sx={{ maxWidth: 100, wordWrap: 'break-word', whiteSpace: 'normal', lineHeight: 0 }}>
                <Typography variant='body2' component={'span'} fontWeight={800} color={'#561885'}>NOVO! </Typography>
                <Typography variant='body2' component={'span'} fontWeight={400} color={'#561885'}>Dicas e tutoriais</Typography>
              </Box>
            }
          </Box>
          </Link>
        <SidebarListItem
          text='Sair'
          icon={<IconLogout />}
          hideChevronIcon
          onClick={signOut}
          isDrawerOpen={isDrawerOpen}
          sx={{ marginTop: 'auto', background: theme.palette.error.main }}
        />
      </List>
      
    </StyleDrawer>
  )
}
