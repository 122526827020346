import { IconChevronRight } from '@tabler/icons-react'
import { Breadcrumbs, Link as MuiLink, Typography, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import { BreadcrumbProps } from './types'
import { theme } from '~/utils'

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ children, currentLink, links }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Breadcrumbs
        separator={<IconChevronRight size={18} />}
        aria-label='breadcrumb'
      >
        {links?.map(({ href, label }) => {
          return (
            <MuiLink component={Link} key={href} underline='hover' color='inherit' to={href}>
              {label}
            </MuiLink>
          )
        })}

        {currentLink && (
          <Typography fontSize={isLowerSm ? '.85rem' : '1rem'} color='text.primary'>
            {currentLink}
          </Typography>
        )}
        {children}
      </Breadcrumbs>
    </>
  )
}
