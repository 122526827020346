import { clearString } from '~/utils'
import { TinputMaskKeys } from './types'

export function useMask(value: string, typeMask?: TinputMaskKeys, customMask?: string) {
  if (typeMask === 'phone') {
    return clearString(value).length < 11 ? '(##) ####-#####' : '(##) #####-####'
  }

  if (typeMask === 'cep') {
    return '#####-###'
  }

  if (typeMask === 'cpf') {
    return '###.###.###-##'
  }

  if (typeMask === 'cnpj') {
    return '##.###.###/####-##'
  }

  if (typeMask === 'cpfCnpj') {
    return clearString(value).length < 12 ? '###.###.###-##' : '##.###.###/####-##'
  }

  if (typeMask === 'rg') {
    return '##.###.###-#'
  }

  if (customMask) {
    return customMask
  }

  // return ''

}

export const usePrefix = (typeMask?: TinputMaskKeys) => {
  if (typeMask === 'BRL') {
    return 'R$ '
  }

  return undefined
}

export const useSuffix = (typeMask?: TinputMaskKeys) => {
  if (typeMask === 'percentage') {
    return '%'
  }

  if (typeMask === 'kwh') {
    return ' kWh'
  }
  
  return undefined
}

export const usePlaceholder = (typeMask?: TinputMaskKeys) => {
  if (typeMask === 'percentage') {
    return '%'
  }

  if (typeMask === 'BRL') {
    return 'R$ 0,00'
  }

  if (typeMask === 'kwh') {
    return 'kWh'
  }
  
  return undefined
}
