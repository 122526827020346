import styled from '@emotion/styled'

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: 'none';
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  padding: .4rem 0;
  font-size: .9rem;

  svg {
    width: 20px;
  }
`
