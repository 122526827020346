import { createContext, useContext, useState } from 'react'
import { ContractContextData, ContractData, ContractProviderProps, ContractsData } from './types'
import { useCustomerUserGetMyContractsQuery , EContractStatus } from '~/graphql/types'
import { useParams } from 'react-router-dom'

const ContractContext = createContext({} as ContractContextData)

export const ContractProvider: React.FC<ContractProviderProps> = ({ children }) => {
  const { contractId } = useParams()

  const [selectedContract, setSelectedContract] = useState<ContractData | null>(null)
  const [contracts, setContracts] = useState<ContractsData>([])
  const [contractsIsLoading, setContracstIsLoading] = useState(true)

  useCustomerUserGetMyContractsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      params: {
        inStatus: [EContractStatus.active]
      },
    },
    onCompleted(data) {
      const loadSelectContract = data.customerUserGetMyContracts.data?.find(item => item._id === contractId)
      if(loadSelectContract) {
        setSelectedContract(loadSelectContract)
      }
      setContracts(data.customerUserGetMyContracts.data)
      setContracstIsLoading(false)
    },
    onError() {
      setContracts([])
      setContracstIsLoading(false)
    },
  })

  return (
    <ContractContext.Provider value={{
      contracts,
      selectedContract,
      setContracts,
      setSelectedContract,
      contractsIsLoading
    }}>
      {children}
    </ContractContext.Provider>
  )
}

export const useContract = () => useContext(ContractContext)
