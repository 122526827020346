import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { formatMoney, pickMonthNameByNumber, theme, transformMoney } from '~/utils'
import { ContainerTooltip } from './styles'
import { Divider, Typography, Box, IconButton, useMediaQuery } from '@mui/material'
import { IconChevronLeft, IconChevronRight, IconCircleFilled } from '@tabler/icons-react'
import { useState } from 'react'
import { transparentize } from 'polished'
import { Card } from '~/components'

type RewardChartProps = {
  data: {
    month: number,
    custAmountCents: number,
    rewardAmountCents: number,
    year: number
  }[]
}

export const RewardChart: React.FC<RewardChartProps> = ({ data }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const [showFirstSemester, setshowFirstSemester] = useState(true)

  const actualYear = new Date().getFullYear()

  const months = [1,2,3,4,5,6,7,8,9,10,11,12]

  const invoiceFilterByYear = data.filter(item => item.year === actualYear)

  const invoices = months.map((month) => ({
    name: pickMonthNameByNumber(month, 'short'),
    custAmountCents: transformMoney(invoiceFilterByYear.find(item => item.month === month)?.custAmountCents || 0, 'toReal'),
    rewardAmountCents: transformMoney(invoiceFilterByYear.find(item => item.month === month)?.rewardAmountCents || 0, 'toReal'),
  }))
  
  const filteredDataInvoice = invoices.slice(showFirstSemester ? 0 : 6, showFirstSemester ? 6 : 12)

  const CustomYAxisTick = (props: any) => {

    const { y, payload } = props

    return (
      <g transform={`translate(${0},${y})`}>
        <text 
          x={0}
          y={0}
          style={{ fontSize: isLowerMd ? '.8rem' : '.9rem' }}
          textAnchor='start'
          fill={theme.palette.grey['400']}>
          {formatMoney(payload.value)}
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload, label: tooltipLabel }: any) => {
    if (active && payload && payload.length) {
      return (
        <ContainerTooltip>
          <Typography fontSize='1.2rem' fontWeight={500} >{`${tooltipLabel} ${new Date().getFullYear()}`}</Typography>
          <Divider />
          <Typography color={theme.palette.grey['400']} fontSize='1rem'>{`Fatura: ${formatMoney(payload.find((item: any) => item.dataKey === 'custAmountCents')?.value || 0)}`}</Typography>
          <Typography color={theme.palette.grey['400']} fontSize='1rem'>{`Recompensa: ${formatMoney(payload.find((item: any) => item.dataKey === 'rewardAmountCents')?.value || 0)}`}</Typography>
        </ContainerTooltip>
      )
    }
  
    return null
  }

  return (
    <Card sx={{ padding: isLowerSm ? '1rem' : '2rem' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', paddingBottom: '2rem', justifyContent: 'space-between' }}>

        <Typography fontSize={isLowerSm ? '1.2rem' : '2rem'} variant='h6'>Histórico de recompensa</Typography>
        {!isLowerSm && (
          <Box sx={{ border: `1px solid ${theme.palette.grey[300]}`, padding: '.6rem', borderRadius: '12px', display: 'flex', flexFlow: 'column', alignItems: 'flex-end', gap: '.4rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
              <Typography sx={{ fontSize: '.8rem', color: theme.palette.grey[400] }}>Valor da fatura</Typography>
              <IconCircleFilled size={18} style={{ color: theme.palette.primary.main }} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
              <Typography sx={{ fontSize: '.8rem', color: theme.palette.grey[400] }}>Valor da recompensa</Typography>
              <IconCircleFilled size={18} style={{ color: theme.palette.secondary.main }} />
            </Box>
          </Box>
        )}
       
        {isLowerSm && (
          <IconButton size='small' onClick={() => setshowFirstSemester(!showFirstSemester)} sx={{ background: transparentize('0.9', '#561885') }}>
            {showFirstSemester ? 
              <IconChevronRight />
              : 
              <IconChevronLeft />
            }
          </IconButton>
        )}
      
      </Box>
        
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>

        <ResponsiveContainer width='94%' aspect={16.0/9.0}>

          <BarChart
            data={isLowerMd ? filteredDataInvoice : invoices}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
            barSize={6}
          >
            <XAxis tick={{ fontSize: isLowerSm ? '.9rem' : '1rem' }} tickLine={false} axisLine={false} dataKey='name' scale='point' padding={{ left: 10, right: 10 }} />
            {!isLowerSm && (
              <YAxis
                tickCount={6}
                width={100}
                tick={<CustomYAxisTick />}
                tickFormatter={formatMoney}
                axisLine={false}
                tickLine={false}
              />
            )}
            
            <Tooltip content={<CustomTooltip />} />
            <Bar radius={[10, 10, 10, 10]} dataKey='custAmountCents' fill='#E7BE00' background={{ fill: 'transparent' }} />
            <Bar radius={[10, 10, 10, 10]} dataKey='rewardAmountCents' fill='#561885' background={{ fill: 'transparent' }} />
          </BarChart>
        </ResponsiveContainer>

        {isLowerMd && !isLowerSm && (
          <IconButton onClick={() => setshowFirstSemester(!showFirstSemester)} sx={{ background: transparentize('0.9', '#561885') }}>
            {showFirstSemester ? 
              <IconChevronRight />
              : 
              <IconChevronLeft />
            }
          </IconButton>
        )}
      </Box>
    </Card>
  )
}
