const validDdds = [
  '11', '12', '13', '14', '15', '16', '17', '18', '19',
  '21', '22', '24', '27', '28',
  '31', '32', '33', '34', '35', '37', '38',
  '41', '42', '43', '44', '45', '46', '47', '48', '49',
  '51', '53', '54', '55',
  '63', '61', '62', '64', '65', '66', '67', '68', '69',
  '71', '73', '74', '75', '77', '79',
  '81', '87', '82', '83', '84', '85', '88', '89', '86',
  '91', '93', '94', '92', '96', '95', '97', '98', '99'
]

export function clearString(value: string): string {
  return value.replace(/[() -./]/g, '')
}

const checkForInvalidTelephoneOperator = (phone: string): boolean => phone.charAt(3) !== '0'
const isValidCellphoneNumber = (phone: string): boolean => phone.charAt(2) === '9'

const phoneValidation = (phone: string) => {
  const phoneDdd = phone.substring(0, 2)
  return validDdds.includes(phoneDdd)
}

const cellPhoneValidation = (cellphone: string) => {
  const isValidCellphone = isValidCellphoneNumber(cellphone)
  const isTelephoneOperatorValid = checkForInvalidTelephoneOperator(cellphone)
  const phoneDdd = cellphone.substring(0, 2)
  return isValidCellphone && isTelephoneOperatorValid && validDdds.includes(phoneDdd)
}

export const isPhoneValid = (phone: string): boolean => {
  const formattedPhone = clearString(phone)

  if (formattedPhone.length < 11) {
    return phoneValidation(formattedPhone)
  }

  return cellPhoneValidation(formattedPhone)
}
