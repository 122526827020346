import styled from '@emotion/styled'

type ContainerProps = {
  $selected: boolean
}

export const Container = styled.div<ContainerProps>`
  list-style: none;
  padding: 1.4rem;
  margin: 0;
  width: 100%;

  display: flex;
  align-items: center;
  background: white;
  border-radius: 1rem;
  cursor: pointer;
  transition: all .2s linear;
  position: relative;

  &:hover {
    background: radial-gradient(circle at center, #F1CC0B11 0, white 100%);
  }

  ${(props) => props.$selected && `
     &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 1rem; 
      padding: 4px; 
      background:linear-gradient(155deg, #C67BFF 30%, #FCC347, #FFD000); 
      -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude; 
    }
  `}

 
`

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  gap: .8rem;
`
