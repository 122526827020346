import styled from '@emotion/styled'
import { Box } from '@mui/material'
import BackgroundImage from '~/assets/images/login-background.webp'

export const LoginBackground = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${BackgroundImage});
  padding: 0 1rem;
`
