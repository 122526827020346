import styled from '@emotion/styled'
import { theme } from '~/utils'

type ContainerProps = {
  palette: {
    color: string,
    background: string
  }
}

export const Container = styled.div<ContainerProps>`
  padding: 2rem;
  border-radius: 1.2rem;
  display: flex;
  flex-flow: column;
  gap: .6rem;
  border: ${(props) => `1px solid ${props.palette.color}`};

  svg {
    width: 44px;
    height: 44px;
    background: ${(props) => props.palette.background};
    padding: .45rem;
    border-radius: .5rem;
    color: ${(props) => props.palette.color};
  }

  @media(max-width: ${theme.breakpoints.values.md}px) {
    padding: 1rem;
  }

`
