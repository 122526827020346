/* eslint-disable */

export type Unarray<T> = T extends (infer U)[] ? U : null

export enum ECustomerInvoiceStatus {
  pending = 'pending',
  late = 'late',
  paid = 'paid',
  canceled = 'canceled',
  extensionDate = 'extensionDate',
} 
