import { ButtonHTMLAttributes } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { IconChevronRight, IconReceipt2, IconTransitionRight } from '@tabler/icons-react'
import { StyledButton } from './styles'
import { theme } from '~/utils'

type CashbackButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  typeButton: 'invoce' | 'split'
}

export const CashbackButton: React.FC<CashbackButtonProps> = ({ typeButton, disabled, ...rest }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledButton disabled={disabled} {...rest}>
      <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: '1rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Box sx={{ 
          width: '44px',
          height: '44px',
          border: `1px solid ${theme.palette.grey['300']}`,
          borderRadius: '.4rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {typeButton === 'invoce' ? 
            <IconReceipt2 size={24} color={theme.palette.grey['400']} />
            : 
            <IconTransitionRight size={24} color={theme.palette.grey['400']} />
          }
        </Box>
        <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem', alignItems: 'flex-start' }}>
          <Typography sx={{
            textAlign: 'left',
            fontSize: '1rem',
            fontWeight: '500',
            color: disabled ? theme.palette.grey['400'] : theme.palette.black.dark
          }}>
            {typeButton === 'invoce' ? 'Pagar fatura' : 'Saque'}
          </Typography>
          <Typography sx={{ 
            textAlign: 'left',
            fontSize: '.95rem',
            fontWeight: '400',
            color: theme.palette.grey['400']
          }}>
            {typeButton === 'invoce' ? 'Use cashback no pagamento da sua conta de luz' : 'Transfira seu saldo para uma conta'}
          </Typography>
        </Box>
      </Box>
      {!isLowerSm && (
        <Box>
          <IconChevronRight color={theme.palette.grey['400']} />
        </Box>
      )}
    </StyledButton>
  )
  
}
