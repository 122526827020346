import styled from '@emotion/styled'

export const Ul = styled.ul`
  margin: 0;
  padding: 0 1rem;

  li {
    padding: .3rem 0;
    color: ${(props) => props.theme.palette.grey['400']};
  }
`
