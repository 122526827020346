import { Box, Button, CircularProgress, Divider, Typography, useMediaQuery } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { theme } from '~/utils';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomerCashbackInvoicePayDto } from '~/graphql/types';
import { Input } from '~/components';
interface ConfirmPaymentModalCashbackProps {
  onSubmit: (data: CustomerCashbackInvoicePayDto) => void;
  toggle: () => void;
  setAuthenticantionIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSplitData: React.Dispatch<React.SetStateAction<null | any>>;
  customerPayinvoiceLoading: boolean;
}

export function ConfirmPaymentModalCashback({ customerPayinvoiceLoading, toggle, setAuthenticantionIsOpen, onSubmit, setSplitData }: ConfirmPaymentModalCashbackProps) {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const formMethods = useFormContext<CustomerCashbackInvoicePayDto>()
  return (
    <Box>
      <Divider />
      <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
        <Box marginY={'24px'}>
          <Typography sx={{ fontSize: '16px', color: theme.palette.grey['700'], lineHeight: '30px' }}>Para prosseguir com a ação, por favor, informe sua senha</Typography>
          <Typography sx={{ fontSize: '14px', color: theme.palette.grey['400'], lineHeight: '20px' }}>(a mesma utilizada para entrar na plataforma) </Typography>
        </Box>
        {/* <Radio sx={{ paddingBottom: '1rem' }} row name='type' options={[{ value: 'basic', label: 'Saque básico' }, { value: 'early', label: 'Saque antecipado' }]} /> */}
        <Box sx={{ display: 'flex', flexFlow: 'column', gap: '1rem' }}>
          <Input label='Senha' type='password' name='password' />
        </Box>
        <Divider sx={{ margin: '2rem 0' }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
          <Button disabled={customerPayinvoiceLoading} onClick={() => (toggle(), setAuthenticantionIsOpen(false), setSplitData(null))} color='secondary'>Cancelar</Button>
          <Button disabled={customerPayinvoiceLoading} endIcon={customerPayinvoiceLoading ? <CircularProgress size={24} color='inherit' /> : null} type='submit'>Pagar com saldo</Button>
        </Box>
      </form>
    </Box>
  );
}
