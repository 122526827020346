import { Box, Divider } from '@mui/material'
import { IconClockHour5, IconStack2, IconWriting } from '@tabler/icons-react'
import { Card, ContentTitle, PageLoader } from '~/components'
import { GridColumn } from '~/styles'
import { DashboardCard, RewardChart } from './components'
import { pickMonthNameByNumber, theme, transformMoney } from '~/utils'
import { useDashboardCustomerUserMutation } from '~/graphql/types'
import { useContract } from '~/contexts'
import { useEffect } from 'react'

export const Dashboard: React.FC = () => {
  const { selectedContract } = useContract()

  const [getDashboardData, { loading: dashboardDataIsLoading, data }] = useDashboardCustomerUserMutation({
    variables: {
      params: {
        contractId: selectedContract?._id
      }
    }
  })

  useEffect(() => {
    getDashboardData()
  }, [])

  const cardData = {
    lastInvoiceAmountCents: data?.dashboardInvoicesInfoCustomerUser.lastInvoiceAmountCents || 0,
    cashback: {
      month: data?.dashboardInvoicesInfoCustomerUser.lastEconomy.month || 0,
      amountCents: data?.dashboardInvoicesInfoCustomerUser.lastEconomy.amountCents  || 0
    },
    overdueInvoices: data?.dashboardInvoicesInfoCustomerUser.overdueInvoices || 0,
  }

  const cards = [
    {
      icon: <IconStack2 />,
      title: 'Última fatura',
      description: 'Confira o valor da sua última conta de energia',
      value: transformMoney(cardData.lastInvoiceAmountCents, 'toReal')
    },
    {
      icon: <IconClockHour5 />,
      title: `Recompensa em ${pickMonthNameByNumber(cardData.cashback.month, 'long')}`,
      description: 'Com a gente você ganha ao pagar sua conta de luz',
      value: transformMoney(cardData.cashback.amountCents, 'toReal')
    },
    {
      icon: <IconWriting />,
      title: 'Faturas em atraso',
      description: 'A gente te ajuda a manter em dia :)',
      value: cardData.overdueInvoices
    },
  ]

  if(dashboardDataIsLoading) return <PageLoader />

  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>

        <ContentTitle
          description='Acompanhe sua jornada na Desperta'
          title='Visão geral'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          breadcrumbLinks={{ currentLink: 'Dashboard', links: [{ href: '/app', label: 'Área do cliente' }] }}
        />

        <Divider sx={{ marginBottom: '1.6rem' }} />

        <Box>
          <GridColumn columns={3} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
            {cards.map((item, i) => {
              return (
                <DashboardCard index={i} {...item} key={item.title}/>
              )
            })}
          </GridColumn>
        </Box>
      </Card>

      <Box sx={{ padding: '2rem 0' }}>
        <RewardChart data={data?.dashboardHistoryInvoicesCustomerUser.history?.map(item => ({ ...item, custAmountCents: item.amountCents, rewardAmountCents: data?.dashboardHistoryInvoicesRewardsCustomerUser.rewardHistory.find(rewardItem => item.month === rewardItem.month)?.amountCents || 0 })) || []} />
      </Box>

    </Box>
  )
}
