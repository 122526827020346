import { useMemo } from 'react'
import { IconX } from '@tabler/icons-react'
import { Box, Divider, Drawer, IconButton, Typography, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { InvoicePaymentModel } from '~/graphql/types'
import { BarcodePayment, PixPayment } from './components'

export type InvoicePaymentType = 'pix' | 'barcode'

export type PaymentDrawerProps = {
  isOpen: boolean
  toggle: () => void
  paymentType: InvoicePaymentType,
  paymentInfo: InvoicePaymentModel & { amountCents: number }
}

export const PaymentDrawer: React.FC<PaymentDrawerProps> = ({ isOpen, toggle, paymentType, paymentInfo }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isPix = useMemo(() => paymentType === 'pix', [paymentType])

  const drawerTitle = `Pagar com ${isPix ? 'Pix' : 'código de barras'}`

  return (
    <Drawer anchor='right' open={isOpen} onClose={toggle}>
      <Box sx={{
        gap: 4,
        maxWidth: '640px',
        display: 'flex',
        padding: isLowerSm ? 4 : '32px 48px',
        flexDirection: 'column'
      }}>
        <Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Typography fontWeight={500} variant={isLowerSm ? 'body1' : 'h6'}>{drawerTitle}</Typography>
            <IconButton onClick={toggle}><IconX /></IconButton>
          </Box>

          <Divider />
        </Box>

        <Box sx={{
          gap: 3,
          display: 'flex',
          flexDirection: 'column'
        }}>
          {isPix ? <PixPayment paymentInfo={paymentInfo} /> : <BarcodePayment paymentInfo={paymentInfo} />}
        </Box>
      </Box>
    </Drawer>
  )
}
