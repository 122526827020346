import styled from '@emotion/styled'

export const StyledButton = styled.button`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.4rem 1rem;
  background: transparent;
  border: 1px solid ${(props) => props.theme.palette.grey['300']};
  border-radius: .6rem;
  cursor: pointer;
  transition: background .15s ease-in-out;

  &:hover {
    background: ${(props) => props.theme.palette.info.light};
  }

  &:disabled {
    background: ${(props) => props.theme.palette.grey['100']};
    cursor: not-allowed;
  }
`
