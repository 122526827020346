import { useCallback, useState } from 'react'

export type MetadataTableProps = {
  currentPage: number
  documentCounts?: number
  pageSize: number
}

export type MetadataProps = {
  metadata: MetadataTableProps;
  setMetadata: React.Dispatch<React.SetStateAction<MetadataTableProps>>;
  nextPage: Function
  prevPage: Function
  firstPage: Function
  lastPage: Function
  canNextPage: boolean
  canPrevPage: boolean
  filtersTable: {[x: string]: any} | null;
  setFiltersTable: React.Dispatch<React.SetStateAction<{[x: string]: any} | null>>;
}

export const useApiPagination = (options?: MetadataTableProps) => {
  const [metadata, setMetadata] = useState<MetadataTableProps>({ currentPage: options?.currentPage || 0, pageSize: options?.pageSize || 10 })
  const [filtersTable, setFiltersTable] = useState<{[x: string]: any} | null>(null)

  const lastPageNumber = Math.ceil((metadata.documentCounts || 0) / metadata.pageSize)

  const nextPage = useCallback(() => {
    setMetadata({ ...metadata, currentPage: metadata.currentPage + 1 })
  }, [metadata])

  const prevPage = useCallback(() => {
    setMetadata({ ...metadata, currentPage: metadata.currentPage - 1 })
  }, [metadata])

  const firstPage = useCallback(() => {
    setMetadata({ ...metadata, currentPage: 0 })
  }, [metadata])

  const lastPage = useCallback(() => {
    setMetadata({ ...metadata, currentPage: lastPageNumber - 1 })
  }, [metadata, lastPageNumber])

  const canNextPage = metadata.currentPage >= lastPageNumber - 1
  const canPrevPage = metadata.currentPage < 1

  return { metadata, setMetadata, nextPage, prevPage, firstPage, lastPage, canNextPage, canPrevPage, filtersTable, setFiltersTable }
}
