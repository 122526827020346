import { SvgIcon, SvgIconProps } from '@mui/material'

export const UnifiedEnergyBill: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon preserveAspectRatio='none' viewBox='19 89.36 398 289.64' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d='M417 343.058H19V343.257H417V343.058Z' fill='#EBEBEB' />
        <path d='M377.12 355.865H350.757V356.064H377.12V355.865Z' fill='#EBEBEB' />
        <path d='M282.651 358.031H275.734V358.23H282.651V358.031Z' fill='#EBEBEB' />
        <path d='M349.961 348.479H334.686V348.678H349.961V348.479Z' fill='#EBEBEB' />
        <path d='M95.1374 349.816H60.7582V350.015H95.1374V349.816Z' fill='#EBEBEB' />
        <path d='M107.268 349.816H102.23V350.015H107.268V349.816Z' fill='#EBEBEB' />
        <path d='M198.219 353.175H123.65V353.374H198.219V353.175Z' fill='#EBEBEB' />
        <path d='M149.934 91.0044H93.219V283.406H149.934V91.0044Z' fill='#FAFAFA' />
        <path d='M141.488 91.0044H138.177L118.396 283.406H121.708L141.488 91.0044Z' fill='#F5F5F5' />
        <path d='M148.931 91.7449H92.8529V93.4404H148.931V91.7449Z' fill='#E0E0E0' />
        <path d='M148.931 281.718H92.8529V285.348H148.931V281.718Z' fill='#EBEBEB' />
        <path d='M148 285.189H95.01V285.348H148V285.189Z' fill='#EBEBEB' />
        <path d='M95.01 91.7448H148V285.189H151.176V89.3647H91.834V285.189H95.01V91.7448Z' fill='#EBEBEB' />
        <path d='M213.049 91.0044H156.334V283.406H213.049V91.0044Z' fill='#FAFAFA' />
        <path d='M197.312 91.0044H179.235L159.454 283.406H177.531L197.312 91.0044Z' fill='#F5F5F5' />
        <path d='M213.638 91.7449H155.968V93.4404H213.638V91.7449Z' fill='#E0E0E0' />
        <path d='M213.638 281.718H155.968V285.348H213.638V281.718Z' fill='#EBEBEB' />
        <path d='M211.115 285.189H158.125V285.348H211.115V285.189Z' fill='#EBEBEB' />
        <path d='M158.125 91.7448H211.115V285.189H214.283V89.3647H154.949V285.189H158.125V91.7448Z' fill='#EBEBEB' />
        <path d='M274.564 91.0044H219.441V283.406H274.564V91.0044Z' fill='#FAFAFA' />
        <path d='M250.676 91.0044H247.364L227.584 283.406H230.895L250.676 91.0044Z' fill='#F5F5F5' />
        <path d='M275.161 91.7449H219.083V93.4404H275.161V91.7449Z' fill='#E0E0E0' />
        <path d='M275.161 281.718H219.083V285.348H275.161V281.718Z' fill='#EBEBEB' />
        <path d='M274.229 285.189H221.24V285.348H274.229V285.189Z' fill='#EBEBEB' />
        <path d='M221.24 91.7448H274.222V285.189H277.398V89.3647H218.064V285.189H221.24V91.7448Z' fill='#EBEBEB' />
        <path d='M339.271 91.0044H282.556V283.406H339.271V91.0044Z' fill='#FAFAFA' />
        <path d='M333.722 91.0044H315.645L295.857 283.406H313.942L333.722 91.0044Z' fill='#F5F5F5' />
        <path d='M338.268 91.7449H282.189V93.4404H338.268V91.7449Z' fill='#E0E0E0' />
        <path d='M338.268 281.718H282.189V285.348H338.268V281.718Z' fill='#EBEBEB' />
        <path d='M284.347 91.7448H337.336V285.189H340.512V89.3647H281.178V285.189H284.347V91.7448Z' fill='#EBEBEB' />
        <path d='M337.336 285.189H284.347V285.348H337.336V285.189Z' fill='#EBEBEB' />
        <path d='M136.362 239.148H111.742V281.726H136.362V239.148Z' fill='#EBEBEB' />
        <path d='M131.634 233.194V243.876H129.7H118.412H116.47V233.194H131.634Z' fill='#EBEBEB' />
        <path d='M131.634 243.876H134.786V240.724H131.634V243.876Z' fill='#FAFAFA' />
        <path d='M125.537 243.876H128.689V240.724H125.537V243.876Z' fill='#FAFAFA' />
        <path d='M119.431 243.876H122.583V240.724H119.431V243.876Z' fill='#FAFAFA' />
        <path d='M113.326 243.876H116.478V240.724H113.326V243.876Z' fill='#F0F0F0' />
        <path d='M131.634 249.6H134.786V246.447H131.634V249.6Z' fill='#F0F0F0' />
        <path d='M125.537 249.6H128.689V246.447H125.537V249.6Z' fill='#F0F0F0' />
        <path d='M119.431 249.6H122.583V246.447H119.431V249.6Z' fill='#FAFAFA' />
        <path d='M113.326 249.6H116.478V246.447H113.326V249.6Z' fill='#FAFAFA' />
        <path d='M131.634 255.331H134.786V252.179H131.634V255.331Z' fill='#F0F0F0' />
        <path d='M125.537 255.331H128.689V252.179H125.537V255.331Z' fill='#FAFAFA' />
        <path d='M119.431 255.331H122.583V252.179H119.431V255.331Z' fill='#FAFAFA' />
        <path d='M125.537 238.925H128.689V235.773H125.537V238.925Z' fill='#FAFAFA' />
        <path d='M119.431 238.925H122.583V235.773H119.431V238.925Z' fill='#F0F0F0' />
        <path d='M113.326 255.331H116.478V252.179H113.326V255.331Z' fill='#F0F0F0' />
        <path d='M131.634 261.062H134.786V257.91H131.634V261.062Z' fill='#FAFAFA' />
        <path d='M125.537 261.062H128.689V257.91H125.537V261.062Z' fill='#F0F0F0' />
        <path d='M119.431 261.062H122.583V257.91H119.431V261.062Z' fill='#F0F0F0' />
        <path d='M113.326 261.062H116.478V257.91H113.326V261.062Z' fill='#FAFAFA' />
        <path d='M131.634 266.793H134.786V263.641H131.634V266.793Z' fill='#F0F0F0' />
        <path d='M125.537 266.793H128.689V263.641H125.537V266.793Z' fill='#FAFAFA' />
        <path d='M119.431 266.793H122.583V263.641H119.431V266.793Z' fill='#FAFAFA' />
        <path d='M113.326 266.793H116.478V263.641H113.326V266.793Z' fill='#F0F0F0' />
        <path d='M131.634 272.516H134.786V269.364H131.634V272.516Z' fill='#F0F0F0' />
        <path d='M125.537 272.516H128.689V269.364H125.537V272.516Z' fill='#FAFAFA' />
        <path d='M119.431 272.516H122.583V269.364H119.431V272.516Z' fill='#F0F0F0' />
        <path d='M113.326 272.516H116.478V269.364H113.326V272.516Z' fill='#FAFAFA' />
        <path d='M131.634 278.248H134.786V275.095H131.634V278.248Z' fill='#FAFAFA' />
        <path d='M125.537 278.248H128.689V275.095H125.537V278.248Z' fill='#FAFAFA' />
        <path d='M119.431 278.248H122.583V275.095H119.431V278.248Z' fill='#F0F0F0' />
        <path d='M113.326 278.248H116.478V275.095H113.326V278.248Z' fill='#F0F0F0' />
        <path d='M309.811 254.654H287.753V281.718H309.811V254.654Z' fill='#F0F0F0' />
        <path d='M289.672 257.663H294.798H296.215H297.624V260.481H296.215H294.798H289.672V257.663Z' fill='#E6E6E6' />
        <path d='M289.672 263.132H294.798H296.215H297.624V265.957H296.215H294.798H289.672V263.132Z' fill='#FAFAFA' />
        <path d='M289.672 268.6H294.798H296.215H297.624V271.426H296.215H294.798H289.672V268.6Z' fill='#E6E6E6' />
        <path d='M289.672 274.069H294.798H296.979H297.624V276.894H296.979H294.798H289.672V274.069Z' fill='#FAFAFA' />
        <path d='M299.932 257.663H305.066H306.475H307.884V260.481H306.475H305.066H299.932V257.663Z' fill='#FAFAFA' />
        <path d='M299.932 263.132H305.066H307.056H307.884V265.957H307.056H305.066H299.932V263.132Z' fill='#FAFAFA' />
        <path d='M299.932 268.6H305.066H306.475H307.884V271.426H306.475H305.066H299.932V268.6Z' fill='#E6E6E6' />
        <path d='M299.932 274.069H305.066H306.475H307.884V276.894H306.475H305.066H299.932V274.069Z' fill='#FAFAFA' />
        <path d='M203.521 264.906H183.478V281.726H203.521V264.906Z' fill='#F0F0F0' />
        <path d='M199.676 269.571H202.239V267.008H199.676V269.571Z' fill='#FAFAFA' />
        <path d='M194.709 269.571H197.272V267.008H194.709V269.571Z' fill='#FAFAFA' />
        <path d='M189.734 269.571H192.297V267.008H189.734V269.571Z' fill='#FAFAFA' />
        <path d='M184.767 269.571H187.33V267.008H184.767V269.571Z' fill='#FAFAFA' />
        <path d='M199.676 274.228H202.239V271.665H199.676V274.228Z' fill='#FAFAFA' />
        <path d='M194.709 274.228H197.272V271.665H194.709V274.228Z' fill='#FAFAFA' />
        <path d='M189.734 274.228H192.297V271.665H189.734V274.228Z' fill='#FAFAFA' />
        <path d='M184.767 274.228H187.33V271.665H184.767V274.228Z' fill='#E6E6E6' />
        <path d='M199.676 278.892H202.239V276.329H199.676V278.892Z' fill='#E6E6E6' />
        <path d='M194.709 278.892H197.272V276.329H194.709V278.892Z' fill='#E6E6E6' />
        <path d='M189.734 278.892H192.297V276.329H189.734V278.892Z' fill='#FAFAFA' />
        <path d='M184.767 278.892H187.33V276.329H184.767V278.892Z' fill='#E6E6E6' />
        <path d='M180.763 252.473H160.72V281.726H180.763V252.473Z' fill='#F0F0F0' />
        <path d='M179.482 253.755H171.943V260.982H179.482V253.755Z' fill='#FAFAFA' />
        <path d='M169.539 253.755H162.001V260.982H169.539V253.755Z' fill='#FAFAFA' />
        <path d='M179.482 263.076H171.943V270.303H179.482V263.076Z' fill='#FAFAFA' />
        <path d='M169.539 263.076H162.001V270.303H169.539V263.076Z' fill='#FAFAFA' />
        <path d='M179.482 272.397H171.943V279.625H179.482V272.397Z' fill='#FAFAFA' />
        <path d='M169.539 272.397H162.001V279.625H169.539V272.397Z' fill='#FAFAFA' />
        <path d='M218 379.005C303.238 379.005 372.336 374.971 372.336 369.995C372.336 365.018 303.238 360.984 218 360.984C132.762 360.984 63.6636 365.018 63.6636 369.995C63.6636 374.971 132.762 379.005 218 379.005Z' fill='#F5F5F5' />
        <path d='M372.878 102.037V277.348L351.784 298.442H229.669V102.037H372.878Z' fill='#7E57C2' />
        <path opacity='0.8' d='M372.878 102.037V277.348L351.784 298.442H229.669V102.037H372.878Z' fill='white' />
        <path d='M296.239 205H295V236.019H296.239V205Z' fill='#C5B3DB' />
        <path d='M297.482 205H296.86V236.019H297.482V205Z' fill='#C5B3DB' />
        <path d='M299.346 205H298.724V236.019H299.346V205Z' fill='#C5B3DB' />
        <path d='M303.062 205H301.823V236.019H303.062V205Z' fill='#C5B3DB' />
        <path d='M304.927 205H304.305V236.019H304.927V205Z' fill='#C5B3DB' />
        <path d='M307.408 205H305.548V236.019H307.408V205Z' fill='#C5B3DB' />
        <path d='M309.268 205H308.647V236.019H309.268V205Z' fill='#C5B3DB' />
        <path d='M310.511 205H309.89V236.019H310.511V205Z' fill='#C5B3DB' />
        <path d='M313.61 205H312.372V236.019H313.61V205Z' fill='#C5B3DB' />
        <path d='M316.709 205H315.471V236.019H316.709V205Z' fill='#C5B3DB' />
        <path d='M319.195 205H317.335V236.019H319.195V205Z' fill='#C5B3DB' />
        <path d='M321.055 205H320.434V236.019H321.055V205Z' fill='#C5B3DB' />
        <path d='M323.533 205H322.294V236.019H323.533V205Z' fill='#C5B3DB' />
        <path d='M326.636 205H324.776V236.019H326.636V205Z' fill='#C5B3DB' />
        <path d='M327.879 205H327.257V236.019H327.879V205Z' fill='#C5B3DB' />
        <path d='M330.978 205H329.118V236.019H330.978V205Z' fill='#C5B3DB' />
        <path d='M332.838 205H331.599V236.019H332.838V205Z' fill='#C5B3DB' />
        <path d='M335.324 205H333.464V236.019H335.324V205Z' fill='#C5B3DB' />
        <path d='M336.567 205H335.945V236.019H336.567V205Z' fill='#C5B3DB' />
        <path d='M339.044 205H338.423V236.019H339.044V205Z' fill='#C5B3DB' />
        <path d='M340.904 205H339.666V236.019H340.904V205Z' fill='#C5B3DB' />
        <path d='M344.007 205H342.769V236.019H344.007V205Z' fill='#C5B3DB' />
        <path d='M346.489 205H345.868V236.019H346.489V205Z' fill='#C5B3DB' />
        <path d='M348.971 205H348.349V236.019H348.971V205Z' fill='#C5B3DB' />
        <path d='M350.831 205H349.592V236.019H350.831V205Z' fill='#C5B3DB' />
        <path d='M354.551 205H352.691V236.019H354.551V205Z' fill='#C5B3DB' />
        <path d='M355.794 205H355.173V236.019H355.794V205Z' fill='#C5B3DB' />
        <path d='M357.654 205H356.416V236.019H357.654V205Z' fill='#C5B3DB' />
        <path d='M320.18 240.557C320.18 241.141 319.739 241.648 318.807 241.648C318.339 241.655 317.882 241.512 317.502 241.239L317.78 240.737C318.071 240.962 318.43 241.083 318.798 241.08C319.26 241.08 319.53 240.876 319.53 240.553C319.53 240.23 319.301 240.038 318.778 240.038H318.459V239.596L319.248 238.66H317.67V238.116H320.037V238.549L319.195 239.551C319.326 239.552 319.455 239.579 319.576 239.63C319.696 239.681 319.805 239.756 319.897 239.849C319.988 239.943 320.06 240.053 320.109 240.175C320.158 240.296 320.182 240.426 320.18 240.557Z' fill='#C5B3DB' />
        <path d='M323.12 240.81H321.382L321.039 241.628H320.372L321.934 238.153H322.568L324.138 241.628H323.463L323.12 240.81ZM322.907 240.303L322.253 238.766L321.599 240.287L322.907 240.303Z' fill='#C5B3DB' />
        <path d='M326.861 240.532C326.861 241.141 326.419 241.648 325.487 241.648C325.02 241.656 324.562 241.513 324.183 241.239L324.457 240.737C324.749 240.963 325.109 241.084 325.479 241.08C325.937 241.08 326.211 240.876 326.211 240.549C326.211 240.221 325.998 240.009 325.279 240.009H324.461L324.641 238.116H326.685V238.66H325.193L325.119 239.477H325.45C326.448 239.469 326.861 239.915 326.861 240.532Z' fill='#C5B3DB' />
        <path d='M330.009 240.52C330.006 240.68 329.969 240.837 329.903 240.982C329.836 241.127 329.739 241.256 329.62 241.362C329.501 241.468 329.36 241.549 329.209 241.598C329.057 241.647 328.896 241.664 328.737 241.648C327.789 241.648 327.233 241.039 327.233 239.923C327.233 238.733 327.928 238.075 328.938 238.075C329.256 238.066 329.571 238.144 329.849 238.3L329.612 238.787C329.412 238.668 329.182 238.61 328.95 238.619C328.296 238.619 327.883 239.028 327.883 239.813C328.003 239.69 328.147 239.594 328.308 239.531C328.468 239.469 328.639 239.443 328.811 239.453C328.961 239.438 329.113 239.454 329.256 239.5C329.4 239.547 329.532 239.622 329.645 239.723C329.757 239.823 329.848 239.946 329.911 240.083C329.973 240.22 330.007 240.369 330.009 240.52ZM329.383 240.553C329.383 240.189 329.109 239.96 328.688 239.96C328.602 239.95 328.515 239.957 328.432 239.982C328.349 240.007 328.273 240.049 328.207 240.105C328.141 240.161 328.087 240.23 328.049 240.308C328.011 240.386 327.989 240.47 327.985 240.557C327.985 240.884 328.243 241.141 328.705 241.141C328.788 241.15 328.871 241.141 328.951 241.116C329.03 241.09 329.103 241.048 329.165 240.993C329.228 240.937 329.278 240.87 329.312 240.794C329.347 240.718 329.366 240.636 329.367 240.553H329.383Z' fill='#C5B3DB' />
        <path d='M332.94 238.124V238.557L331.591 241.599H330.9L332.204 238.672H330.843V239.261H330.242V238.124H332.94Z' fill='#C5B3DB' />
        <path d='M336.673 240.655C336.673 241.252 336.207 241.599 335.311 241.599H333.586V238.124H335.222C336.039 238.124 336.501 238.48 336.501 239.028C336.508 239.184 336.47 239.34 336.391 239.475C336.312 239.611 336.195 239.721 336.056 239.792C336.241 239.845 336.402 239.959 336.514 240.116C336.626 240.273 336.682 240.462 336.673 240.655ZM334.22 238.611V239.584H335.132C335.577 239.584 335.831 239.428 335.831 239.109C335.831 238.79 335.577 238.627 335.132 238.627L334.22 238.611ZM336.011 240.569C336.011 240.225 335.749 240.07 335.262 240.07H334.232V241.072H335.275C335.761 241.092 336.023 240.937 336.023 240.589L336.011 240.569Z' fill='#C5B3DB' />
        <path opacity='0.2' d='M351.784 298.442V277.969H372.878L352.198 298.649' fill='black' />
        <path opacity='0.1' d='M283.861 119.064H244.363C243.808 119.064 243.276 118.843 242.883 118.45C242.49 118.058 242.27 117.525 242.27 116.97C242.269 116.695 242.322 116.421 242.427 116.167C242.532 115.912 242.686 115.68 242.88 115.485C243.075 115.289 243.306 115.134 243.56 115.029C243.815 114.923 244.088 114.869 244.363 114.869H283.861C284.417 114.871 284.949 115.093 285.342 115.487C285.734 115.881 285.954 116.414 285.954 116.97C285.954 117.525 285.734 118.058 285.341 118.45C284.949 118.843 284.416 119.064 283.861 119.064Z' fill='black' />
        <path opacity='0.1' d='M355.501 144.305H244.363C243.808 144.305 243.276 144.084 242.883 143.692C242.49 143.299 242.27 142.767 242.27 142.211C242.269 141.936 242.322 141.663 242.427 141.408C242.532 141.153 242.686 140.921 242.88 140.726C243.075 140.531 243.306 140.376 243.56 140.27C243.815 140.164 244.088 140.11 244.363 140.11H355.501C356.057 140.112 356.589 140.334 356.982 140.728C357.374 141.122 357.594 141.655 357.594 142.211C357.594 142.767 357.374 143.299 356.981 143.692C356.589 144.084 356.056 144.305 355.501 144.305Z' fill='black' />
        <path opacity='0.1' d='M355.501 153.634H244.363C244.088 153.634 243.815 153.579 243.56 153.474C243.306 153.368 243.075 153.213 242.88 153.018C242.686 152.823 242.532 152.591 242.427 152.336C242.322 152.081 242.269 151.808 242.27 151.532C242.27 150.977 242.49 150.445 242.883 150.052C243.276 149.66 243.808 149.439 244.363 149.439H355.501C356.056 149.439 356.589 149.66 356.981 150.052C357.374 150.445 357.594 150.977 357.594 151.532C357.594 152.088 357.374 152.622 356.982 153.016C356.589 153.409 356.057 153.632 355.501 153.634Z' fill='black' />
        <path opacity='0.1' d='M355.501 162.955H244.364C243.808 162.955 243.276 162.734 242.883 162.342C242.491 161.949 242.27 161.417 242.27 160.862C242.27 160.306 242.491 159.774 242.883 159.381C243.276 158.989 243.808 158.768 244.364 158.768H355.501C356.056 158.768 356.589 158.989 356.981 159.381C357.374 159.774 357.595 160.306 357.595 160.862C357.595 161.417 357.374 161.949 356.981 162.342C356.589 162.734 356.056 162.955 355.501 162.955Z' fill='black' />
        <path opacity='0.1' d='M355.501 172.284H244.363C243.808 172.284 243.276 172.064 242.883 171.671C242.49 171.278 242.27 170.746 242.27 170.191C242.269 169.915 242.322 169.643 242.427 169.388C242.532 169.134 242.686 168.903 242.881 168.708C243.075 168.513 243.307 168.359 243.561 168.254C243.815 168.15 244.088 168.096 244.363 168.097H355.501C356.056 168.097 356.589 168.318 356.981 168.71C357.374 169.103 357.594 169.635 357.594 170.191C357.594 170.746 357.374 171.278 356.981 171.671C356.589 172.064 356.056 172.284 355.501 172.284Z' fill='black' />
        <path opacity='0.1' d='M355.501 181.613H244.363C243.808 181.613 243.276 181.393 242.883 181C242.49 180.607 242.27 180.075 242.27 179.52C242.269 179.244 242.322 178.971 242.427 178.716C242.532 178.461 242.686 178.229 242.88 178.034C243.075 177.839 243.306 177.684 243.56 177.578C243.815 177.473 244.088 177.418 244.363 177.418H355.501C356.057 177.42 356.589 177.643 356.982 178.037C357.374 178.43 357.594 178.964 357.594 179.52C357.594 180.075 357.374 180.607 356.981 181C356.589 181.393 356.056 181.613 355.501 181.613Z' fill='black' />
        <path opacity='0.1' d='M355.509 186.747H244.364C243.207 186.747 242.27 187.685 242.27 188.841V188.849C242.27 190.005 243.207 190.942 244.364 190.942H355.509C356.665 190.942 357.602 190.005 357.602 188.849V188.841C357.602 187.685 356.665 186.747 355.509 186.747Z' fill='black' />
        <path opacity='0.1' d='M355.501 200.256H244.364C243.808 200.256 243.276 200.035 242.883 199.642C242.491 199.25 242.27 198.717 242.27 198.162C242.27 197.607 242.491 197.074 242.883 196.682C243.276 196.289 243.808 196.069 244.364 196.069H355.501C356.056 196.069 356.589 196.289 356.981 196.682C357.374 197.074 357.595 197.607 357.595 198.162C357.595 198.717 357.374 199.25 356.981 199.642C356.589 200.035 356.056 200.256 355.501 200.256Z' fill='black' />
        <path opacity='0.1' d='M290.111 210H242.89C242.654 210 242.427 209.737 242.261 209.268C242.094 208.799 242 208.163 242 207.5C242 207.171 242.022 206.846 242.067 206.542C242.111 206.238 242.177 205.962 242.26 205.729C242.342 205.497 242.44 205.313 242.549 205.188C242.657 205.063 242.773 204.999 242.89 205H290.111C290.346 205 290.573 205.263 290.739 205.732C290.906 206.201 291 206.837 291 207.5C291 208.163 290.906 208.799 290.739 209.268C290.573 209.737 290.346 210 290.111 210Z' fill='black' />
        <path opacity='0.1' d='M290.111 218H242.89C242.654 218 242.427 217.79 242.261 217.415C242.094 217.041 242 216.533 242 216.004C242 215.741 242.022 215.481 242.067 215.238C242.111 214.994 242.177 214.774 242.259 214.587C242.342 214.401 242.44 214.253 242.548 214.153C242.656 214.052 242.772 214 242.89 214H290.111C290.347 214.002 290.573 214.214 290.74 214.59C290.906 214.965 291 215.474 291 216.004C291 216.533 290.906 217.041 290.739 217.415C290.573 217.79 290.346 218 290.111 218Z' fill='black' />
        <path opacity='0.1' d='M290.111 227H242.89C242.772 227 242.656 226.948 242.548 226.847C242.44 226.747 242.342 226.599 242.259 226.413C242.177 226.226 242.111 226.006 242.067 225.762C242.022 225.519 242 225.259 242 224.996C242 224.467 242.094 223.959 242.261 223.585C242.427 223.21 242.654 223 242.89 223H290.111C290.346 223 290.573 223.21 290.739 223.585C290.906 223.959 291 224.467 291 224.996C291 225.526 290.906 226.035 290.74 226.41C290.573 226.786 290.347 226.998 290.111 227Z' fill='black' />
        <path opacity='0.1' d='M289.014 236H243.986C243.459 236 242.954 235.789 242.582 235.414C242.209 235.039 242 234.53 242 234C242 233.47 242.209 232.961 242.582 232.586C242.954 232.211 243.459 232 243.986 232H289.014C289.541 232 290.046 232.211 290.418 232.586C290.791 232.961 291 233.47 291 234C291 234.53 290.791 235.039 290.418 235.414C290.046 235.789 289.541 236 289.014 236Z' fill='black' />
        <path d='M248.938 131.648H251.273C251.227 132.414 251.016 133.094 250.641 133.688C250.271 134.281 249.753 134.745 249.086 135.078C248.424 135.411 247.628 135.578 246.695 135.578C245.966 135.578 245.312 135.453 244.734 135.203C244.156 134.948 243.661 134.583 243.25 134.109C242.844 133.635 242.534 133.062 242.32 132.391C242.107 131.719 242 130.966 242 130.133V129.344C242 128.51 242.109 127.758 242.328 127.086C242.552 126.409 242.87 125.833 243.281 125.359C243.698 124.885 244.195 124.521 244.773 124.266C245.352 124.01 245.997 123.883 246.711 123.883C247.659 123.883 248.458 124.055 249.109 124.398C249.766 124.742 250.273 125.216 250.633 125.82C250.997 126.424 251.216 127.112 251.289 127.883H248.945C248.919 127.424 248.828 127.036 248.672 126.719C248.516 126.396 248.279 126.154 247.961 125.992C247.648 125.826 247.232 125.742 246.711 125.742C246.32 125.742 245.979 125.815 245.688 125.961C245.396 126.107 245.151 126.328 244.953 126.625C244.755 126.922 244.607 127.297 244.508 127.75C244.414 128.198 244.367 128.724 244.367 129.328V130.133C244.367 130.721 244.411 131.24 244.5 131.688C244.589 132.13 244.724 132.505 244.906 132.812C245.094 133.115 245.333 133.344 245.625 133.5C245.922 133.651 246.279 133.727 246.695 133.727C247.185 133.727 247.589 133.648 247.906 133.492C248.224 133.336 248.466 133.104 248.633 132.797C248.805 132.49 248.906 132.107 248.938 131.648Z' fill='#C5B3DB' />
        <path d='M252.312 131.281V131.117C252.312 130.497 252.401 129.927 252.578 129.406C252.755 128.88 253.013 128.424 253.352 128.039C253.69 127.654 254.107 127.354 254.602 127.141C255.096 126.922 255.664 126.812 256.305 126.812C256.945 126.812 257.516 126.922 258.016 127.141C258.516 127.354 258.935 127.654 259.273 128.039C259.617 128.424 259.878 128.88 260.055 129.406C260.232 129.927 260.32 130.497 260.32 131.117V131.281C260.32 131.896 260.232 132.466 260.055 132.992C259.878 133.513 259.617 133.969 259.273 134.359C258.935 134.745 258.518 135.044 258.023 135.258C257.529 135.471 256.961 135.578 256.32 135.578C255.68 135.578 255.109 135.471 254.609 135.258C254.115 135.044 253.695 134.745 253.352 134.359C253.013 133.969 252.755 133.513 252.578 132.992C252.401 132.466 252.312 131.896 252.312 131.281ZM254.562 131.117V131.281C254.562 131.635 254.594 131.966 254.656 132.273C254.719 132.581 254.818 132.852 254.953 133.086C255.094 133.315 255.276 133.495 255.5 133.625C255.724 133.755 255.997 133.82 256.32 133.82C256.633 133.82 256.901 133.755 257.125 133.625C257.349 133.495 257.529 133.315 257.664 133.086C257.799 132.852 257.898 132.581 257.961 132.273C258.029 131.966 258.062 131.635 258.062 131.281V131.117C258.062 130.773 258.029 130.451 257.961 130.148C257.898 129.841 257.797 129.57 257.656 129.336C257.521 129.096 257.341 128.909 257.117 128.773C256.893 128.638 256.622 128.57 256.305 128.57C255.987 128.57 255.716 128.638 255.492 128.773C255.273 128.909 255.094 129.096 254.953 129.336C254.818 129.57 254.719 129.841 254.656 130.148C254.594 130.451 254.562 130.773 254.562 131.117Z' fill='#C5B3DB' />
        <path d='M263.906 128.773V135.422H261.656V126.969H263.766L263.906 128.773ZM263.578 130.898H262.969C262.969 130.273 263.049 129.711 263.211 129.211C263.372 128.706 263.599 128.276 263.891 127.922C264.182 127.562 264.529 127.289 264.93 127.102C265.336 126.909 265.789 126.812 266.289 126.812C266.685 126.812 267.047 126.87 267.375 126.984C267.703 127.099 267.984 127.281 268.219 127.531C268.458 127.781 268.641 128.112 268.766 128.523C268.896 128.935 268.961 129.438 268.961 130.031V135.422H266.695V130.023C266.695 129.648 266.643 129.357 266.539 129.148C266.435 128.94 266.281 128.794 266.078 128.711C265.88 128.622 265.635 128.578 265.344 128.578C265.042 128.578 264.779 128.638 264.555 128.758C264.336 128.878 264.154 129.044 264.008 129.258C263.867 129.466 263.76 129.711 263.688 129.992C263.615 130.273 263.578 130.576 263.578 130.898Z' fill='#C5B3DB' />
        <path d='M274.812 126.969V128.562H269.891V126.969H274.812ZM271.109 124.883H273.359V132.875C273.359 133.12 273.391 133.307 273.453 133.438C273.521 133.568 273.62 133.659 273.75 133.711C273.88 133.758 274.044 133.781 274.242 133.781C274.383 133.781 274.508 133.776 274.617 133.766C274.732 133.75 274.828 133.734 274.906 133.719L274.914 135.375C274.721 135.438 274.513 135.487 274.289 135.523C274.065 135.56 273.818 135.578 273.547 135.578C273.052 135.578 272.62 135.497 272.25 135.336C271.885 135.169 271.604 134.904 271.406 134.539C271.208 134.174 271.109 133.695 271.109 133.102V124.883Z' fill='#C5B3DB' />
        <path d='M280.656 133.516V129.75C280.656 129.479 280.612 129.247 280.523 129.055C280.435 128.857 280.297 128.703 280.109 128.594C279.927 128.484 279.69 128.43 279.398 128.43C279.148 128.43 278.932 128.474 278.75 128.562C278.568 128.646 278.427 128.768 278.328 128.93C278.229 129.086 278.18 129.271 278.18 129.484H275.93C275.93 129.125 276.013 128.784 276.18 128.461C276.346 128.138 276.589 127.854 276.906 127.609C277.224 127.359 277.602 127.164 278.039 127.023C278.482 126.883 278.977 126.812 279.523 126.812C280.18 126.812 280.763 126.922 281.273 127.141C281.784 127.359 282.185 127.688 282.477 128.125C282.773 128.562 282.922 129.109 282.922 129.766V133.383C282.922 133.846 282.951 134.227 283.008 134.523C283.065 134.815 283.148 135.07 283.258 135.289V135.422H280.984C280.875 135.193 280.792 134.906 280.734 134.562C280.682 134.214 280.656 133.865 280.656 133.516ZM280.953 130.273L280.969 131.547H279.711C279.414 131.547 279.156 131.581 278.938 131.648C278.719 131.716 278.539 131.812 278.398 131.938C278.258 132.057 278.154 132.198 278.086 132.359C278.023 132.521 277.992 132.698 277.992 132.891C277.992 133.083 278.036 133.258 278.125 133.414C278.214 133.565 278.341 133.685 278.508 133.773C278.674 133.857 278.87 133.898 279.094 133.898C279.432 133.898 279.727 133.831 279.977 133.695C280.227 133.56 280.419 133.393 280.555 133.195C280.695 132.997 280.768 132.81 280.773 132.633L281.367 133.586C281.284 133.799 281.169 134.021 281.023 134.25C280.883 134.479 280.703 134.695 280.484 134.898C280.266 135.096 280.003 135.26 279.695 135.391C279.388 135.516 279.023 135.578 278.602 135.578C278.065 135.578 277.578 135.471 277.141 135.258C276.708 135.039 276.365 134.74 276.109 134.359C275.859 133.974 275.734 133.536 275.734 133.047C275.734 132.604 275.818 132.211 275.984 131.867C276.151 131.523 276.396 131.234 276.719 131C277.047 130.76 277.456 130.581 277.945 130.461C278.435 130.336 279.003 130.273 279.648 130.273H280.953Z' fill='#C5B3DB' />
        <path d='M295.062 124.047H297.398V131.562C297.398 132.438 297.211 133.174 296.836 133.773C296.466 134.372 295.953 134.823 295.297 135.125C294.646 135.427 293.896 135.578 293.047 135.578C292.198 135.578 291.443 135.427 290.781 135.125C290.125 134.823 289.609 134.372 289.234 133.773C288.865 133.174 288.68 132.438 288.68 131.562V124.047H291.023V131.562C291.023 132.073 291.104 132.49 291.266 132.812C291.427 133.135 291.659 133.372 291.961 133.523C292.268 133.674 292.63 133.75 293.047 133.75C293.474 133.75 293.836 133.674 294.133 133.523C294.435 133.372 294.664 133.135 294.82 132.812C294.982 132.49 295.062 132.073 295.062 131.562V124.047ZM291.945 123.406L293.477 121H295.922L293.773 123.406H291.945Z' fill='#C5B3DB' />
        <path d='M301.391 128.773V135.422H299.141V126.969H301.25L301.391 128.773ZM301.062 130.898H300.453C300.453 130.273 300.534 129.711 300.695 129.211C300.857 128.706 301.083 128.276 301.375 127.922C301.667 127.562 302.013 127.289 302.414 127.102C302.82 126.909 303.273 126.812 303.773 126.812C304.169 126.812 304.531 126.87 304.859 126.984C305.188 127.099 305.469 127.281 305.703 127.531C305.943 127.781 306.125 128.112 306.25 128.523C306.38 128.935 306.445 129.438 306.445 130.031V135.422H304.18V130.023C304.18 129.648 304.128 129.357 304.023 129.148C303.919 128.94 303.766 128.794 303.562 128.711C303.365 128.622 303.12 128.578 302.828 128.578C302.526 128.578 302.263 128.638 302.039 128.758C301.82 128.878 301.638 129.044 301.492 129.258C301.352 129.466 301.245 129.711 301.172 129.992C301.099 130.273 301.062 130.576 301.062 130.898Z' fill='#C5B3DB' />
        <path d='M310.531 126.969V135.422H308.273V126.969H310.531ZM308.133 124.766C308.133 124.438 308.247 124.167 308.477 123.953C308.706 123.74 309.013 123.633 309.398 123.633C309.779 123.633 310.083 123.74 310.312 123.953C310.547 124.167 310.664 124.438 310.664 124.766C310.664 125.094 310.547 125.365 310.312 125.578C310.083 125.792 309.779 125.898 309.398 125.898C309.013 125.898 308.706 125.792 308.477 125.578C308.247 125.365 308.133 125.094 308.133 124.766Z' fill='#C5B3DB' />
        <path d='M315.883 133.82C316.159 133.82 316.404 133.768 316.617 133.664C316.831 133.555 316.997 133.404 317.117 133.211C317.242 133.013 317.307 132.781 317.312 132.516H319.43C319.424 133.109 319.266 133.638 318.953 134.102C318.641 134.56 318.221 134.922 317.695 135.188C317.169 135.448 316.581 135.578 315.93 135.578C315.273 135.578 314.701 135.469 314.211 135.25C313.727 135.031 313.323 134.729 313 134.344C312.677 133.953 312.435 133.5 312.273 132.984C312.112 132.464 312.031 131.906 312.031 131.312V131.086C312.031 130.487 312.112 129.93 312.273 129.414C312.435 128.893 312.677 128.44 313 128.055C313.323 127.664 313.727 127.359 314.211 127.141C314.695 126.922 315.263 126.812 315.914 126.812C316.607 126.812 317.214 126.945 317.734 127.211C318.26 127.477 318.672 127.857 318.969 128.352C319.271 128.841 319.424 129.422 319.43 130.094H317.312C317.307 129.812 317.247 129.557 317.133 129.328C317.023 129.099 316.862 128.917 316.648 128.781C316.44 128.641 316.182 128.57 315.875 128.57C315.547 128.57 315.279 128.641 315.07 128.781C314.862 128.917 314.701 129.104 314.586 129.344C314.471 129.578 314.391 129.846 314.344 130.148C314.302 130.445 314.281 130.758 314.281 131.086V131.312C314.281 131.641 314.302 131.956 314.344 132.258C314.385 132.56 314.464 132.828 314.578 133.062C314.698 133.297 314.862 133.482 315.07 133.617C315.279 133.753 315.549 133.82 315.883 133.82Z' fill='#C5B3DB' />
        <path d='M325.297 133.516V129.75C325.297 129.479 325.253 129.247 325.164 129.055C325.076 128.857 324.938 128.703 324.75 128.594C324.568 128.484 324.331 128.43 324.039 128.43C323.789 128.43 323.573 128.474 323.391 128.562C323.208 128.646 323.068 128.768 322.969 128.93C322.87 129.086 322.82 129.271 322.82 129.484H320.57C320.57 129.125 320.654 128.784 320.82 128.461C320.987 128.138 321.229 127.854 321.547 127.609C321.865 127.359 322.242 127.164 322.68 127.023C323.122 126.883 323.617 126.812 324.164 126.812C324.82 126.812 325.404 126.922 325.914 127.141C326.424 127.359 326.826 127.688 327.117 128.125C327.414 128.562 327.562 129.109 327.562 129.766V133.383C327.562 133.846 327.591 134.227 327.648 134.523C327.706 134.815 327.789 135.07 327.898 135.289V135.422H325.625C325.516 135.193 325.432 134.906 325.375 134.562C325.323 134.214 325.297 133.865 325.297 133.516ZM325.594 130.273L325.609 131.547H324.352C324.055 131.547 323.797 131.581 323.578 131.648C323.359 131.716 323.18 131.812 323.039 131.938C322.898 132.057 322.794 132.198 322.727 132.359C322.664 132.521 322.633 132.698 322.633 132.891C322.633 133.083 322.677 133.258 322.766 133.414C322.854 133.565 322.982 133.685 323.148 133.773C323.315 133.857 323.51 133.898 323.734 133.898C324.073 133.898 324.367 133.831 324.617 133.695C324.867 133.56 325.06 133.393 325.195 133.195C325.336 132.997 325.409 132.81 325.414 132.633L326.008 133.586C325.924 133.799 325.81 134.021 325.664 134.25C325.523 134.479 325.344 134.695 325.125 134.898C324.906 135.096 324.643 135.26 324.336 135.391C324.029 135.516 323.664 135.578 323.242 135.578C322.706 135.578 322.219 135.471 321.781 135.258C321.349 135.039 321.005 134.74 320.75 134.359C320.5 133.974 320.375 133.536 320.375 133.047C320.375 132.604 320.458 132.211 320.625 131.867C320.792 131.523 321.036 131.234 321.359 131C321.688 130.76 322.096 130.581 322.586 130.461C323.076 130.336 323.643 130.273 324.289 130.273H325.594Z' fill='#C5B3DB' />
        <g opacity='0.7'>
          <path d='M338.912 342.907C338.912 342.907 333.675 331.492 339.653 320.229C345.631 308.965 347.374 306.315 343.561 304.014C339.748 301.714 337.989 313.017 337.989 313.017C337.989 313.017 334.805 305.232 337.424 297.097C340.043 288.962 341.746 275.684 338.124 274.26C334.503 272.835 337.432 290.808 334.288 296.938C332.684 292.635 331.649 288.143 331.207 283.573C330.777 277.03 331.454 266.586 328.588 263.904C325.723 261.221 323.9 263.108 326.71 277.515C328.399 286.144 329.334 294.904 329.504 303.696C329.504 303.696 323.287 284.751 319.156 284.202C315.024 283.652 314.993 289.774 318.567 295.099C322.141 300.424 328.986 309.944 330.109 314.879C330.109 314.879 323.295 300.631 317.158 304.993C311.021 309.355 322.499 317.976 327.609 321.088C332.72 324.201 336.278 343.695 336.278 343.695L338.912 342.907Z' fill='#7E57C2' />
          <path opacity='0.2' d='M338.912 342.907C338.912 342.907 333.675 331.492 339.653 320.229C345.631 308.965 347.374 306.315 343.561 304.014C339.748 301.714 337.989 313.017 337.989 313.017C337.989 313.017 334.805 305.232 337.424 297.097C340.043 288.962 341.746 275.684 338.124 274.26C334.503 272.835 337.432 290.808 334.288 296.938C332.684 292.635 331.649 288.143 331.207 283.573C330.777 277.03 331.454 266.586 328.588 263.904C325.723 261.221 323.9 263.108 326.71 277.515C328.399 286.144 329.334 294.904 329.504 303.696C329.504 303.696 323.287 284.751 319.156 284.202C315.024 283.652 314.993 289.774 318.567 295.099C322.141 300.424 328.986 309.944 330.109 314.879C330.109 314.879 323.295 300.631 317.158 304.993C311.021 309.355 322.499 317.976 327.609 321.088C332.72 324.201 336.278 343.695 336.278 343.695L338.912 342.907Z' fill='black' />
          <path d='M334.773 347.595C334.773 347.595 339.876 336.093 333.762 324.925C327.649 313.757 325.874 311.13 329.663 308.766C333.452 306.402 335.33 317.689 335.33 317.689C335.33 317.689 338.451 309.865 335.705 301.769C332.958 293.674 331.128 280.421 334.733 278.948C338.339 277.476 335.625 295.489 338.841 301.562C340.394 297.241 341.373 292.734 341.754 288.158C342.112 281.607 341.308 271.171 344.142 268.449C346.976 265.727 348.831 267.557 346.188 282.037C344.604 290.686 343.775 299.456 343.712 308.249C343.712 308.249 349.698 289.217 353.861 288.604C358.024 287.991 358.088 294.12 354.586 299.493C351.083 304.866 344.317 314.498 343.243 319.393C343.243 319.393 349.889 305.065 356.074 309.324C362.259 313.582 350.892 322.378 345.822 325.562C340.751 328.746 337.4 348.312 337.4 348.312L334.773 347.595Z' fill='#7E57C2' />
          <path d='M358.287 339.396H316.179L319.586 369.533H354.88L358.287 339.396Z' fill='#263238' />
        </g>
        <path d='M153.614 135.332H116.454C115.023 135.332 113.65 135.901 112.638 136.913C111.626 137.925 111.058 139.297 111.058 140.729V176.856C111.058 178.287 111.626 179.66 112.638 180.672C113.65 181.684 115.023 182.253 116.454 182.253H128.344L120.34 193.131L134.177 182.253H153.603C155.035 182.253 156.407 181.684 157.42 180.672C158.432 179.66 159 178.287 159 176.856V140.729C159 139.299 158.433 137.928 157.423 136.916C156.414 135.905 155.044 135.335 153.614 135.332Z' fill='#7E57C2' />
        <path opacity='0.6' d='M153.614 135.332H116.454C115.023 135.332 113.65 135.901 112.638 136.913C111.626 137.925 111.058 139.297 111.058 140.729V176.856C111.058 178.287 111.626 179.66 112.638 180.672C113.65 181.684 115.023 182.253 116.454 182.253H128.344L120.34 193.131L134.177 182.253H153.603C155.035 182.253 156.407 181.684 157.42 180.672C158.432 179.66 159 178.287 159 176.856V140.729C159 139.299 158.433 137.928 157.423 136.916C156.414 135.905 155.044 135.335 153.614 135.332Z' fill='white' />
        <path d='M135.04 175.664C144.357 175.664 151.911 168.11 151.911 158.792C151.911 149.474 144.357 141.921 135.04 141.921C125.722 141.921 118.168 149.474 118.168 158.792C118.168 168.11 125.722 175.664 135.04 175.664Z' fill='#613F9B' fillOpacity='0.9' />
        <path d='M149.113 162.105C150.948 154.329 146.133 146.538 138.357 144.703C130.581 142.867 122.79 147.683 120.955 155.459C119.12 163.234 123.936 171.025 131.711 172.861C139.487 174.696 147.278 169.88 149.113 162.105Z' fill='#794EC2' />
        <path d='M133.241 165.701C133.045 165.705 132.85 165.671 132.667 165.599C132.484 165.528 132.318 165.422 132.176 165.286L126.034 159.272C125.752 158.998 125.59 158.623 125.584 158.23C125.578 157.837 125.729 157.457 126.002 157.175C126.276 156.892 126.651 156.73 127.044 156.724C127.437 156.718 127.817 156.869 128.099 157.143L133.134 162.071L141.841 152.353C142.108 152.084 142.468 151.927 142.847 151.916C143.226 151.905 143.595 152.039 143.878 152.292C144.16 152.544 144.335 152.896 144.366 153.273C144.397 153.651 144.282 154.027 144.045 154.322L134.305 165.19C134.171 165.34 134.007 165.461 133.824 165.545C133.641 165.63 133.442 165.675 133.241 165.68V165.701Z' fill='white' />
        <path d='M92.9484 351.432L115.881 349.171V323.309H124.972V349.163L148 351.432C149.103 351.539 150.127 352.053 150.872 352.874C151.617 353.695 152.029 354.765 152.028 355.873V359.32H88.9207V355.873C88.9195 354.765 89.3314 353.695 90.0762 352.874C90.8209 352.053 91.8449 351.539 92.9484 351.432Z' fill='#263238' />
        <path opacity='0.1' d='M92.9484 351.432L115.881 349.171V323.309H124.972V349.163L148 351.432C149.103 351.539 150.127 352.053 150.872 352.874C151.617 353.695 152.029 354.765 152.028 355.873V359.32H88.9207V355.873C88.9195 354.765 89.3314 353.695 90.0762 352.874C90.8209 352.053 91.8449 351.539 92.9484 351.432Z' fill='white' />
        <path d='M149.6 369.533C152.884 369.533 155.546 366.871 155.546 363.587C155.546 360.303 152.884 357.641 149.6 357.641C146.316 357.641 143.654 360.303 143.654 363.587C143.654 366.871 146.316 369.533 149.6 369.533Z' fill='#263238' />
        <path d='M91.3485 369.533C94.6324 369.533 97.2946 366.871 97.2946 363.587C97.2946 360.303 94.6324 357.641 91.3485 357.641C88.0645 357.641 85.4023 360.303 85.4023 363.587C85.4023 366.871 88.0645 369.533 91.3485 369.533Z' fill='#263238' />
        <path d='M123.244 330.234H117.577C116.705 330.234 115.869 329.888 115.252 329.272C114.635 328.655 114.289 327.819 114.289 326.947V301.523H126.532V326.947C126.532 327.819 126.185 328.655 125.569 329.272C124.952 329.888 124.116 330.234 123.244 330.234Z' fill='#263238' />
        <path opacity='0.2' d='M123.244 330.234H117.577C116.705 330.234 115.869 329.888 115.252 329.272C114.635 328.655 114.289 327.819 114.289 326.947V301.523H126.532V326.947C126.532 327.819 126.185 328.655 125.569 329.272C124.952 329.888 124.116 330.234 123.244 330.234Z' fill='white' />
        <path d='M88.6818 251.693C88.6818 251.693 95.4956 294.184 108.948 301.531H95.2727C95.2727 301.531 81.7407 287.043 77.6652 251.693H88.6818Z' fill='#263238' />
        <path opacity='0.1' d='M88.6818 251.693C88.6818 251.693 95.4956 294.184 108.948 301.531H95.2727C95.2727 301.531 81.7407 287.043 77.6652 251.693H88.6818Z' fill='white' />
        <path d='M94.62 295.521H147.888C148.944 295.521 149.956 295.94 150.703 296.686C151.449 297.433 151.868 298.445 151.868 299.501V304.826H90.64V299.501C90.64 298.445 91.0593 297.433 91.8057 296.686C92.5521 295.94 93.5645 295.521 94.62 295.521Z' fill='#7E57C2' />
        <path d='M152.792 300.169H89.661C88.7774 300.169 88.061 300.886 88.061 301.769V303.218C88.061 304.102 88.7774 304.818 89.661 304.818H152.792C153.675 304.818 154.392 304.102 154.392 303.218V301.769C154.392 300.886 153.675 300.169 152.792 300.169Z' fill='#263238' />
        <path opacity='0.6' d='M152.792 300.169H89.661C88.7774 300.169 88.061 300.886 88.061 301.769V303.218C88.061 304.102 88.7774 304.818 89.661 304.818H152.792C153.675 304.818 154.392 304.102 154.392 303.218V301.769C154.392 300.886 153.675 300.169 152.792 300.169Z' fill='white' />
        <path d='M76.0493 260.338H97.9314C104.347 260.338 108.51 255.195 107.237 248.851L105.533 240.382C104.26 234.014 98.0269 228.904 91.6191 228.904H69.737C63.3213 228.904 59.1582 234.046 60.4318 240.382L62.1352 248.851C63.4088 255.18 69.6336 260.338 76.0493 260.338Z' fill='#7E57C2' />
        <path d='M192.162 331.508L187.147 333.474L183.541 326.676L180.262 320.491L185.818 317.164L189.177 324.75L192.162 331.508Z' fill='#E4897B' />
        <path opacity='0.2' d='M189.177 324.75L183.541 326.676L180.262 320.491L185.818 317.164L189.177 324.75Z' fill='black' />
        <path d='M188.134 321.04L181.312 324.702C181.312 324.702 155.53 279.37 151.566 279.752C147.602 280.134 109.991 304.094 98.5204 295.242C87.05 286.391 98.2099 259.741 98.2099 259.741C98.2099 259.741 121.939 252.959 124.478 258.149C125.147 259.703 125.716 261.298 126.181 262.925C126.683 264.556 127.065 265.933 127.065 265.933C127.789 265.639 151.224 256.031 161.683 261.157C172.357 266.427 188.134 321.04 188.134 321.04Z' fill='#263238' />
        <path opacity='0.3' d='M188.134 321.04L181.312 324.702C181.312 324.702 155.53 279.37 151.566 279.752C147.602 280.134 109.991 304.094 98.5204 295.242C87.05 286.391 98.2099 259.741 98.2099 259.741C98.2099 259.741 121.939 252.959 124.478 258.149C125.147 259.703 125.716 261.298 126.181 262.925C126.683 264.556 127.065 265.933 127.065 265.933C127.789 265.639 151.224 256.031 161.683 261.157C172.357 266.427 188.134 321.04 188.134 321.04Z' fill='white' />
        <path d='M151.072 358.007L145.723 357.394L145.691 349.848L145.683 342.923L152.155 342.62L151.582 350.763L151.072 358.007Z' fill='#E4897B' />
        <path opacity='0.2' d='M151.566 350.93L145.675 349.991V342.994L152.147 342.652L151.566 350.93Z' fill='black' />
        <path d='M177.977 322.45L187.959 317.451L189.002 321.685L181.44 325.586L177.977 322.45Z' fill='#7E57C2' />
        <path d='M116.239 295.314C116.239 295.314 117.879 295.131 143.104 290.458C143.104 290.458 143.678 322.712 144.872 346.759L152.64 347.006C152.64 347.006 163.784 293.674 162.471 285.587C161.158 277.499 151.574 276.441 151.574 276.441C151.574 276.441 134.929 289.408 116.239 295.314Z' fill='#263238' />
        <path opacity='0.4' d='M116.239 295.314C116.239 295.314 117.879 295.131 143.104 290.458C143.104 290.458 143.678 322.712 144.872 346.759L152.64 347.006C152.64 347.006 163.784 293.674 162.471 285.587C161.158 277.499 151.574 276.441 151.574 276.441C151.574 276.441 134.929 289.408 116.239 295.314Z' fill='white' />
        <path opacity='0.3' d='M116.239 295.314C116.239 295.314 117.879 295.131 143.104 290.458C143.104 290.458 143.678 322.712 144.872 346.759L152.64 347.006C152.64 347.006 163.784 293.674 162.471 285.587C161.158 277.499 151.574 276.441 151.574 276.441C151.574 276.441 134.929 289.408 116.239 295.314Z' fill='black' />
        <path d='M143.447 344.61L154.041 344.363L152.593 348.725L143.996 348.805L143.447 344.61Z' fill='#7E57C2' />
        <path opacity='0.3' d='M127.097 265.949L113.35 270.845C113.35 270.845 120.578 265.448 126.213 262.94C126.715 264.572 127.097 265.949 127.097 265.949Z' fill='black' />
        <path d='M96.6179 258.101L97.1274 261.914L122.894 256.445L122.297 251.94L96.6179 258.101Z' fill='#263238' />
        <path opacity='0.2' d='M96.6179 258.101L97.1274 261.914L122.894 256.445L122.297 251.94L96.6179 258.101Z' fill='black' />
        <path d='M105.155 255.33L103.872 255.569L105.084 262.104L106.368 261.866L105.155 255.33Z' fill='#263238' />
        <path d='M116.391 253.677L110.769 254.944L111.73 259.207L117.352 257.94L116.391 253.677Z' fill='white' />
        <path d='M120.006 251.015L118.727 251.25L119.923 257.763L121.202 257.528L120.006 251.015Z' fill='#263238' />
        <path opacity='0.3' d='M105.155 255.33L103.872 255.569L105.084 262.104L106.368 261.866L105.155 255.33Z' fill='white' />
        <path opacity='0.3' d='M120.006 251.015L118.727 251.25L119.923 257.763L121.202 257.528L120.006 251.015Z' fill='white' />
        <path d='M202.128 340.407C201.291 340.15 200.414 340.051 199.541 340.113C199.541 340.113 196.301 338.759 192.377 332.32L189.034 332.758C188.471 332.842 187.948 333.097 187.535 333.489C187.122 333.88 186.839 334.389 186.725 334.947C186.701 335.105 186.701 335.266 186.725 335.424C186.869 336.232 187.066 337.03 187.314 337.812C188.171 340.4 189.184 342.933 190.347 345.398L190.888 345.175L189.161 340.351C188.588 338.704 190.586 340.097 191.644 341.689C192.703 343.281 194.208 344.825 197.782 343.472C201.937 341.848 203.584 340.988 202.128 340.407Z' fill='#263238' />
        <path d='M191.541 330.608C191.541 330.608 196.906 338.759 199.541 340.113C199.541 340.113 196.588 342.501 192.647 338.664C187.561 333.737 186.709 335.424 186.709 335.424C186.664 334.459 186.709 333.492 186.845 332.535C187.251 330.664 191.541 330.608 191.541 330.608Z' fill='#E4897B' />
        <path d='M159.096 367.495C158.333 367.056 157.497 366.759 156.628 366.62C156.628 366.62 153.803 364.542 151.534 357.362L148.183 356.996C147.614 356.942 147.043 357.066 146.548 357.352C146.053 357.637 145.659 358.069 145.421 358.588C145.349 358.731 145.301 358.884 145.277 359.042C145.222 359.855 145.222 360.672 145.277 361.485C145.493 364.202 145.876 366.903 146.424 369.573L147.005 369.485L146.487 364.375C146.32 362.64 147.936 364.47 148.589 366.269C149.241 368.068 150.332 369.923 154.121 369.453C158.547 368.856 160.346 368.411 159.096 367.495Z' fill='#263238' />
        <path d='M151.136 355.476C151.136 355.476 154.416 364.669 156.652 366.62C156.652 366.62 153.222 368.212 150.284 363.579C146.503 357.585 145.285 359.026 145.285 359.026C145.463 358.079 145.73 357.152 146.081 356.256C146.925 354.504 151.136 355.476 151.136 355.476Z' fill='#E4897B' />
        <path d='M91.0062 193.092L84.272 194.445C84.009 194.666 83.6982 194.824 83.364 194.906C83.0298 194.987 82.6813 194.99 82.3457 194.914C80.3398 194.118 77.4503 191.786 77.0841 190.934C76.5429 189.732 77.0841 186.206 78.501 185.569C79.9179 184.933 84.7974 190.823 84.7974 190.823L92.033 189.191L91.0062 193.092Z' fill='#E4897B' />
        <path d='M112.243 215.475C112.243 215.475 111.631 193.657 109.107 192.725C106.584 191.794 87.6152 194.397 87.6152 194.397L87.1536 189.414C87.1536 189.414 109.227 186.015 113.501 188.029C117.776 190.043 119.185 222.559 118.405 228.824L112.243 215.475Z' fill='#7E57C2' />
        <path opacity='0.6' d='M112.243 215.475C112.243 215.475 111.631 193.657 109.107 192.725C106.584 191.794 87.6152 194.397 87.6152 194.397L87.1536 189.414C87.1536 189.414 109.227 186.015 113.501 188.029C117.776 190.043 119.185 222.559 118.405 228.824L112.243 215.475Z' fill='white' />
        <path d='M87.9415 194.875L86.6201 189.398L88.5146 188.714L89.7166 195.042L87.9415 194.875Z' fill='#7E57C2' />
        <path opacity='0.7' d='M87.9415 194.875L86.6201 189.398L88.5146 188.714L89.7166 195.042L87.9415 194.875Z' fill='white' />
        <path d='M112.132 212.753C112.132 212.753 110.437 219.391 102.27 221.509C94.1026 223.626 85.068 218.094 85.068 218.094L86.867 233.934L96.8647 258.889L122.416 252.226C122.416 252.226 120.888 223.196 112.132 212.753Z' fill='#7E57C2' />
        <path opacity='0.7' d='M112.132 212.753C112.132 212.753 110.437 219.391 102.27 221.509C94.1026 223.626 85.068 218.094 85.068 218.094L86.867 233.934L96.8647 258.889L122.416 252.226C122.416 252.226 120.888 223.196 112.132 212.753Z' fill='white' />
        <path opacity='0.6' d='M116.573 239.076L119.564 238.132C119.895 238.028 120.079 237.675 119.974 237.343L118.192 231.696C118.088 231.364 117.735 231.181 117.403 231.285L114.412 232.229C114.081 232.333 113.897 232.686 114.002 233.018L115.784 238.665C115.888 238.997 116.241 239.18 116.573 239.076Z' fill='#7E57C2' />
        <path opacity='0.2' d='M115.964 237.354L119.365 236.281L117.896 231.628L114.496 232.701L115.964 237.354Z' fill='black' />
        <path d='M115.85 229.946L115.098 230.183L115.963 232.923L116.714 232.686L115.85 229.946Z' fill='#263238' />
        <path d='M94.7633 187.957C88.4669 187.957 84.3118 194.628 85.7844 205.207C87.257 215.785 93.1553 218.834 93.1553 218.834L92.7812 216.279C99.3292 217.765 106.08 218.142 112.753 217.393C112.753 217.393 109.712 208.765 110.699 199.953C110.699 199.953 112.697 187.941 94.7633 187.957Z' fill='#263238' />
        <path opacity='0.2' d='M107.269 219.359L109.84 228.911L105.318 225.465C105.318 225.465 109.72 235.526 110.89 241.385C110.89 241.385 107.085 231.411 103.806 225.942L101.888 231.419L94.1743 223.562C94.1743 223.562 100.988 218.34 107.269 219.359Z' fill='black' />
        <path d='M103.511 222.44L95.6628 222.679C99.1413 218.229 92.8529 210.476 92.8529 210.476L100.606 212.156C102.453 219.829 105.645 220.06 105.645 220.06L103.511 222.44Z' fill='#E4897B' />
        <path opacity='0.2' d='M102.771 217.664C99.7762 217.414 96.9908 216.027 94.9862 213.788C94.3669 212.637 93.6533 211.54 92.8529 210.508L100.606 212.196C101.017 214.127 101.748 215.975 102.771 217.664Z' fill='black' />
        <path d='M100.598 191.197C107.356 191.762 109.235 195.559 109.147 202.572C109.044 211.328 106.592 217.354 98.2975 214.79C87.0261 211.312 89.3664 190.258 100.598 191.197Z' fill='#E4897B' />
        <path d='M104.809 201.847C105.266 202.903 105.849 203.9 106.544 204.816C106.322 205.078 106.032 205.273 105.706 205.381C105.381 205.489 105.032 205.506 104.697 205.429L104.809 201.847Z' fill='#DE5753' />
        <path d='M100.232 200.717L101.696 200.24C101.696 200.24 100.916 201.402 100.232 200.717Z' fill='#263238' />
        <path d='M100.741 200.55C100.469 200.416 100.157 200.386 99.8642 200.465C99.5713 200.544 99.3171 200.727 99.1492 200.98C99.3991 200.866 99.6705 200.807 99.9452 200.807C100.22 200.807 100.491 200.866 100.741 200.98V200.55Z' fill='#263238' />
        <path d='M98.0189 197.326C97.9437 197.324 97.8722 197.293 97.8199 197.239C97.7922 197.213 97.7702 197.181 97.7551 197.146C97.74 197.111 97.7322 197.074 97.7322 197.036C97.7322 196.998 97.74 196.96 97.7551 196.925C97.7702 196.89 97.7922 196.859 97.8199 196.833C98.2638 196.445 98.7919 196.165 99.3624 196.016C99.9329 195.866 100.53 195.852 101.107 195.973C101.182 195.989 101.248 196.034 101.29 196.098C101.332 196.162 101.346 196.241 101.33 196.315C101.314 196.39 101.27 196.456 101.205 196.498C101.141 196.54 101.063 196.554 100.988 196.538C100.506 196.44 100.009 196.451 99.5321 196.571C99.0554 196.691 98.6117 196.916 98.2339 197.231C98.2072 197.261 98.1741 197.286 98.137 197.302C98.0999 197.319 98.0596 197.327 98.0189 197.326Z' fill='#263238' />
        <path d='M108.622 197.446C108.559 197.448 108.497 197.428 108.447 197.39C108.128 197.098 107.748 196.881 107.335 196.754C106.922 196.627 106.486 196.594 106.059 196.658C106.022 196.668 105.984 196.67 105.946 196.665C105.909 196.66 105.872 196.648 105.839 196.629C105.806 196.611 105.778 196.585 105.754 196.555C105.731 196.525 105.714 196.491 105.704 196.455C105.694 196.418 105.692 196.38 105.697 196.342C105.701 196.305 105.714 196.268 105.732 196.235C105.751 196.203 105.776 196.174 105.806 196.151C105.836 196.127 105.871 196.11 105.907 196.1C106.423 196.009 106.953 196.037 107.457 196.182C107.96 196.327 108.424 196.585 108.813 196.936C108.872 196.985 108.91 197.054 108.918 197.13C108.927 197.205 108.907 197.281 108.86 197.342C108.832 197.377 108.795 197.405 108.753 197.423C108.712 197.441 108.667 197.448 108.622 197.446Z' fill='#263238' />
        <path d='M92.232 201.975C92.232 201.975 95.8538 196.69 95.7344 192.359C95.7344 192.359 109.593 187.997 109.123 201.657C109.123 201.657 111.432 189.717 99.8338 188.451C88.2361 187.185 86.8909 204.236 92.4708 209.808C92.4708 209.808 90.0112 205.286 92.232 201.975Z' fill='#263238' />
        <path d='M93.2429 203.495C93.2429 202.707 92.9304 201.951 92.3738 201.393C91.8172 200.835 91.062 200.52 90.2739 200.518C88.1565 200.574 86.8033 204.793 91.9295 206.265C92.6619 206.472 93.1474 205.78 93.2429 203.495Z' fill='#E4897B' />
        <path d='M104.753 207.42C104.257 208.085 103.813 208.788 103.424 209.521C103.288 209.473 103.145 209.434 103.01 209.378C101.139 208.717 100.487 207.786 100.335 206.99C100.264 206.585 100.294 206.17 100.423 205.78C100.489 205.556 100.586 205.341 100.709 205.143C101.682 206.067 102.845 206.768 104.116 207.197C104.498 207.34 104.753 207.42 104.753 207.42Z' fill='#263238' />
        <path d='M104.116 207.181L103.71 207.794C101.943 207.165 100.789 206.488 100.423 205.764C100.489 205.54 100.586 205.325 100.709 205.127C101.682 206.051 102.845 206.752 104.116 207.181Z' fill='white' />
        <path d='M103.01 209.378C101.139 208.717 100.487 207.786 100.335 206.99C101.043 207.25 101.698 207.636 102.27 208.128C102.64 208.458 102.899 208.894 103.01 209.378Z' fill='#DE5753' />
        <path d='M100.415 191.699C100.415 191.699 96.7612 202.596 89.7723 201.848C82.7834 201.099 94.7632 187.933 94.7632 187.933C94.7632 187.933 106.552 184.566 111.686 191.117C116.82 197.669 109.147 201.641 109.147 201.641C109.147 201.641 110.293 193.092 100.415 191.699Z' fill='#263238' />
        <path d='M94.8429 200.972C94.8025 200.97 94.7633 200.957 94.7297 200.935C94.696 200.912 94.6691 200.881 94.6518 200.845C94.6336 200.794 94.6359 200.738 94.6582 200.689C94.6805 200.64 94.721 200.602 94.7712 200.582C100.343 198.457 100.216 190.982 100.216 190.903C100.214 190.877 100.217 190.85 100.226 190.826C100.234 190.801 100.248 190.779 100.266 190.76C100.284 190.741 100.306 190.726 100.33 190.716C100.355 190.707 100.381 190.702 100.407 190.704C100.459 190.705 100.508 190.725 100.546 190.76C100.584 190.796 100.608 190.843 100.614 190.895C100.614 190.974 100.741 198.727 94.9145 200.956L94.8429 200.972Z' fill='#263238' />
        <path d='M107.093 201.378L108.558 200.908C108.558 200.908 107.77 202.062 107.093 201.378Z' fill='#263238' />
        <path d='M107.603 201.211C107.33 201.076 107.017 201.046 106.723 201.127C106.43 201.207 106.176 201.393 106.011 201.649C106.257 201.526 106.527 201.458 106.802 201.45C107.076 201.442 107.35 201.493 107.603 201.601V201.211Z' fill='#263238' />
        <path d='M96.7692 219.463L102.683 222.472L102.588 228.466L94.1344 223.538L96.7692 219.463Z' fill='#7E57C2' />
        <path d='M103.583 218.333L103.933 221.97L107.794 226.054L107.268 219.336L103.583 218.333Z' fill='#7E57C2' />
        <path d='M76.2563 177.172L73.7887 170.804C73.8337 170.463 73.7989 170.117 73.687 169.792C73.5751 169.467 73.3892 169.173 73.1439 168.933C71.4246 167.651 67.8744 166.489 66.9829 166.625C65.6854 166.816 63.0029 169.164 63.2337 170.7C63.4646 172.236 71.0425 173.184 71.0425 173.184L73.5578 180.164L76.2563 177.172Z' fill='#E4897B' />
        <path d='M86.8828 233.934C86.8828 233.934 91.6588 230.137 89.7564 221.747L84.6222 198.536L75.8025 174.561L71.4882 176.742C71.4882 176.742 78.7318 200.168 79.4004 201.752C79.7427 202.548 81.1038 214.703 82.9585 222.846C84.662 230.344 86.8828 233.934 86.8828 233.934Z' fill='#7E57C2' />
        <path opacity='0.6' d='M86.8828 233.934C86.8828 233.934 91.6588 230.137 89.7564 221.747L84.6222 198.536L75.8025 174.561L71.4882 176.742C71.4882 176.742 78.7318 200.168 79.4004 201.752C79.7427 202.548 81.1038 214.703 82.9585 222.846C84.662 230.344 86.8828 233.934 86.8828 233.934Z' fill='white' />
        <path d='M70.7321 176.057L75.7707 173.534L76.8692 175.213L70.9788 177.824L70.7321 176.057Z' fill='#7E57C2' />
        <path opacity='0.7' d='M70.7321 176.057L75.7707 173.534L76.8692 175.213L70.9788 177.824L70.7321 176.057Z' fill='white' />
        <path opacity='0.6' d='M96.7692 219.463L102.683 222.472L102.588 228.466L94.1344 223.538L96.7692 219.463Z' fill='white' />
        <path opacity='0.6' d='M103.583 218.333L103.933 221.97L107.794 226.054L107.268 219.336L103.583 218.333Z' fill='white' />
        <path d='M271.539 206.95H247.691V225.481H271.539V206.95Z' fill='white' />
        <path opacity='0.1' d='M271.539 206.95H265.426V225.481H271.539V206.95Z' fill='black' />
        <path d='M246.322 241.847H273.282V208.216H264.598L246.322 216.964V241.847Z' fill='#7E57C2' />
        <path opacity='0.2' d='M273.282 208.216H264.598V241.847H273.282V208.216Z' fill='black' />
        <path opacity='0.7' d='M261.724 223.594H249.1V229.644H261.724V223.594Z' fill='white' />
        <path opacity='0.7' d='M261.724 231.666H249.1V232.533H261.724V231.666Z' fill='white' />
        <path opacity='0.7' d='M272.438 234.205H265.426V239.339H272.438V234.205Z' fill='white' />
        <path d='M261.406 205.939H237.558V225.481H261.406V205.939Z' fill='white' />
        <path opacity='0.1' d='M261.406 205.939H255.293V225.481H261.406V205.939Z' fill='black' />
        <path d='M236.189 241.847H263.149V208.216H254.465L236.189 216.964V241.847Z' fill='#7E57C2' />
        <path opacity='0.2' d='M263.149 208.216H254.465V241.847H263.149V208.216Z' fill='black' />
        <path opacity='0.7' d='M251.591 223.594H238.967V229.644H251.591V223.594Z' fill='white' />
        <path opacity='0.7' d='M251.591 231.666H238.967V232.533H251.591V231.666Z' fill='white' />
        <path opacity='0.7' d='M262.305 234.205H255.293V239.339H262.305V234.205Z' fill='white' />
        <path d='M219.441 369.533H224.32L233.076 247.546H226.605L219.441 369.533Z' fill='#263238' />
        <path opacity='0.1' d='M219.441 369.533H224.32L232.646 253.603L233.076 247.745H226.605L226.263 253.603L219.441 369.533Z' fill='white' />
        <path opacity='0.2' d='M226.263 253.317H232.647L233.076 247.745H226.605L226.263 253.317Z' fill='black' />
        <path d='M183.653 369.533H188.532L189.328 247.546H182.857L183.653 369.533Z' fill='#263238' />
        <path opacity='0.1' d='M182.849 247.745L182.984 267.422L183.645 369.533H188.524L189.193 267.422L189.32 247.745H182.849Z' fill='white' />
        <path opacity='0.2' d='M182.849 247.745L182.984 267.645H189.193L189.32 247.745H182.849Z' fill='black' />
        <path d='M131.881 369.533H136.76L145.516 247.546H139.045L131.881 369.533Z' fill='#263238' />
        <path opacity='0.1' d='M131.881 369.533H136.76L145.094 253.603L145.516 247.745H139.045L138.702 253.603L131.881 369.533Z' fill='white' />
        <path opacity='0.2' d='M138.703 253.317H145.094L145.516 247.745H139.045L138.703 253.317Z' fill='black' />
        <path d='M275.224 369.533H280.104L272.932 247.546H266.46L275.224 369.533Z' fill='#263238' />
        <path opacity='0.1' d='M266.46 247.745L266.898 253.603L275.224 369.533H280.096L273.282 253.603L272.932 247.745H266.46Z' fill='white' />
        <path opacity='0.2' d='M272.932 247.745H266.46L266.898 253.317H273.282L272.932 247.745Z' fill='black' />
        <path d='M276.347 250.603L135.646 250.603C135.42 250.603 135.196 250.558 134.988 250.472C134.779 250.385 134.59 250.259 134.43 250.099C134.27 249.939 134.144 249.75 134.057 249.541C133.971 249.333 133.926 249.109 133.926 248.883V244.497L278.066 244.497V248.883C278.066 249.109 278.022 249.333 277.935 249.541C277.849 249.75 277.722 249.939 277.563 250.099C277.403 250.259 277.213 250.385 277.005 250.472C276.796 250.558 276.573 250.603 276.347 250.603Z' fill='#7E57C2' />
        <path opacity='0.4' d='M276.347 250.603L135.646 250.603C135.42 250.603 135.196 250.558 134.988 250.472C134.779 250.385 134.59 250.259 134.43 250.099C134.27 249.939 134.144 249.75 134.057 249.541C133.971 249.333 133.926 249.109 133.926 248.883V244.497L278.066 244.497V248.883C278.066 249.109 278.022 249.333 277.935 249.541C277.849 249.75 277.722 249.939 277.563 250.099C277.403 250.259 277.213 250.385 277.005 250.472C276.796 250.558 276.573 250.603 276.347 250.603Z' fill='white' />
        <path opacity='0.2' d='M133.926 247.546L278.066 247.546V244.489L133.926 244.489V247.546Z' fill='black' />
        <path d='M130.902 245.285H281.083C281.309 245.286 281.533 245.242 281.742 245.156C281.951 245.07 282.141 244.944 282.301 244.784C282.461 244.624 282.588 244.434 282.674 244.225C282.76 244.016 282.803 243.792 282.802 243.566C282.802 243.11 282.621 242.672 282.299 242.35C281.976 242.028 281.539 241.846 281.083 241.846H130.902C130.445 241.846 130.007 242.027 129.683 242.35C129.359 242.672 129.176 243.109 129.174 243.566C129.174 243.792 129.219 244.016 129.306 244.226C129.393 244.435 129.52 244.625 129.681 244.784C129.841 244.944 130.032 245.071 130.241 245.157C130.451 245.242 130.675 245.286 130.902 245.285Z' fill='#7E57C2' />
        <path opacity='0.4' d='M130.902 245.285H281.083C281.309 245.286 281.533 245.242 281.742 245.156C281.951 245.07 282.141 244.944 282.301 244.784C282.461 244.624 282.588 244.434 282.674 244.225C282.76 244.016 282.803 243.792 282.802 243.566C282.802 243.11 282.621 242.672 282.299 242.35C281.976 242.028 281.539 241.846 281.083 241.846H130.902C130.445 241.846 130.007 242.027 129.683 242.35C129.359 242.672 129.176 243.109 129.174 243.566C129.174 243.792 129.219 244.016 129.306 244.226C129.393 244.435 129.52 244.625 129.681 244.784C129.841 244.944 130.032 245.071 130.241 245.157C130.451 245.242 130.675 245.286 130.902 245.285Z' fill='white' />
        <path d='M248.813 241.831V239.14H250.62L248.813 238.089H250.166L248.359 236.911H246.648V235.558H248.638L246.648 234.77V231.833H248.638V229.915H250.62L248.638 229.246V227.877H250.055L248.893 226.723L247.89 225.712H217.132V227.089H215.102L217.363 228.108V229.246H213.216V231.363H211.377L213.216 232.287V234.651H214.521V235.781H217.132V239.14H215.668L216.87 240.342V241.847L248.813 241.831Z' fill='#7E57C2' />
        <path opacity='0.7' d='M248.813 241.831V239.14H250.62L248.813 238.089H250.166L248.359 236.911H246.648V235.558H248.638L246.648 234.77V231.833H248.638V229.915H250.62L248.638 229.246V227.877H250.055L248.893 226.723L247.89 225.712H217.132V227.089H215.102L217.363 228.108V229.246H213.216V231.363H211.377L213.216 232.287V234.651H214.521V235.781H217.132V239.14H215.668L216.87 240.342V241.847L248.813 241.831Z' fill='white' />
        <path opacity='0.1' d='M248.813 241.831V239.14H250.62L248.813 238.089H250.166L248.359 236.911H246.648V235.558H248.638L246.648 234.77V231.833H248.638V229.915H250.62L248.638 229.246V227.877H250.055L248.893 226.723L247.89 225.712H231.691V227.089H229.661L231.914 228.108V229.246H227.767V231.363H225.928L227.767 232.287V234.651H229.072V235.781H231.691V239.14H230.226L231.42 240.342V241.847L248.813 241.831Z' fill='black' />
        <path opacity='0.1' d='M217.363 229.246H231.914L217.363 228.72V229.246Z' fill='black' />
        <path opacity='0.1' d='M217.132 235.781H231.691L231.755 236.8L217.132 235.781Z' fill='black' />
        <path opacity='0.1' d='M214.521 234.651H229.072L214.521 235.16V234.651Z' fill='black' />
        <path opacity='0.1' d='M217.132 239.14H231.691L217.132 238.607V239.14Z' fill='black' />
        <path opacity='0.1' d='M216.87 240.342H231.42V240.899L216.87 240.342Z' fill='black' />
        <path opacity='0.1' d='M213.216 231.363H227.767L213.216 230.902V231.363Z' fill='black' />
        <path opacity='0.1' d='M217.132 227.089H231.691L217.132 226.579V227.089Z' fill='black' />
        <path opacity='0.1' d='M248.638 227.877H233.872L248.638 228.33V227.877Z' fill='black' />
        <path opacity='0.1' d='M231.914 229.246H248.638L233.474 229.739L231.914 229.246Z' fill='black' />
        <path opacity='0.1' d='M227.767 231.363L248.638 231.833V231.363H227.767Z' fill='black' />
        <path opacity='0.1' d='M227.767 232.287H238.091L227.767 232.788V232.287Z' fill='black' />
        <path opacity='0.1' d='M246.648 234.77L231.691 234.651L246.648 234.316V234.77Z' fill='black' />
        <path opacity='0.1' d='M246.648 235.558H231.421L246.648 236.068V235.558Z' fill='black' />
        <path opacity='0.1' d='M248.813 238.089H231.914V238.607L248.813 238.089Z' fill='black' />
        <path opacity='0.1' d='M248.813 239.14H236.571L248.813 239.482V239.14Z' fill='black' />
        <path d='M209.65 238.36V240.915C209.65 241.568 208.854 241.839 208.225 241.839H157.186C156.533 241.839 155.745 241.568 155.745 240.915V238.36H209.65Z' fill='#263238' />
        <path opacity='0.4' d='M209.65 238.36V240.915C209.65 241.568 208.854 241.839 208.225 241.839H157.186C156.533 241.839 155.745 241.568 155.745 240.915V238.36H209.65Z' fill='white' />
        <path opacity='0.2' d='M209.65 238.36V240.915C209.65 241.568 208.854 241.839 208.225 241.839H174.562C173.909 241.839 173.129 241.568 173.129 240.915V238.36H209.65Z' fill='black' />
        <path d='M218.191 211.837L212.11 239.403C212.005 239.85 211.754 240.25 211.396 240.538C211.039 240.827 210.595 240.987 210.136 240.995H176.95C176.744 240.99 176.541 240.938 176.358 240.842C176.175 240.746 176.017 240.609 175.896 240.442C175.775 240.275 175.694 240.081 175.661 239.878C175.627 239.674 175.64 239.465 175.701 239.268L181.766 211.917C181.89 211.5 182.154 211.137 182.514 210.891C182.873 210.645 183.306 210.529 183.74 210.564H216.933C217.825 210.532 218.382 210.938 218.191 211.837Z' fill='#263238' />
        <path opacity='0.4' d='M218.191 211.837L212.11 239.403C212.005 239.85 211.754 240.25 211.396 240.538C211.039 240.827 210.595 240.987 210.135 240.995H178.073C177.866 240.99 177.663 240.938 177.481 240.842C177.298 240.746 177.14 240.609 177.018 240.442C176.897 240.274 176.817 240.081 176.783 239.877C176.749 239.674 176.763 239.465 176.823 239.267L182.896 211.917C183.019 211.499 183.283 211.136 183.643 210.889C184.002 210.642 184.436 210.528 184.87 210.564H216.933C217.825 210.532 218.382 210.938 218.191 211.837Z' fill='white' />
        <path opacity='0.2' d='M201.825 225.847C201.825 228.235 199.294 230.121 197.439 230.121C195.585 230.121 194.486 228.203 195.051 225.847C195.616 223.491 197.439 221.652 199.238 221.58C198.713 223.968 199.843 225.847 201.69 225.847H201.825Z' fill='black' />
      </g>
    </SvgIcon>
  )
}
