import * as yup from 'yup'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { ApolloError } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { useBank } from '~/hooks'
import { Input } from '~/components/Form/Input'
import { Radio } from '~/components/Form/Radio'
import { Select } from '~/components/Form/Select'
import { useAuth, useContract } from '~/contexts'
import { PropsWithWizard } from '~/components/Wizard'
import { clearString, displayCpfCnpj } from '~/utils'
import { accountOwnerOptions, bankAccountTypeOptions, defaultOption } from '~/utils/options'
import {
  ECustomerPersonType,
  EBankAccountType,
  ESingleAccountType,
  ContractChangeSingleAccountDto,
  CustomerCreateDigitalAccountGraphqlDto,
  useUserContractChangeSingleAccountMutation,
  useCustomerAreaCreateDigitalAccountMutation
} from '~/graphql/types'
import { UnifiedEnergyBillSuccess } from '../UnifiedEnergyBillSuccess'
import { bankAccountSchema, bankAgencySchema } from '~/utils/yupSchema'

const schema = yup.object().shape({
  accountOwner: yup.string().optional(),
  code: yup.string().required(),
  agency: bankAgencySchema,
  account: bankAccountSchema,
  legalRepresentativeDocument: yup.string().optional(),
  accountType: yup.string().required()
})

export type BankFormData = {
  code: string
  agency: string
  account: string
  accountOwner: string
  accountType: EBankAccountType
  legalRepresentativeDocument?: string
}

export type CreateBankAccountProps = {
  toggleModal: () => void
  setIsVisible: (visible: boolean) => void
}

export const CreateBankAccount: React.FC<PropsWithWizard<CreateBankAccountProps>> = ({ toggleModal, setIsVisible }) => {
  const { authUser } = useAuth()
  const [requestSuccess, setRequestSuccess] = useState(false)
  const { selectedContract, setSelectedContract } = useContract()
  const [createDigitalAccount, { loading }] = useCustomerAreaCreateDigitalAccountMutation()
  const [unifyEnergyBillAdhesion, { loading: loadingUnifyEnergyBill }] = useUserContractChangeSingleAccountMutation()

  const methods = useForm<BankFormData>({
    resolver: yupResolver(schema)
  })

  const activeBankCode = methods.watch('code')
  const pjAccountOwner = methods.watch('accountOwner')

  const isPj = authUser?.personType === ECustomerPersonType.pj

  const hasLegalRepresentives = Boolean(authUser?.legalRepresentatives)
  const legalRepresentativesOptions = authUser?.legalRepresentatives ? authUser?.legalRepresentatives.map(legalRepresentative => {
    return { value: legalRepresentative.document || '', label: legalRepresentative.name || '' }
  }) : []

  const { accountLabel, agencyLabel, bankOptions, agencyMask, accountMask } = useBank(activeBankCode)

  const onSubmit = async (formData: BankFormData) => {
    const params: CustomerCreateDigitalAccountGraphqlDto = {
      accountOwner: isPj ? ECustomerPersonType.pj : ECustomerPersonType.pf as any,
      bank: {
        type: formData.accountType,
        code: formData.code,
        agency: formData.agency,
        account: formData.account
      }
    }

    const paramsWithLegalRepresentative: CustomerCreateDigitalAccountGraphqlDto = formData.legalRepresentativeDocument ? {
      ...params,
      legalRepresentativeDocument: clearString(formData.legalRepresentativeDocument)
    } : params

    try {
      const result = await createDigitalAccount({ variables: { params: paramsWithLegalRepresentative } })

      if (result.data?.customerAreaCreateDigitalAccount && selectedContract) {
        const unifyEnergyBillParams: ContractChangeSingleAccountDto = {
          contractId: selectedContract._id,
          type: ESingleAccountType.adhesion
        }

        const { data } = await unifyEnergyBillAdhesion({ variables: { params: unifyEnergyBillParams } })

        if (data?.userContractChangeSingleAccount.singleAccount) {
          setSelectedContract({
            ...selectedContract,
            singleAccount: data.userContractChangeSingleAccount.singleAccount 
          })
        }

        setRequestSuccess(true)
      }

      setRequestSuccess(true)
    } catch (err: any) {
      const apolloError = err as ApolloError
      const errMessage = apolloError.graphQLErrors[0]
      toast.error(errMessage?.message || 'Houve um problema ao criar a conta digital, verifique se os dados estão corretos')
      setRequestSuccess(false)
    }
  }

  if (requestSuccess) {
    return <UnifiedEnergyBillSuccess setIsVisible={() => setIsVisible(false)} />
  }

  if (loading || loadingUnifyEnergyBill) {
    return (
      <Box sx={{
        display: 'flex',
        minHeight: 300,
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <CircularProgress size={64} color='inherit' />
      </Box>
    )
  }

  return (
    <Box sx={{
      gap: 4,
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Divider sx={{ marginTop: '16px' }} />

      <Typography>Para finalizar, precisamos dos seus <b>dados bancários</b>. Isso vai nos permitir criar uma <b>conta digital</b> para você aqui na Desperta.</Typography>
      <Typography><b>Fique tranquilo</b>, seus dados estão seguros conosco, e não serão compartilhados com terceiros.</Typography>

      <FormProvider {...methods}>
        <form style={{ width: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {isPj && (
              <>
                <Grid item xs={12}>
                  <Typography fontWeight='500'>Titularidade da conta</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Radio disabled={!hasLegalRepresentives} row name='accountOwner' options={accountOwnerOptions} />
                </Grid>
                {pjAccountOwner === 'legalRepresentative' ? (
                  <Grid item xs={12}>
                    <Select label='Sócio titular' name='legalRepresentativeDocument' options={legalRepresentativesOptions} />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography color='grey.400'>{authUser?.name}</Typography>
                    <Typography color='grey.400'>CNPJ: {displayCpfCnpj(authUser?.document || '')}</Typography>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <Radio row name='accountType' options={bankAccountTypeOptions} />
            </Grid>

            <Grid item xs={12}>
              <Select label='Banco' name='code' options={[defaultOption, ...bankOptions]} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input name='agency' label={agencyLabel} customMask={agencyMask} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input name='account' label={accountLabel} customMask={accountMask} />
            </Grid>
          </Grid>

          <Divider sx={{ margin: '32px 0px' }} />

          <Box sx={{
            gap: 3,
            flex: 1,
            display: 'flex',
            justifyContent: 'end'
          }}>
            <Button
              type='button'
              color='secondary'
              onClick={toggleModal}
              disabled={loading || loadingUnifyEnergyBill}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              disabled={loading || loadingUnifyEnergyBill}
              endIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
            >
              Confirmar
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}
