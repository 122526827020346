import { InputHTMLAttributes } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CheckboxProps, FormControlLabel, Checkbox as MuiCheckbox, RadioProps, Typography } from '@mui/material'

export type InnerCheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> & Omit<CheckboxProps, 'defaultValue'> & {
  name: string
  label?: string,
  defaultValue?: boolean
  checkboxProps?: RadioProps,
  shouldUnregister?: boolean
}

export const Checkbox: React.FC<InnerCheckboxProps> = ({ name, label, checkboxProps, shouldUnregister = false, defaultValue = false }) => {
  const { control } = useFormContext()

  return (
    
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          checked={field.value}
          sx={{ margin: 0 }}
          control={<MuiCheckbox {...checkboxProps} />}
          label={<Typography variant='body2' color='grey.400'>{label}</Typography>}
        />
      )}
    />

  )
}
