import styled from '@emotion/styled'

export const ContainerWithScroll = styled.div`
  max-height: 400px;
  overflow: auto;
  padding-bottom: .1rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.info.main};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.info.dark};
    border-radius: 4px;
  }
`

export const Ol = styled.ol`
  padding: 1rem 1rem 1rem 2rem;
  margin: 0;
  border: 1px solid ${(props) => props.theme.palette.grey['300']};
  border-radius: .6rem;

  li {
    padding: .4rem 0;
    color: ${(props) => props.theme.palette.grey['600']};
  }
`

export const Ul = styled.ul`
  padding: 0 1rem 2rem 2rem;
  margin: 0;
  border-radius: .6rem;

  li {
    padding: .4rem 0;
    color: ${(props) => props.theme.palette.grey['600']};

    span {
      font-weight: 500;
      color: ${(props) => props.theme.palette.grey['800']};
    }
  }
`
