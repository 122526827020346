import { useMemo } from 'react'
import { Dialog } from '../Dialog'
import { Wizard } from '../Wizard'
import {
  CreateBankAccount,
  UnifyEnergyBillFeedback,
  UnifyEnergyBillInstructions,
  UnifyEnergyBillAutomaticDebit
} from './components'
import { UseWizardResult, useWizard } from '~/hooks'

export type UnifyEnergyBillFlowProps = {
  isVisible: boolean
  toggleModal: () => void,
  setIsVisible: (visible: boolean) => void
}

export const UnifyEnergyBillFlow: React.FC<UnifyEnergyBillFlowProps> = ({ isVisible, toggleModal, setIsVisible }) => {
  const screens = useMemo(() => [
    (props: UseWizardResult) => <UnifyEnergyBillInstructions setIsVisible={setIsVisible} {...props} />,
    UnifyEnergyBillAutomaticDebit,
    (props: UseWizardResult) => <UnifyEnergyBillFeedback setIsVisible={setIsVisible} isVisible={isVisible} {...props} />,
    (props: UseWizardResult) => <CreateBankAccount toggleModal={toggleModal} setIsVisible={setIsVisible} {...props} />,
  ], [])

  const wizardHelpers = useWizard(screens.length)
  
 
  return (
    <Dialog
      onClose={toggleModal}
      isVisible={isVisible}
      style={{ padding: '8px' }}
      title={wizardHelpers.currentPage === 3 ? 'Criar conta digital Desperta' : 'Unificar faturas para conta única'}

    >
      <Wizard screens={screens} wizardHelpers={wizardHelpers} />
    </Dialog>
  )
}
