import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { IconCurrencyDollar, IconHandClick, IconPigMoney, IconReceipt2 } from '@tabler/icons-react'
import { HomeBanner } from '~/components'
import { useTheme } from '@emotion/react'
import { useToggle } from '~/hooks'
import { ChangeToCashback } from '~/components/ChangeToCashback'

export const CashbackJoinBanner: React.FC = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { isTrue: cashbackModalIsVisible, toggle: cashbackToggle } = useToggle(false)

  const items = [
    {
      title: 'Parte da fatura de volta pra você',
      icon: <IconReceipt2 size={28} color={theme.palette.grey[700]} />,
    },
    {
      title: 'Dinheiro para transferências e pagamentos',
      icon: <IconPigMoney size={28} color={theme.palette.grey[700]} />,
    },
    {
      title: '100% digital',
      icon: <IconHandClick size={28} color={theme.palette.grey[700]} />,
    },
  ]

  return (
    <>
    
      <HomeBanner
        backgroundImage='/images/bg-cashback-join.png'
        backgroundColor='#fff2ac'
        title={(
          <>
            <Typography sx={{ fontSize: isLowerSm ? '1.4rem' : '2.2rem', fontWeight: 400, color: theme.palette.grey[800] }}>
              <b style={{ color: theme.palette.purple.main }}>Cliente Desperta,</b> agora pode <br/ > aderir ao Cashback!
            </Typography>
          </>
        )}
        description={
          <>
            {items.map((item) => {
              return (
                <Box sx={{ alignItems: 'center', gap: '.4rem', display: 'flex' }} key={item.title}>
                  {item.icon}
                  <span>{item.title}</span>
                </Box>
              )
            })}
            <Box>
              <Button onClick={() => cashbackToggle()} sx={{ borderRadius: '2rem' }} color='purple'>ADERIR AO CASHBACK</Button>
            </Box>
          </>
        }
        category={{
          text: 'Cashback',
          icon: <IconCurrencyDollar />,
          color: theme.palette.grey[800]
        }}
      />
      <ChangeToCashback isVisible={cashbackModalIsVisible} toggle={cashbackToggle} />
    </>
  )
}
