import { toast } from 'react-toastify'
import { IconExclamationCircle } from '@tabler/icons-react'
import { Box, Button, CircularProgress, Divider, Typography, useMediaQuery } from '@mui/material'
import { Dialog } from '~/components'
import { useContract } from '~/contexts'
import { ESingleAccountType, useUserContractChangeSingleAccountMutation } from '~/graphql/types'
import { theme } from '~/utils'

export type CancelUnifiedEnergyBillProps = {
  isVisible: boolean
  toggleModal: () => void
  setIsVisible: (visible: boolean) => void
}

export const CancelUnifiedEnergyBill: React.FC<CancelUnifiedEnergyBillProps> = ({ isVisible, toggleModal, setIsVisible }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { selectedContract, setSelectedContract, contracts, setContracts } = useContract()
  const [unifyEnergyBillCancellation, { loading }] = useUserContractChangeSingleAccountMutation()

  const onSubmit = async () => {
    if (selectedContract) {
      try {
        const result = await unifyEnergyBillCancellation({
          variables: {
            params: {
              contractId: selectedContract._id,
              type: ESingleAccountType.cancel
            }
          }
        })

        if (result.data?.userContractChangeSingleAccount?.singleAccount) {
          setSelectedContract({
            ...selectedContract,
            singleAccount: result.data.userContractChangeSingleAccount.singleAccount
          })

          toast.success('Adesão de conta única cancelada!')
          setContracts(contracts.map(contract => contract._id === selectedContract._id ? {...contract, singleAccount: result.data?.userContractChangeSingleAccount.singleAccount} : {...contract}))
          // return toggleModal()
          return setIsVisible(false)
        }

        toast.error('Houve um problema ao cancelar a conta única, tente novamente mais tarde')
      } catch (err) {
        toast.error('Houve um problema ao cancelar a conta única, tente novamente mais tarde')
      } 
    }
  }

  return (
    <Dialog
      onClose={toggleModal}
      isVisible={isVisible}
      style={{ padding: '8px' }}
      title='Desativar conta única'
      titleIcon={<IconExclamationCircle fill='#EF4444' color='#fff' size={30} />}
    >
      <Box sx={{
        gap: 4,
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Divider sx={{ marginTop: '16px' }} />

        <Typography style={{color: '#434343'}}>Ao desativar a conta única, você voltará a pagar duas faturas de energia: uma da Desperta e outra da sua concessionária de energia.</Typography>
        <Typography style={{color: '#434343'}}>Deseja desativar sua a conta única?</Typography>

        <Divider />

        <Box sx={{
          gap: isLowerSm ? 2 : 3,
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isLowerSm ? 'column' : 'row'
        }}>
          <Button disabled={loading} color='secondary' onClick={() => setIsVisible(false)}>
            Manter conta única
          </Button>
          <Button
            color='error'
            disabled={loading}
            variant='contained'
            onClick={onSubmit}
            endIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
          >
            Sim, quero desativar
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
