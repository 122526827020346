import { Typography, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import { IconCurrencyDollar, IconHandFinger } from '@tabler/icons-react'
import { HomeBanner } from '~/components'
import { theme } from '~/utils'
import { useContract } from '~/contexts'
import { ERewardType  } from '~/graphql/types'

export const CashbackOrDiscountBanner: React.FC = () => {
  const { selectedContract } = useContract()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isCashback = selectedContract?.reward?.type === ERewardType.cashback

  return (
    <HomeBanner
      backgroundImage='/images/bg-cashback-or-discount-home.png'
      iconTextList={[{ icon: <IconHandFinger />, text: <Typography sx={{ fontWeight: 400, fontSize: isLowerSm ? '.9rem' : '1rem' }}>Processo 100% digital, econômico e sustentável</Typography> }]}
      title={(
        <>
          <Typography sx={{ fontSize: isLowerSm ? '1.4rem' : '2.2rem', fontWeight: 500, color: theme.palette.yellow.darker }}>
            Desperta energia
          </Typography>

          <Typography sx={{ maxWidth: isCashback ? '460px' : '620px', fontSize: isLowerSm ? '1.4rem' : '2.2rem', color: theme.palette.grey['800'] }}>
            {isCashback ? 'Ganhe dinheiro de volta ao pagar sua conta de luz' : 'Pague menos na sua conta de luz todos os meses com a energia solar por assinatura'}
          </Typography>
        </>
      )}
      description={isCashback ? (
        <>
          <Typography sx={{ fontSize: '1rem', color: theme.palette.grey['800'], padding: '1rem 0 .4rem 0' }}>
            Quer relembrar os termos do cashback Desperta?
          </Typography>

          <Link target='_blank' style={{ textDecoration: 'none' }} to='https://despertaenergia.com/politica-de-cashback'>
            <Typography sx={{ fontSize: '1rem', color: theme.palette.info.dark }}>Acesse nossas regras de adesão</Typography>
          </Link>
        </>
      ) : <></>}
      category={{
        text: 'Economia',
        icon: <IconCurrencyDollar />
      }}
    />
  )
}
