import { SvgIcon, SvgIconProps } from '@mui/material'

export const ManAndWomanClappingHands: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='260' height='180' viewBox='0 0 260 180' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M260 155.579H0V155.706H260V155.579Z' fill='#EBEBEB' />
      <path d='M44.5017 160.443H27.2793V160.571H44.5017V160.443Z' fill='#EBEBEB' />
      <path d='M117 160.509H88.9929V160.637H117V160.509Z' fill='#EBEBEB' />
      <path d='M64.9793 163.08H55.0005V163.207H64.9793V163.08Z' fill='#EBEBEB' />
      <path d='M231.92 163.019H221.92V163.146H231.92V163.019Z' fill='#EBEBEB' />
      <path d='M217.287 163.019H201.536V163.146H217.287V163.019Z' fill='#EBEBEB' />
      <path d='M192.863 162.175H170.731V162.303H192.863V162.175Z' fill='#EBEBEB' />
      <path d='M216.237 117.425C218.951 116.52 220.917 113.945 219.201 111.293C216.341 106.929 223.491 105.999 221.483 98.6977C218.649 88.3864 209.872 97.3232 207.688 87.4258C206.128 80.2262 193.793 75.51 193.315 84.9884C192.795 95.3713 187.491 88.5908 186.316 100.128C185.499 108.11 197.059 109.27 193.596 111.763C191.074 113.587 189.587 116.76 193.102 118.917C197.891 121.875 193.372 124.185 196.627 126.249C199.883 128.313 200.413 125.023 205.467 126.627C209.986 128.058 213.819 123.669 212.919 120.925C212.092 118.396 213.507 118.334 216.237 117.425Z' fill='#E6E6E6' />
      <path d='M190.112 122.161C190.112 122.161 190.481 155.568 204.729 155.568C218.977 155.568 219.352 122.161 219.352 122.161H190.112Z' fill='#F0F0F0' />
      <path d='M98.9716 5.95703H35.8904V72.2704H98.9716V5.95703Z' fill='#E6E6E6' />
      <path d='M102.118 5.95703H37.3621V72.2704H102.118V5.95703Z' fill='#F0F0F0' />
      <path d='M98.9716 72.2705H35.8904V78.0138H98.9716V72.2705Z' fill='#E6E6E6' />
      <path d='M107.354 72.2705H42.5984V78.0138H107.354V72.2705Z' fill='#F0F0F0' />
      <path d='M97.7131 67.9425V10.2798L41.7611 10.2798L41.7611 67.9425H97.7131Z' fill='#FAFAFA' />
      <path d='M49.8784 67.9425L75.7172 10.2798H87.9112L62.0776 67.9425H49.8784Z' fill='white' />
      <path d='M43.7996 63.635C43.7168 63.635 43.6374 63.6027 43.5789 63.5452C43.5204 63.4877 43.4875 63.4097 43.4875 63.3284V13.0951C43.4875 13.0134 43.5202 12.9348 43.5786 12.8765C43.637 12.8182 43.7163 12.7848 43.7996 12.7834C43.8833 12.7848 43.9632 12.818 44.0224 12.8762C44.0816 12.9344 44.1154 13.0129 44.1167 13.0951V63.3284C44.1154 63.4102 44.0814 63.4881 44.022 63.5455C43.9627 63.6028 43.8828 63.635 43.7996 63.635Z' fill='#F0F0F0' />
      <path d='M64.6985 67.9425L90.5321 10.2798H95.2901L69.4513 67.9425H64.6985Z' fill='white' />
      <path d='M97.7131 67.9425V10.2798H97.1567V67.9425H97.7131Z' fill='#E6E6E6' />
      <path opacity='0.6' d='M103.158 16.8049H40.0765L39.6709 11.9609H102.752L103.158 16.8049Z' fill='#EBEBEB' />
      <path opacity='0.6' d='M103.158 24.7402H40.0765L39.6709 19.9014H102.752L103.158 24.7402Z' fill='#EBEBEB' />
      <path opacity='0.6' d='M103.158 32.6807H40.0765L39.6709 27.8418H102.752L103.158 32.6807Z' fill='#EBEBEB' />
      <path opacity='0.6' d='M103.158 40.6211H40.0765L39.6709 35.7771H102.752L103.158 40.6211Z' fill='#EBEBEB' />
      <path opacity='0.6' d='M103.158 48.5615H40.0765L39.6709 43.7175H102.752L103.158 48.5615Z' fill='#EBEBEB' />
      <path opacity='0.6' d='M103.158 56.4968H40.0765L39.6709 51.658H102.752L103.158 56.4968Z' fill='#EBEBEB' />
      <path d='M198.224 96.2095H174.933V155.579H198.224V96.2095Z' fill='#E6E6E6' />
      <path d='M192.005 155.579H198.224V145.339H185.484L192.005 155.579Z' fill='#F5F5F5' />
      <path d='M138.356 96.2095H115.066V155.579H138.356V96.2095Z' fill='#E6E6E6' />
      <path d='M198.224 96.2095H132.922V152.053H198.224V96.2095Z' fill='#F5F5F5' />
      <path d='M193.398 101.396H137.748V113.097H193.398V101.396Z' fill='#F0F0F0' />
      <path d='M139.142 155.579H132.928V145.339H145.663L139.142 155.579Z' fill='#F5F5F5' />
      <path d='M193.398 118.278H137.748V129.979H193.398V118.278Z' fill='#F0F0F0' />
      <path d='M193.398 135.166H137.748V146.867H193.398V135.166Z' fill='#F0F0F0' />
      <path d='M130.25 96.2095H106.959V155.579H130.25V96.2095Z' fill='#E6E6E6' />
      <path d='M124.03 155.579H130.25V145.339H117.51L124.03 155.579Z' fill='#F5F5F5' />
      <path d='M70.3767 96.2095H47.0859V155.579H70.3767V96.2095Z' fill='#E6E6E6' />
      <path d='M130.25 96.2095H64.948V152.053H130.25V96.2095Z' fill='#F5F5F5' />
      <path d='M125.424 101.396H69.7737V113.097H125.424V101.396Z' fill='#F0F0F0' />
      <path d='M71.1672 155.579H64.948V145.339H77.688L71.1672 155.579Z' fill='#F5F5F5' />
      <path d='M125.424 118.278H69.7737V129.979H125.424V118.278Z' fill='#F0F0F0' />
      <path d='M125.424 135.166H69.7737V146.867H125.424V135.166Z' fill='#F0F0F0' />
      <path d='M156.437 86.1228C156.29 86.1286 156.145 86.1423 156 86.1637V85.3308C156 85.0069 155.869 84.6963 155.636 84.4673C155.403 84.2383 155.087 84.1096 154.757 84.1096H148.106C147.777 84.1096 147.461 84.2383 147.228 84.4673C146.994 84.6963 146.864 85.0069 146.864 85.3308V96.2093H156V94.0224C156.144 94.0481 156.29 94.0634 156.437 94.0684C157.402 94.0684 158.328 93.6915 159.011 93.0208C159.693 92.35 160.077 91.4402 160.077 90.4916V89.7149C160.079 89.2439 159.986 88.7772 159.804 88.3415C159.622 87.9057 159.354 87.5096 159.016 87.1759C158.678 86.8421 158.276 86.5773 157.833 86.3966C157.391 86.2158 156.916 86.1228 156.437 86.1228ZM158.517 90.4712C158.517 90.7396 158.463 91.0054 158.358 91.2533C158.254 91.5013 158.101 91.7266 157.908 91.9164C157.714 92.1062 157.485 92.2567 157.233 92.3595C156.98 92.4622 156.71 92.515 156.437 92.515C156.29 92.5155 156.143 92.5 156 92.4691V87.6966C156.143 87.6656 156.29 87.6502 156.437 87.6506C156.988 87.6506 157.517 87.866 157.908 88.2493C158.298 88.6326 158.517 89.1524 158.517 89.6945V90.4712Z' fill='#E6E6E6' />
      <path d='M219.617 63.676V15.083L153.478 15.083V63.676L219.617 63.676Z' fill='#E6E6E6' />
      <path d='M220.376 66.1594V15.0881L154.237 15.0881V66.1594H220.376Z' fill='#F0F0F0' />
      <path d='M161.814 61.8774L212.789 61.8774C214.573 61.8774 216.018 60.4568 216.018 58.7043V22.538C216.018 20.7856 214.573 19.3649 212.789 19.3649L161.814 19.3649C160.03 19.3649 158.584 20.7856 158.584 22.538V58.7043C158.584 60.4568 160.03 61.8774 161.814 61.8774Z' fill='#E6E6E6' />
      <path d='M161.814 61.1365L212.789 61.1365C214.573 61.1365 216.018 59.7158 216.018 57.9634V22.538C216.018 20.7855 214.573 19.3649 212.789 19.3649L161.814 19.3649C160.03 19.3649 158.584 20.7855 158.584 22.538V57.9634C158.584 59.7158 160.03 61.1365 161.814 61.1365Z' fill='white' />
      <path d='M196.674 47.8053L206.96 40.5189C207.009 40.4917 207.049 40.4523 207.077 40.4047C207.105 40.3571 207.12 40.3031 207.12 40.2481C207.12 40.1931 207.105 40.139 207.077 40.0915C207.049 40.0439 207.009 40.0045 206.96 39.9773L196.695 32.696C191.526 29.0323 183.123 29.0323 177.975 32.696L167.705 39.9773C167.657 40.0052 167.616 40.0451 167.588 40.0931C167.56 40.141 167.546 40.1954 167.546 40.2506C167.546 40.3059 167.56 40.3602 167.588 40.4082C167.616 40.4561 167.657 40.4961 167.705 40.524L177.975 47.8053C183.102 51.4689 191.506 51.4689 196.674 47.8053ZM195.91 33.2529H178.703C183.446 29.8703 191.162 29.8703 195.91 33.2529ZM195.91 47.279C191.162 50.6412 183.43 50.6412 178.703 47.279H195.91Z' fill='#F0F0F0' />
      <path d='M130 178.654C185.683 178.654 230.823 176.064 230.823 172.87C230.823 169.675 185.683 167.085 130 167.085C74.3172 167.085 29.1772 169.675 29.1772 172.87C29.1772 176.064 74.3172 178.654 130 178.654Z' fill='#F5F5F5' />
      <path d='M93.4285 26.7226C91.4473 26.0124 88.1713 27.5351 88.8161 30.5702C89.4609 33.6054 81.9365 29.8089 80.3245 35.9814C81.4836 36.2309 82.5755 36.7199 83.5276 37.4159C84.4798 38.1118 85.2703 38.9988 85.8469 40.018C88.5821 44.816 110.126 45.3065 107.063 39.3742C104 33.4419 110.495 31.9039 108.524 27.7037C106.553 23.5035 102.861 22.359 100.204 23.3809C97.5469 24.4029 91.8893 22.824 93.4285 26.7226Z' fill='#263238' />
      <path d='M93.4285 26.7891C93.4135 26.795 93.3967 26.795 93.3817 26.7891C92.7785 26.0226 92.5705 25.4197 92.7577 24.9445C93.0853 24.086 94.5881 23.7999 96.3301 23.4678C98.0721 23.1356 99.9285 22.778 100.859 21.7969C100.865 21.7905 100.871 21.7853 100.879 21.7818C100.887 21.7782 100.895 21.7764 100.903 21.7764C100.912 21.7764 100.92 21.7782 100.928 21.7818C100.936 21.7853 100.942 21.7905 100.948 21.7969C100.954 21.8022 100.959 21.8088 100.963 21.8163C100.967 21.8238 100.969 21.832 100.969 21.8403C100.969 21.8486 100.967 21.8568 100.963 21.8643C100.959 21.8719 100.954 21.8785 100.948 21.8838C99.9857 22.9057 98.0565 23.2634 96.3509 23.5904C94.6453 23.9174 93.1737 24.1984 92.8721 24.9904C92.7057 25.4197 92.9033 25.9817 93.4753 26.7124C93.4818 26.7177 93.4871 26.7243 93.4907 26.7318C93.4943 26.7393 93.4962 26.7475 93.4962 26.7558C93.4962 26.7641 93.4943 26.7724 93.4907 26.7799C93.4871 26.7874 93.4818 26.794 93.4753 26.7993C93.4672 26.801 93.4588 26.801 93.4507 26.7992C93.4426 26.7975 93.435 26.794 93.4285 26.7891Z' fill='#263238' />
      <path d='M107.203 46.5891C108.399 46.2212 109.678 45.8125 110.906 45.3781C112.133 44.9438 113.376 44.5044 114.592 44.0241C117.008 43.0885 119.36 42.0013 121.633 40.7692C121.909 40.6261 122.247 40.4371 122.325 40.3809C122.403 40.3247 122.476 40.2634 122.554 40.2071L122.798 39.9772C123.168 39.5854 123.494 39.1554 123.77 38.6947C124.405 37.6417 124.951 36.5395 125.403 35.3989C125.892 34.2237 126.329 33.0076 126.724 31.7711C127.119 30.5345 127.504 29.2724 127.847 28.0359L129.979 28.3731C129.756 31.1025 129.274 33.8054 128.539 36.4464C128.147 37.8268 127.625 39.168 126.979 40.4524C126.622 41.164 126.188 41.8356 125.684 42.4554C125.533 42.624 125.388 42.7875 125.232 42.9664C125.076 43.1452 124.894 43.2883 124.712 43.4416C124.386 43.6861 124.048 43.9129 123.698 44.1212C121.328 45.5987 118.838 46.8789 116.251 47.9483C113.696 49.0376 111.071 49.9627 108.394 50.7177L107.203 46.5891Z' fill='#FFB573' />
      <path d='M104.317 46.6912C104.988 45.0408 114.587 43.8145 114.587 43.8145L115.96 52.1586C115.96 52.1586 107.864 55.2499 106.127 53.6915C104.39 52.133 103.335 49.0928 104.317 46.6912Z' fill='#263238' />
      <path d='M127.561 27.5096L126.818 24.127L130.728 25.9562C130.728 25.9562 130.889 28.6388 129.381 29.533L129.007 29.3439C128.644 29.1611 128.326 28.9019 128.076 28.5849C127.826 28.2679 127.651 27.9008 127.561 27.5096Z' fill='#FFB573' />
      <path d='M127.717 21.1428L131.191 22.1699L130.728 25.9561L126.818 24.1269L127.717 21.1428Z' fill='#FFB573' />
      <path d='M84.24 141.603L87.8852 143.816L93.5688 136.836L90.4488 134.051L84.24 141.603Z' fill='#FFB573' />
      <path d='M88.7589 143.622L85.5401 140.142C85.485 140.081 85.4094 140.042 85.3272 140.032C85.245 140.021 85.1618 140.04 85.0929 140.086L81.9313 142.196C81.8531 142.251 81.7877 142.322 81.7394 142.404C81.6911 142.486 81.6611 142.577 81.6513 142.671C81.6414 142.765 81.652 142.861 81.6824 142.95C81.7127 143.04 81.7621 143.123 81.8273 143.192C82.9713 144.383 83.5849 144.904 84.9993 146.432C85.8729 147.372 88.2701 150.162 89.4765 151.46C90.6829 152.758 92.0765 151.664 91.6657 151.015C89.9497 148.118 89.1021 145.752 89.0397 144.311C89.0318 144.056 88.9323 143.812 88.7589 143.622Z' fill='#263238' />
      <g opacity='0.2'>
        <path d='M93.5688 136.841L90.7972 140.239L87.2456 137.945L90.4488 134.051L93.5688 136.841Z' fill='black' />
      </g>
      <path d='M111.202 46.9314C111.202 46.9314 113.22 47.6621 108.758 72.1426L91.4679 71.8718C91.8683 64.9584 91.9879 60.6918 88.8679 46.4511C91.2704 45.9979 93.7037 45.7196 96.1479 45.6182C98.765 45.4763 101.389 45.519 104 45.746C107.39 46.0986 111.202 46.9314 111.202 46.9314Z' fill='#263238' />
      <path opacity='0.2' d='M90.6567 55.4696C90.8491 56.6142 91.0051 57.6719 91.1351 58.658C91.8584 56.9878 92.3144 55.2176 92.4871 53.4104L90.6567 55.4696Z' fill='black' />
      <path d='M97.0945 36.947C97.5521 39.4354 97.9681 43.9933 96.1741 45.6335C96.1741 45.6335 96.8241 48.1883 101.52 48.2394C106.678 48.3212 104.026 45.7612 104.026 45.7612C101.218 45.0561 101.327 43.002 101.847 41.0808L97.0945 36.947Z' fill='#FFB573' />
      <path d='M96.1999 45.6232C98.8151 45.4771 101.438 45.5181 104.047 45.7458L104.567 45.802C105.175 46.9823 105.336 49.8335 103.792 52.4446C103.792 52.4446 96.9175 48.5561 95.1755 45.6743C95.4823 45.6487 95.8307 45.6334 96.1999 45.6232Z' fill='#FFB573' />
      <path opacity='0.2' d='M99.034 38.6282L101.847 41.0655C101.719 41.512 101.631 41.9684 101.582 42.4298C100.511 42.2612 99.0496 41.0757 98.9508 39.9669C98.8945 39.5194 98.9227 39.0656 99.034 38.6282Z' fill='black' />
      <path d='M94.4424 32.6037C95.3368 36.6608 95.628 38.3981 98.0824 40.1814C101.722 42.8639 106.621 40.8967 106.974 36.6557C107.297 32.8388 105.778 26.8451 101.442 25.8334C100.485 25.6006 99.4843 25.6126 98.5341 25.8683C97.5838 26.1241 96.7162 26.615 96.0141 27.2941C95.312 27.9733 94.7989 28.818 94.5237 29.7475C94.2485 30.6771 94.2206 31.6605 94.4424 32.6037Z' fill='#FFB573' />
      <path d='M94.5464 27.4226C96.2832 27.535 98.3216 28.3986 97.8276 30.7337C97.3336 33.0688 95.4304 31.5257 96.122 34.9236C96.8136 38.3216 90.4332 36.3543 91.3952 33.2374C92.3572 30.1205 91.884 27.254 94.5464 27.4226Z' fill='#263238' />
      <path d='M93.4336 30.0388C95.9712 32.3586 99.5124 25.8948 102.976 28.3219C106.439 30.749 106.382 26.5948 102.456 25.5729C98.5296 24.551 90.3708 27.2438 93.4336 30.0388Z' fill='#263238' />
      <path d='M108.758 72.1426C108.758 72.1426 110.588 96.48 110.1 109.234C109.58 122.519 92.3572 139.478 92.3572 139.478L87.906 135.901C87.7916 135.957 100.386 113.955 99.6893 107.287C98.1293 93.1484 96.096 71.9177 96.096 71.9177L108.758 72.1426Z' fill='#F9DC45' />
      <path opacity='0.3' d='M108.758 72.1426C108.758 72.1426 110.588 96.48 110.1 109.234C109.58 122.519 92.3572 139.478 92.3572 139.478L87.906 135.901C87.7916 135.957 100.386 113.955 99.6893 107.287C98.1293 93.1484 96.096 71.9177 96.096 71.9177L108.758 72.1426Z' fill='white' />
      <path d='M86.7517 136.744L92.4977 140.234L94.0525 138.691L87.7553 134.562L86.7517 136.744Z' fill='#263238' />
      <path opacity='0.2' d='M100.397 80.7012C98.8365 78.0544 97.7081 78.1464 96.8657 79.7968C97.5781 87.0219 98.6701 97.8902 99.5905 106.449C101.639 100.19 105.144 88.8461 100.397 80.7012Z' fill='black' />
      <path d='M57.9541 115.166L59.3217 119.126L69.1601 117.46L67.7925 113.5L57.9541 115.166Z' fill='#FFB573' />
      <path d='M60.84 119.31L60.164 114.66C60.1519 114.576 60.1115 114.498 60.0494 114.439C59.9873 114.38 59.9069 114.343 59.8208 114.333L55.9312 113.848C55.8388 113.834 55.7443 113.842 55.6557 113.872C55.5671 113.901 55.487 113.951 55.4219 114.017C55.3568 114.083 55.3086 114.163 55.2815 114.251C55.2543 114.339 55.2489 114.432 55.2656 114.522C55.5256 116.142 55.7388 116.924 56.0352 118.973C56.2224 120.23 56.6124 123.847 56.862 125.585C57.1116 127.322 58.9108 127.394 58.942 126.668C59.1916 123.413 59.8572 121.114 60.6528 120.025C60.8087 119.82 60.876 119.563 60.84 119.31Z' fill='#263238' />
      <g opacity='0.2'>
        <path d='M69.1601 117.46L64.0901 118.318L62.7173 114.358L67.7873 113.5L69.1601 117.46Z' fill='black' />
      </g>
      <path d='M104 72.0863C104 72.0863 101.634 94.8755 96.1115 106.582C93.7247 111.651 65.7955 118.513 65.7955 118.513L63.3515 113.403C62.9719 113.403 79.2011 104.318 84.5987 102.351C85.1884 102.134 85.7116 101.772 86.1185 101.299C86.5254 100.827 86.8024 100.26 86.9231 99.653C89.6375 86.1992 91.3379 71.9023 91.3379 71.9023L104 72.0863Z' fill='#F9DC45' />
      <path opacity='0.3' d='M104 72.0863C104 72.0863 101.634 94.8755 96.1115 106.582C93.7247 111.651 65.7955 118.513 65.7955 118.513L63.3515 113.403C62.9719 113.403 79.2011 104.318 84.5987 102.351C85.1884 102.134 85.7116 101.772 86.1185 101.299C86.5254 100.827 86.8024 100.26 86.9231 99.653C89.6375 86.1992 91.3379 71.9023 91.3379 71.9023L104 72.0863Z' fill='white' />
      <path d='M63.0396 112.892L65.156 119.193L67.4492 119.254L65.2443 111.83L63.0396 112.892Z' fill='#263238' />
      <path d='M60.3201 120.874C60.3405 121.249 60.4847 121.608 60.7309 121.895C60.7992 121.96 60.8812 122.009 60.9711 122.039C61.061 122.069 61.1566 122.08 61.2509 122.069C61.305 122.066 61.3571 122.049 61.4013 122.018C61.4455 121.987 61.4799 121.945 61.5005 121.895C61.7137 121.384 60.6009 119.744 60.4605 119.56C60.4486 119.543 60.4321 119.529 60.413 119.52C60.3938 119.511 60.3725 119.507 60.3513 119.509C60.3313 119.513 60.3131 119.523 60.2991 119.538C60.2851 119.553 60.2761 119.571 60.2733 119.591C60.2498 120.019 60.2654 120.448 60.3201 120.874ZM61.3185 121.604C61.3325 121.663 61.3325 121.724 61.3185 121.783C61.3095 121.803 61.2949 121.82 61.2765 121.831C61.258 121.843 61.2365 121.849 61.2145 121.849C61.1535 121.859 61.0912 121.854 61.0325 121.835C60.9738 121.817 60.9204 121.785 60.8765 121.742C60.6061 121.497 60.4657 120.812 60.4865 119.892C60.8494 120.417 61.125 120.995 61.3029 121.604H61.3185Z' fill='#F9DC45' />
      <path d='M60.2681 119.606V119.642C60.5749 120.153 61.6409 121.098 62.1141 121.016C62.2285 121.016 62.3585 120.919 62.3481 120.643C62.3388 120.437 62.2473 120.242 62.0933 120.102C61.5733 119.591 60.3981 119.509 60.3513 119.504C60.3343 119.504 60.3175 119.507 60.3022 119.514C60.2868 119.521 60.2734 119.532 60.2629 119.545C60.2621 119.565 60.2638 119.586 60.2681 119.606ZM62.1557 120.567C62.1557 120.567 62.1557 120.608 62.1557 120.628C62.1557 120.807 62.0881 120.817 62.0673 120.822C61.7813 120.873 60.9545 120.219 60.5645 119.719C61.0717 119.757 61.5579 119.934 61.9685 120.23C62.0635 120.315 62.1289 120.428 62.1557 120.551V120.567Z' fill='#F9DC45' />
      <path d='M101.676 32.6908C101.728 33.028 101.946 33.2682 102.17 33.2375C102.393 33.2068 102.528 32.9054 102.476 32.5732C102.424 32.2411 102.201 31.9959 101.982 32.0265C101.764 32.0572 101.624 32.3586 101.676 32.6908Z' fill='#263238' />
      <path d='M105.508 32.1133C105.56 32.4454 105.784 32.6907 106.028 32.6549C106.272 32.6191 106.387 32.3228 106.335 31.9906C106.283 31.6585 106.059 31.4133 105.815 31.449C105.57 31.4848 105.456 31.7812 105.508 32.1133Z' fill='#263238' />
      <path d='M105.721 31.4798L106.501 31.1272C106.501 31.1272 106.179 31.817 105.721 31.4798Z' fill='#263238' />
      <path d='M104 32.8848C104.426 33.6012 104.951 34.2563 105.56 34.8316C105.406 34.9794 105.221 35.0923 105.018 35.162C104.815 35.2318 104.599 35.2568 104.385 35.2352L104 32.8848Z' fill='#ED893E' />
      <path d='M102.726 36.8296C102.9 36.8286 103.074 36.8149 103.246 36.7887C103.259 36.7869 103.272 36.7825 103.283 36.7758C103.294 36.7691 103.304 36.7602 103.312 36.7498C103.32 36.7393 103.325 36.7274 103.328 36.7148C103.331 36.7022 103.331 36.6891 103.329 36.6763C103.327 36.6635 103.323 36.6512 103.316 36.6401C103.309 36.629 103.3 36.6194 103.29 36.6118C103.279 36.6041 103.267 36.5987 103.254 36.5957C103.241 36.5928 103.228 36.5924 103.215 36.5946C102.794 36.6668 102.362 36.6412 101.952 36.5196C101.543 36.398 101.169 36.1839 100.859 35.8945C100.851 35.8848 100.84 35.8768 100.829 35.8711C100.817 35.8653 100.804 35.8618 100.791 35.8609C100.778 35.8599 100.765 35.8615 100.753 35.8655C100.74 35.8696 100.729 35.8759 100.719 35.8843C100.709 35.8927 100.701 35.9029 100.695 35.9144C100.689 35.9259 100.686 35.9384 100.685 35.9512C100.684 35.964 100.685 35.9769 100.689 35.9891C100.693 36.0013 100.7 36.0126 100.708 36.0223C100.973 36.2821 101.288 36.4874 101.634 36.626C101.981 36.7647 102.352 36.8339 102.726 36.8296Z' fill='#263238' />
      <path d='M93.9016 36.5282C94.3007 37.2289 94.9665 37.7453 95.7528 37.964C96.7928 38.245 97.3128 37.3253 97.0164 36.3596C96.7512 35.4909 95.888 34.3157 94.8428 34.4434C94.615 34.4791 94.3999 34.5701 94.217 34.7083C94.0342 34.8464 93.8894 35.0274 93.7958 35.2345C93.7023 35.4417 93.663 35.6686 93.6814 35.8946C93.6999 36.1205 93.7756 36.3383 93.9016 36.5282Z' fill='#FFB573' />
      <path d='M99.6008 32.149C99.6427 32.1485 99.6836 32.1355 99.7179 32.1118C99.7522 32.0881 99.7785 32.0548 99.7932 32.0161C100.079 31.1424 101.228 30.7847 101.239 30.7847C101.29 30.7683 101.332 30.7328 101.356 30.6859C101.381 30.6389 101.385 30.5845 101.369 30.5343C101.362 30.5093 101.35 30.486 101.333 30.4659C101.316 30.4457 101.296 30.4291 101.272 30.4171C101.249 30.4051 101.223 30.398 101.197 30.3962C101.17 30.3944 101.144 30.3979 101.119 30.4066C101.062 30.4066 99.762 30.8256 99.4084 31.8884C99.3995 31.9132 99.3959 31.9395 99.3977 31.9657C99.3995 31.9919 99.4066 32.0175 99.4188 32.0409C99.431 32.0643 99.4478 32.0851 99.4684 32.1019C99.4889 32.1187 99.5127 32.1313 99.5384 32.1388C99.558 32.1472 99.5794 32.1507 99.6008 32.149Z' fill='#263238' />
      <path d='M105.976 30.187C106.02 30.1862 106.062 30.1715 106.096 30.1452C106.131 30.1189 106.156 30.0824 106.167 30.0411C106.179 29.9998 106.177 29.9559 106.161 29.9159C106.145 29.8759 106.117 29.8419 106.08 29.8191C105.809 29.6518 105.5 29.5506 105.181 29.5239C104.862 29.4973 104.541 29.5459 104.244 29.6658C104.2 29.6909 104.167 29.7317 104.151 29.78C104.136 29.8283 104.14 29.8804 104.163 29.9258C104.185 29.9713 104.224 30.0066 104.272 30.0247C104.32 30.0429 104.374 30.0424 104.421 30.0235C104.656 29.9309 104.911 29.8947 105.163 29.9178C105.415 29.9409 105.658 30.0227 105.872 30.1564C105.903 30.1764 105.939 30.1871 105.976 30.187Z' fill='#263238' />
      <path d='M90.7244 52.0972C89.96 52.9046 89.1644 53.7834 88.3844 54.6521C87.6044 55.5207 86.8244 56.3996 86.1172 57.304C84.6651 59.063 83.3542 60.9303 82.1964 62.8889L81.9936 63.2466C81.9671 63.2959 81.9376 63.3437 81.9052 63.3896C81.8491 63.5325 81.8124 63.6819 81.796 63.8342C81.74 64.3142 81.7295 64.7983 81.7648 65.2802C81.8433 66.3948 81.9892 67.5039 82.2016 68.6015C82.394 69.7358 82.6332 70.8804 82.9088 72.0199C83.1844 73.1593 83.4288 74.3192 83.7304 75.4485L81.7076 76.174C80.633 73.9678 79.7223 71.6881 78.9828 69.3526C78.5958 68.1391 78.3003 66.8992 78.0988 65.643C77.9875 64.94 77.9423 64.2285 77.9636 63.5174C77.9856 63.0746 78.0589 62.6358 78.182 62.2093C78.2204 62.077 78.2673 61.9473 78.3224 61.821C78.39 61.6575 78.416 61.6012 78.4524 61.5297L78.6656 61.0954C79.2194 59.9495 79.8482 58.8401 80.548 57.7741C81.2188 56.7062 81.9624 55.7302 82.7112 54.7083C84.2171 52.7285 85.8571 50.8507 87.62 49.0876L90.7244 52.0972Z' fill='#FFB573' />
      <path d='M88.8732 46.4511C87.1 46.8036 83.7512 52.2914 83.7512 52.2914L89.4712 57.6924C89.4712 57.6924 93.6624 54.2382 93.5896 51.9286C93.5116 49.5169 90.896 46.027 88.8732 46.4511Z' fill='#263238' />
      <path d='M89.2841 145.252C89.5024 145.541 89.8171 145.745 90.1733 145.829C90.2635 145.839 90.3547 145.827 90.439 145.794C90.5233 145.761 90.5981 145.708 90.6569 145.64C90.6957 145.601 90.7237 145.553 90.7382 145.5C90.7528 145.447 90.7534 145.392 90.7401 145.339C90.5997 144.767 88.8161 144.107 88.6133 144.036C88.5944 144.028 88.573 144.026 88.5531 144.032C88.5331 144.037 88.5158 144.05 88.5041 144.066C88.4915 144.083 88.4846 144.102 88.4846 144.123C88.4846 144.143 88.4915 144.163 88.5041 144.179C88.7271 144.561 88.9884 144.921 89.2841 145.252ZM90.4437 145.221C90.4905 145.266 90.5245 145.323 90.5425 145.385C90.5481 145.406 90.5476 145.428 90.5412 145.449C90.5348 145.469 90.5226 145.488 90.5061 145.502C90.4692 145.549 90.4207 145.586 90.3651 145.609C90.3096 145.633 90.2489 145.641 90.1889 145.635C89.8457 145.599 89.3465 145.124 88.8265 144.327C89.4129 144.534 89.9596 144.836 90.4437 145.221Z' fill='#F9DC45' />
      <path d='M88.5041 144.194C88.514 144.205 88.5265 144.214 88.5405 144.219C89.0605 144.434 90.4073 144.557 90.7089 144.184C90.7609 144.115 90.7853 144.029 90.7777 143.943C90.77 143.858 90.7308 143.778 90.6673 143.719C90.6057 143.638 90.5277 143.57 90.4382 143.52C90.3488 143.47 90.2497 143.438 90.1473 143.427C89.4609 143.335 88.5457 144.02 88.5093 144.051C88.4945 144.06 88.4826 144.073 88.4752 144.088C88.4678 144.104 88.4652 144.121 88.4677 144.138C88.4764 144.158 88.4887 144.177 88.5041 144.194ZM90.4645 143.79L90.5009 143.836C90.6153 143.984 90.5685 144.041 90.5529 144.056C90.3709 144.286 89.3777 144.265 88.7953 144.097C89.1802 143.807 89.647 143.641 90.1317 143.622C90.2647 143.635 90.3884 143.695 90.4801 143.79H90.4645Z' fill='#F9DC45' />
      <path d='M101.884 32.0623L102.664 31.7097C102.664 31.7097 102.346 32.3944 101.884 32.0623Z' fill='#263238' />
      <path d='M91.2237 70.8703L90.7557 72.4032C90.6933 72.5207 90.8337 72.6434 91.0365 72.6485L108.831 72.9295C108.987 72.9295 109.117 72.858 109.127 72.7609L109.309 71.228C109.309 71.1207 109.184 71.0287 109.013 71.0287L91.5201 70.7477C91.464 70.7392 91.4065 70.7461 91.3542 70.7678C91.3019 70.7894 91.2567 70.8249 91.2237 70.8703Z' fill='#FAFAFA' />
      <path d='M93.5427 72.8322H93.0747C92.9811 72.8322 92.9135 72.7811 92.9187 72.7249L93.1735 70.7526C93.1735 70.6964 93.2619 70.6504 93.3555 70.6504H93.8235C93.9171 70.6504 93.9847 70.7015 93.9795 70.7628L93.7247 72.7351C93.7195 72.7862 93.6363 72.8322 93.5427 72.8322Z' fill='#F9DC45' />
      <g opacity='0.3'>
        <path d='M93.5427 72.8322H93.0747C92.9811 72.8322 92.9135 72.7811 92.9187 72.7249L93.1735 70.7526C93.1735 70.6964 93.2619 70.6504 93.3555 70.6504H93.8235C93.9171 70.6504 93.9847 70.7015 93.9795 70.7628L93.7247 72.7351C93.7195 72.7862 93.6363 72.8322 93.5427 72.8322Z' fill='white' />
      </g>
      <path d='M107.51 73.052H107.037C106.943 73.052 106.876 73.006 106.881 72.9446L107.136 70.9723C107.136 70.9161 107.224 70.8701 107.318 70.8701H107.786C107.879 70.8701 107.952 70.9212 107.942 70.9774L107.692 72.9498C107.682 73.006 107.604 73.052 107.51 73.052Z' fill='#F9DC45' />
      <path opacity='0.3' d='M107.51 73.052H107.037C106.943 73.052 106.876 73.006 106.881 72.9446L107.136 70.9723C107.136 70.9161 107.224 70.8701 107.318 70.8701H107.786C107.879 70.8701 107.952 70.9212 107.942 70.9774L107.692 72.9498C107.682 73.006 107.604 73.052 107.51 73.052Z' fill='white' />
      <path d='M100.526 72.9395H100.058C99.9648 72.9395 99.892 72.8884 99.9024 72.8322L100.152 70.8599C100.165 70.8243 100.19 70.7945 100.224 70.7758C100.257 70.757 100.296 70.7506 100.334 70.7577H100.807C100.901 70.7577 100.968 70.8088 100.963 70.865L100.708 72.8373C100.703 72.8935 100.62 72.9395 100.526 72.9395Z' fill='#F9DC45' />
      <path opacity='0.3' d='M100.526 72.9395H100.058C99.9648 72.9395 99.892 72.8884 99.9024 72.8322L100.152 70.8599C100.165 70.8243 100.19 70.7945 100.224 70.7758C100.257 70.757 100.296 70.7506 100.334 70.7577H100.807C100.901 70.7577 100.968 70.8088 100.963 70.865L100.708 72.8373C100.703 72.8935 100.62 72.9395 100.526 72.9395Z' fill='white' />
      <path d='M83.7824 75.152L86.3824 78.8872L82.0768 78.4529C82.0768 78.4529 81.0368 75.9747 82.1184 74.6206L83.7824 75.152Z' fill='#FFB573' />
      <path d='M86.1328 82.3924L82.55 81.8355L82.0664 78.4426L86.372 78.8821L86.1328 82.3924Z' fill='#FFB573' />
      <path d='M161.621 40.9174L161.003 41.2086L160.431 41.459C160.051 41.6174 159.671 41.7656 159.287 41.9086C158.522 42.1948 157.753 42.4554 156.967 42.6904C155.379 43.1656 153.752 43.5075 152.105 43.7124C150.387 43.9195 148.65 43.9195 146.931 43.7124C146.028 43.6065 145.137 43.4184 144.269 43.1503C144.051 43.0941 143.827 43.0072 143.609 42.9357L143.281 42.8182L142.907 42.6598C142.66 42.5594 142.42 42.4433 142.189 42.3123C141.96 42.1897 141.737 42.067 141.539 41.9342C140.771 41.438 140.058 40.8643 139.412 40.2224C138.812 39.6411 138.246 39.0269 137.717 38.383C136.723 37.1488 135.813 35.8515 134.992 34.4996C133.393 31.8811 132.034 29.128 130.931 26.273L133.604 24.9956C134.389 26.1811 135.2 27.4227 136.053 28.5724C136.906 29.7221 137.753 30.9126 138.653 32.0214C139.515 33.1165 140.441 34.1622 141.425 35.1536C142.27 36.0534 143.244 36.8279 144.316 37.453C145.313 37.903 146.395 38.1431 147.493 38.1581C148.767 38.2157 150.043 38.1352 151.299 37.918C152.627 37.6954 153.937 37.3829 155.22 36.9829C155.87 36.7887 156.52 36.5741 157.165 36.3391C157.482 36.2216 157.799 36.0938 158.111 35.9712L158.574 35.7821L158.98 35.6135L161.621 40.9174Z' fill='#FF8B7B' />
      <path d='M133.827 26.3853L133.567 22.4407L130.036 26.0634C130.036 26.0634 130.952 28.8073 132.829 29.1292L133.125 28.7971C133.417 28.4717 133.632 28.0864 133.753 27.6692C133.875 27.2519 133.9 26.8133 133.827 26.3853Z' fill='#FF8B7B' />
      <path d='M131.108 20.0852L128.05 22.4663L130.031 26.0635L133.567 22.4408L131.108 20.0852Z' fill='#FF8B7B' />
      <path d='M183.445 35.2966C184.402 36.4003 185.203 37.4631 186.045 38.5821C186.888 39.7012 187.637 40.8355 188.38 42.0056C189.916 44.3877 191.207 46.9141 192.233 49.5475L192.592 50.6001L192.681 50.8607L192.722 50.9936L192.779 51.1928C192.857 51.4692 192.913 51.7511 192.946 52.0359C193.046 52.9485 193 53.8708 192.811 54.7696C192.499 56.2304 192.02 57.6516 191.381 59.0055C190.209 61.5216 188.767 63.9077 187.08 66.1233L184.48 64.6619C184.943 63.4816 185.421 62.2553 185.848 61.0494C186.274 59.8435 186.685 58.6376 187.018 57.4726C187.349 56.3631 187.574 55.2255 187.689 54.0747C187.73 53.6717 187.707 53.2649 187.621 52.8688C187.616 52.8232 187.6 52.7794 187.574 52.7411V52.7053L187.533 52.6031L187.444 52.4038L187.101 51.5965C186.022 49.3706 184.767 47.2316 183.347 45.1992C182.639 44.1773 181.891 43.1195 181.137 42.0874C180.383 41.0552 179.577 40.0435 178.828 39.0829L183.445 35.2966Z' fill='#FF8B7B' />
      <path d='M184.08 64.8459L181.896 68.1672L186.93 67.3241C186.93 67.3241 187.855 64.5853 186.545 63.2363L186.103 63.3283C185.672 63.4178 185.267 63.6004 184.917 63.8629C184.567 64.1254 184.281 64.4612 184.08 64.8459Z' fill='#FF8B7B' />
      <path d='M182.452 71.5039L186.352 71.3813L186.94 67.3242L181.906 68.1673L182.452 71.5039Z' fill='#FF8B7B' />
      <path d='M164.408 19.2269C164.382 19.5437 164.2 19.7889 163.987 19.7838C163.774 19.7787 163.628 19.5181 163.649 19.2064C163.67 18.8948 163.862 18.6444 164.07 18.6495C164.278 18.6546 164.429 18.9152 164.408 19.2269Z' fill='#263238' />
      <path d='M164.226 19.7939C163.777 20.7414 163.202 21.6263 162.516 22.4254C162.726 22.5858 162.97 22.6983 163.23 22.7548C163.49 22.8114 163.759 22.8106 164.018 22.7524L164.226 19.7939Z' fill='#FF5652' />
      <path d='M164.133 17.2135C164.168 17.226 164.207 17.226 164.242 17.2135C164.468 17.1534 164.705 17.1471 164.934 17.1951C165.163 17.2431 165.376 17.344 165.558 17.4894C165.597 17.5113 165.642 17.5187 165.686 17.5102C165.73 17.5017 165.77 17.478 165.798 17.4433C165.825 17.4086 165.839 17.3653 165.837 17.3213C165.835 17.2772 165.817 17.2353 165.786 17.2033C165.557 17.0191 165.286 16.8922 164.997 16.8335C164.707 16.7747 164.407 16.7859 164.122 16.8661C164.075 16.8823 164.037 16.916 164.014 16.9598C163.992 17.0037 163.988 17.0543 164.003 17.1011C164.014 17.1283 164.032 17.1526 164.055 17.172C164.077 17.1915 164.104 17.2057 164.133 17.2135Z' fill='#263238' />
      <path d='M173.311 23.3809C173.43 26.5846 172.494 30.5344 174.574 32.2921C174.574 32.2921 173.872 35.2302 168.501 35.593C162.594 35.9915 165.589 32.8951 165.589 32.8951C168.792 31.9191 168.636 29.5687 168.007 27.392L173.311 23.3809Z' fill='#FF8B7B' />
      <path d='M176.025 33.1864C176.42 32.2412 176.795 30.8104 175.822 30.5447C174.751 30.2586 169.364 29.7068 165.422 31.2397C165.665 31.6684 165.813 32.1426 165.856 32.6314C165.899 33.1201 165.836 33.6123 165.672 34.0755L176.025 33.1864Z' fill='#263238' />
      <path d='M199.763 150.597C199.706 150.597 199.65 150.587 199.597 150.566C199.524 150.532 199.464 150.476 199.425 150.406C199.387 150.336 199.372 150.256 199.384 150.178C199.383 150.113 199.397 150.049 199.424 149.99C199.451 149.931 199.49 149.878 199.54 149.835C199.982 149.437 201.266 149.539 201.323 149.544C201.341 149.546 201.358 149.552 201.373 149.563C201.387 149.574 201.398 149.588 201.404 149.605C201.41 149.622 201.411 149.64 201.407 149.657C201.403 149.675 201.393 149.69 201.38 149.703C201.11 149.958 200.268 150.597 199.763 150.597ZM200.912 149.713C200.481 149.676 200.048 149.767 199.67 149.973C199.638 149.998 199.613 150.029 199.596 150.064C199.579 150.1 199.57 150.139 199.571 150.178C199.571 150.357 199.649 150.387 199.67 150.398C199.924 150.5 200.621 150.101 201.084 149.718L200.912 149.713Z' fill='#F9DC45' />
      <path d='M201.292 149.753C200.829 149.753 199.774 149.406 199.628 148.997C199.602 148.93 199.602 148.856 199.627 148.789C199.651 148.721 199.7 148.665 199.763 148.629C199.819 148.584 199.885 148.552 199.955 148.535C200.025 148.519 200.098 148.518 200.169 148.532C200.741 148.634 201.365 149.554 201.391 149.615C201.4 149.629 201.404 149.645 201.404 149.661C201.404 149.678 201.4 149.694 201.391 149.707C201.383 149.721 201.371 149.732 201.358 149.74C201.344 149.748 201.329 149.752 201.313 149.753H201.292ZM200.07 148.731C200.001 148.731 199.933 148.752 199.878 148.793C199.774 148.875 199.794 148.931 199.805 148.956C199.893 149.202 200.637 149.518 201.126 149.58C200.928 149.314 200.502 148.798 200.138 148.737L200.07 148.731Z' fill='#F9DC45' />
      <path d='M174.486 163.115C174.363 163.123 174.242 163.093 174.138 163.029C174.08 162.982 174.039 162.919 174.019 162.849C174 162.779 174.003 162.704 174.028 162.635C174.21 161.859 176.197 162.247 176.42 162.293C176.439 162.297 176.455 162.307 176.468 162.321C176.481 162.334 176.49 162.351 176.493 162.369C176.497 162.388 176.494 162.408 176.486 162.425C176.477 162.443 176.464 162.457 176.446 162.467C175.843 162.808 175.177 163.029 174.486 163.115ZM175.152 162.344C174.684 162.344 174.273 162.421 174.21 162.676C174.174 162.824 174.21 162.865 174.252 162.88C174.491 163.054 175.448 162.758 176.119 162.426C175.799 162.376 175.476 162.348 175.152 162.344Z' fill='#F9DC45' />
      <path d='M176.4 162.477C175.88 162.421 174.767 161.92 174.694 161.455C174.694 161.347 174.694 161.199 174.944 161.092C175.026 161.058 175.114 161.04 175.204 161.04C175.293 161.04 175.381 161.058 175.464 161.092C176.056 161.327 176.457 162.298 176.472 162.339C176.479 162.353 176.482 162.369 176.482 162.385C176.482 162.401 176.479 162.416 176.472 162.431C176.464 162.443 176.453 162.453 176.441 162.461C176.428 162.469 176.414 162.474 176.4 162.477ZM175.24 161.23C175.17 161.23 175.101 161.246 175.037 161.276C174.876 161.347 174.892 161.419 174.892 161.445C174.938 161.715 175.729 162.145 176.27 162.262C176.102 161.854 175.81 161.508 175.432 161.271C175.372 161.245 175.306 161.231 175.24 161.23Z' fill='#F9DC45' />
      <path d='M180.534 161.031L176.774 162.262L173.836 153.724L177.59 152.498L180.534 161.031Z' fill='#FF8B7B' />
      <path d='M205.223 147.377L201.812 149.35L195.744 142.401L199.16 140.428L205.223 147.377Z' fill='#FF8B7B' />
      <path d='M201.349 149.094L205.182 146.872C205.249 146.833 205.327 146.819 205.404 146.831C205.481 146.843 205.551 146.881 205.603 146.938L207.948 149.6C208.003 149.667 208.043 149.745 208.065 149.829C208.087 149.913 208.09 150 208.074 150.085C208.059 150.17 208.024 150.251 207.974 150.321C207.923 150.392 207.858 150.451 207.782 150.494C206.43 151.251 205.738 151.547 204.048 152.538C203.008 153.136 200.928 154.48 199.503 155.313C198.078 156.146 197.106 154.802 197.621 154.357C199.935 152.257 200.486 150.888 200.912 149.641C200.986 149.413 201.141 149.218 201.349 149.094Z' fill='#263238' />
      <path d='M176.67 161.823L180.773 160.474C180.847 160.45 180.926 160.452 180.998 160.48C181.071 160.508 181.131 160.56 181.168 160.627L182.894 163.693C182.937 163.769 182.962 163.853 182.967 163.939C182.973 164.025 182.958 164.112 182.925 164.192C182.893 164.272 182.842 164.344 182.777 164.403C182.712 164.461 182.635 164.505 182.551 164.531C181.064 164.996 178.911 165.609 177.044 166.217C174.866 166.932 173.014 168.286 170.352 168.67C168.745 168.9 168.121 167.362 168.792 167.137C171.787 166.033 173.789 164.807 175.869 162.415C176.075 162.149 176.352 161.944 176.67 161.823Z' fill='#263238' />
      <path d='M181.433 32.5987C185.208 32.7214 190.96 41.745 190.96 41.745L184.439 48.1219C182.202 45.8862 180.151 43.4769 178.308 40.9173C175.427 36.8295 177.575 32.4761 181.433 32.5987Z' fill='#F9DC45' />
      <path opacity='0.2' d='M181.699 41.7554L179.738 37.499L178.88 41.735C180.019 43.2679 181.215 44.6781 182.203 45.7818C182.449 44.4203 182.273 43.0177 181.699 41.7554Z' fill='black' />
      <path d='M158.735 51.6477C159.063 55.9807 159.708 61.3561 160.815 67.9987L181.948 66.5782C181.995 63.3744 179.488 47.9176 182.156 32.6499C179.802 32.3615 177.43 32.2267 175.058 32.2462C172.055 32.2753 169.057 32.4766 166.078 32.8492C164.026 33.1914 162.004 33.6885 160.03 34.3361C160.03 34.3361 158.356 36.38 158.439 45.4394C158.47 47.238 158.553 49.2921 158.735 51.6477Z' fill='#F9DC45' />
      <path opacity='0.2' d='M158.735 51.6478C159.853 49.2718 161.855 44.4942 161.335 41.9138L158.444 45.4395C158.47 47.2381 158.553 49.2922 158.735 51.6478Z' fill='black' />
      <path d='M164.232 40.672C163.046 44.1875 155.48 47.5599 155.48 47.5599L149.24 37.8515C152.354 36.339 155.641 35.1999 159.032 34.4586C164.502 33.4265 165.568 36.712 164.232 40.672Z' fill='#F9DC45' />
      <path opacity='0.2' d='M173.836 153.729L175.349 158.128L179.109 156.897L177.596 152.498L173.836 153.729Z' fill='black' />
      <path opacity='0.2' d='M199.16 140.428L195.749 142.406L198.874 145.982L202.285 144.01L199.16 140.428Z' fill='black' />
      <path d='M175.1 18.8792C174.58 22.7217 174.491 24.9853 172.375 26.9014C171.698 27.5339 170.857 27.9719 169.944 28.1677C169.031 28.3635 168.081 28.3096 167.197 28.012C166.313 27.7144 165.529 27.1844 164.93 26.4796C164.331 25.7748 163.94 24.9222 163.8 24.0145C163.197 20.5297 164.133 14.817 168.08 13.417C168.923 13.0847 169.841 12.9772 170.741 13.1056C171.64 13.234 172.489 13.5936 173.202 14.148C173.914 14.7024 174.465 15.4319 174.798 16.2627C175.131 17.0936 175.236 17.9962 175.1 18.8792Z' fill='#FF8B7B' />
      <path d='M168.787 26.6104C169.827 27.7856 173.347 27.2849 174.086 26.6819C174.824 26.079 181.704 22.0832 180.102 18.6802C178.5 15.2771 169.416 13.2946 168.277 16.2735C167.138 19.2524 167.71 25.3841 168.787 26.6104Z' fill='#263238' />
      <path d='M178.282 17.6733C178.282 17.6733 181.126 17.9288 181.766 20.3508C180.882 19.4566 179.509 18.1588 178.282 17.6733Z' fill='#263238' />
      <path d='M169.941 17.2392C165.854 16.9633 157.711 11.036 163.79 8.04177C169.868 5.0475 169.723 10.7857 172.172 12.247C174.621 13.7084 175.812 10.8214 178.708 11.9353C181.475 12.9982 182.728 19.1298 178.084 18.5473C173.441 17.9648 169.941 17.2392 169.941 17.2392Z' fill='#263238' />
      <path d='M170.513 16.4369C167.632 16.4369 163.16 14.2857 161.923 11.6798C161.646 11.1558 161.542 10.5598 161.627 9.97523C161.712 9.39068 161.981 8.84683 162.396 8.41982C163.384 7.31102 164.335 6.74896 165.308 6.70808C166.79 6.63654 168.001 7.76067 169.177 8.84903C170.217 9.83009 171.257 10.7549 172.224 10.5403C172.241 10.5366 172.258 10.5361 172.275 10.5389C172.292 10.5418 172.308 10.5479 172.322 10.5569C172.337 10.566 172.35 10.5777 172.359 10.5915C172.369 10.6053 172.376 10.6209 172.38 10.6374C172.384 10.6539 172.384 10.6709 172.381 10.6875C172.378 10.7041 172.372 10.72 172.363 10.7343C172.354 10.7485 172.342 10.7609 172.328 10.7706C172.314 10.7802 172.298 10.7871 172.281 10.7907C171.147 11.036 170.102 10.0651 168.995 9.03809C167.887 8.01104 166.696 6.89202 165.318 6.95845C164.424 7.00444 163.53 7.53585 162.588 8.58844C162.204 8.97661 161.955 9.47487 161.877 10.0113C161.799 10.5476 161.897 11.0944 162.157 11.5725C163.379 14.1273 167.825 16.2478 170.643 16.1712C170.678 16.1712 170.711 16.1847 170.735 16.2086C170.759 16.2326 170.773 16.2651 170.773 16.2989C170.773 16.332 170.76 16.3637 170.737 16.3875C170.714 16.4113 170.682 16.4254 170.648 16.4267L170.513 16.4369Z' fill='#263238' />
      <path d='M169.733 19.84C169.443 20.7726 168.858 21.5903 168.064 22.1751C167.024 22.9415 166.265 22.2109 166.369 21.0612C166.463 20.0392 167.128 18.3939 168.272 18.0771C169.416 17.7603 170.04 18.7209 169.733 19.84Z' fill='#FF8B7B' />
      <path d='M168.324 67.4877C168.324 67.4877 173.711 96.9348 176.509 107.91C179.577 119.933 196.851 146.095 196.851 146.095L203.091 142.477C203.091 142.477 190.294 120.199 188.391 108.242C186.311 95.2384 181.958 66.5935 181.958 66.5935L168.324 67.4877Z' fill='#263238' />
      <path d='M194.584 144.378C194.558 144.378 196.55 146.422 196.55 146.422L203.58 142.334L202.28 140.265L194.584 144.378Z' fill='#F9DC45' />
      <path opacity='0.2' d='M171.48 77.9115C176.68 78.5655 176.722 97.6502 176.4 107.486C174.694 100.665 172.099 87.487 170.321 78.1261C170.672 77.9293 171.08 77.8537 171.48 77.9115Z' fill='black' />
      <path d='M160.831 67.9987C160.831 67.9987 161.169 97.1238 161.959 108.978C162.76 121.303 173.68 157.725 173.68 157.725L180.045 155.635C180.045 155.635 174.101 121.262 174.028 109.137C173.956 95.8975 174.704 67.0942 174.704 67.0942L160.831 67.9987Z' fill='#263238' />
      <path d='M171.725 155.604C171.694 155.604 172.864 158.113 172.864 158.113L180.601 155.558L180.534 152.528L171.725 155.604Z' fill='#F9DC45' />
      <path d='M122.361 17.584L121.969 17.9199L123.788 19.9684L124.18 19.6325L122.361 17.584Z' fill='#F9DC45' />
      <path d='M124.706 10.8754L124.218 11.0505L126.886 18.2361L127.375 18.061L124.706 10.8754Z' fill='#F9DC45' />
      <path d='M130.386 13.2458L130.108 17.6931L130.627 17.7244L130.905 13.2771L130.386 13.2458Z' fill='#F9DC45' />
      <path d='M135.587 12.9029L132.917 18.2239L133.384 18.4498L136.053 13.1288L135.587 12.9029Z' fill='#F9DC45' />
      <path d='M137.059 18.5251L135.497 19.1609L135.696 19.6329L137.258 18.9972L137.059 18.5251Z' fill='#F9DC45' />
    </SvgIcon>
  )
}
