import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { removeToken } from '~/utils/token'
import { useAuth, useSidebar } from '~/contexts'
import { TSidebarItem } from '~/components/Layout'
import { ResponsiveSidebar, SidebarContent } from './components'
import { useNavigate } from 'react-router-dom'

export type SidebarProps = {
  isDrawerOpen: boolean
  sidebarItems: TSidebarItem[]
  toggleSidebar: () => void
}

export type Collapsibles = {

}

const COLLAPSIBLES_INITIAL_VALUES: Collapsibles = {
 
}

export const Sidebar: React.FC<SidebarProps> = ({ isDrawerOpen, sidebarItems, toggleSidebar }) => {
  const { setAuthUser } = useAuth()
  const [collapsibles, setCollapsibles] = useState<Collapsibles>(COLLAPSIBLES_INITIAL_VALUES)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { setSidebar } = useSidebar()
  const navigate = useNavigate()

  const signOut = async () => {
    removeToken()
    setAuthUser(null)
    navigate('/')
  }

  const toggleCollapsible = (collapsibleName: keyof Collapsibles) => {
    setCollapsibles(prevState => ({
      ...prevState,
      [collapsibleName]: !prevState[collapsibleName]
    }))
  }

  useEffect(() => {
    isMobile && setSidebar(false)
  }, [isMobile])

  return (
    <>
      {isMobile ? (
        <ResponsiveSidebar
          signOut={signOut}
          collapsibles={collapsibles}
          isDrawerOpen={isDrawerOpen}
          sidebarItems={sidebarItems}
          toggleSidebar={toggleSidebar}
          toggleCollapsible={toggleCollapsible}
        />
      ) : (
          
        <SidebarContent
          signOut={signOut}
          collapsibles={collapsibles}
          isDrawerOpen={isDrawerOpen}
          sidebarItems={sidebarItems}
          toggleCollapsible={toggleCollapsible}
          />
         
      )}
    </>
  )
}
