import { EBankAccountType, EConsumerClassification, ECustomerPersonType, ECustomerTension, EMaritalStatus, EProposalStatus, ETariffClassification, EUserType } from '~/graphql/types'

export type Option = {
  label: string,
  value: string
}

export const defaultOption: Option = {
  value: '', label: ''
}

export const personTypeOptions: Option[] = [
  { value: ECustomerPersonType.pf, label: 'Pessoa Física' },
  { value: ECustomerPersonType.pj, label: 'Pessoa Jurídica' }
]

export const maritalStatusOptions: Option[] = [
  { value: EMaritalStatus.married, label: 'Casado(a)' },
  { value: EMaritalStatus.single, label: 'Solteiro(a)' },
  { value: EMaritalStatus.divorced, label: 'Divorciado(a)' },
  { value: EMaritalStatus.widowed, label: 'Viúvo(a)' }
]

export const tariffClassificationOptions: Option[] = [
  { value: ETariffClassification.A4, label: 'A4' },
  { value: ETariffClassification.B1, label: 'B1' },
  { value: ETariffClassification.B2, label: 'B2' },
  { value: ETariffClassification.B3, label: 'B3' }
]

export const customerTensionOptions: Option[] = [
  { value: ECustomerTension.monofasico, label: 'Monofásico' },
  { value: ECustomerTension.bifasico, label: 'Bifásico' },
  { value: ECustomerTension.trifasico, label: 'Trifásico' }
]

export const getConsumerClassificationOptions = (tariffClassification: ETariffClassification): Option[] => {
  switch (tariffClassification) {
  case ETariffClassification.A4:
    return [
      { value: EConsumerClassification.industrial, label: 'Industrial' }
    ]
  case ETariffClassification.B1:
    return [
      { value: EConsumerClassification.residencial, label: 'Residencial' }
    ]
  case ETariffClassification.B2:
    return [
      { value: EConsumerClassification.rural, label: 'Rural' }
    ]
  case ETariffClassification.B3:
    return [
      { value: EConsumerClassification.comercial, label: 'Comercial' },
      { value: EConsumerClassification.industrial, label: 'Industrial' }
    ]
  default:
    return [defaultOption]
  }
}

export const userTypeOptions: Option[] = [
  { value: EUserType.internal, label: 'Interno' },
  { value: EUserType.seller, label: 'Vendedor' },
]

export const proposalStatusOptions: Option[] = [
  { value: EProposalStatus.canceled, label: 'Cancelado' },
  { value: EProposalStatus.documentation, label: 'Documentação' },
  { value: EProposalStatus.finalized, label: 'Finalizado' },
  { value: EProposalStatus.formalization, label: 'Formalização' }
]

export const bankAccountTypeOptions: Option[] = [
  { value: EBankAccountType.cc, label: 'Conta corrente' },
  { value: EBankAccountType.cp, label: 'Poupança' }
]

export const accountOwnerOptions: Option[] = [
  { value: 'company', label: 'Empresa' },
  { value: 'legalRepresentative', label: 'Representante legal' }
]
