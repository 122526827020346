import styled from '@emotion/styled'
import { EProposalStatus } from '~/graphql/types'
import { colorProposalStatus } from '~/utils'

type ContainerProps = {
  status: EProposalStatus;
}
export const Container = styled.div<ContainerProps>`

  span {
    ${(props) => colorProposalStatus(props.status)}
    padding: .2rem .6rem;
    border-radius: .4rem;
    font-weight: 600;
    font-size: .8rem;
  }

`
