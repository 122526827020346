import { Typography } from '@mui/material'
import { formatMoney, theme } from '~/utils'
import { Container } from './styles'

type DashboardCardProps = {
  icon: JSX.Element,
  title: string,
  description: string,
  value: number,
  index: number
}

export const DashboardCard: React.FC<DashboardCardProps> = (item) => {

  const colorByCard: {[x: string]: {
    color: string,
    background: string
  }} = {
    0: {
      color: theme.palette.info.dark,
      background: theme.palette.info.light
    },
    1: {
      color: theme.palette.success.main,
      background: theme.palette.success[100] || ''
    },
    2: {
      color: theme.palette.error.main,
      background: theme.palette.error.light
    },
  }

  return (
    <Container palette={colorByCard[item.index]} key={item.title}>
      {item.icon}
      <Typography sx={{ textTransform: 'uppercase', fontWeight: 500, color: theme.palette.grey['800'] }}>{item.title}</Typography>
      <Typography sx={{ color: theme.palette.grey['600'] }}>{item.description}</Typography>
      <Typography sx={{ fontWeight: 500, fontSize: '1.8rem', color: theme.palette.grey['800'] }}>{item.index === 2 ? item.value : formatMoney(item.value)}</Typography>
    </Container>
  )
}
