import { Container, Content } from './styles'

export const PageLoader: React.FC = () => {
  return ((
    <Container>
      <Content>
        <svg viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path className='a' d='M17.9856 27.4886L17.9976 38.2795C18.2564 38.2896 18.5132 38.2977 18.774 38.2977C19.0489 38.2977 19.3217 38.2876 19.5926 38.2775L19.5805 27.4866H17.9856V27.4886Z' fill='#FFCE00'/>
          <path className='b' d='M24.2407 25.5445L31.8502 33.1597C32.2414 32.7975 32.6166 32.4211 32.9777 32.0267L25.3682 24.4094L24.2407 25.5425V25.5445Z' fill='#FFCE00'/>
          <path className='c' d='M38.0514 18.1205L27.2922 18.1326V19.7325L38.0514 19.7204C38.0615 19.4608 38.0715 19.2032 38.0715 18.9416C38.0715 18.6659 38.0635 18.3922 38.0514 18.1205Z' fill='#FFCE00'/>
          <path className='d' d='M24.3051 0.39646C23.7975 0.243511 23.2839 0.110687 22.7603 0L21.3359 5.61283C21.8636 5.71345 22.3792 5.84426 22.8827 6.00526L24.3071 0.39646H24.3051Z' fill='#FFCE00'/>
          <path className='e' d='M13.632 6.38562L11.1283 1.17126C10.6388 1.38459 10.1593 1.61602 9.69189 1.86759L12.1956 7.08194C12.659 6.82233 13.1385 6.59089 13.632 6.38562Z' fill='#FFCE00'/>
          <path className='f' d='M6.789 12.6384L1.53279 10.2596C1.29405 10.7346 1.07537 11.2216 0.874756 11.7167L6.13298 14.0975C6.32356 13.5963 6.54424 13.1093 6.789 12.6384Z' fill='#FFCE00'/>
          <path className='g' d='M5.55514 21.8395L0 23.4072C0.124384 23.9284 0.266823 24.4436 0.431331 24.9468L5.98647 23.379C5.81393 22.8779 5.66949 22.3647 5.55514 21.8395Z' fill='#FFCE00'/>
          <path d='M32.9495 5.82218C32.5884 5.42975 32.2133 5.05341 31.8201 4.69116L24.0822 12.4714C22.6397 11.278 20.79 10.5616 18.7738 10.5616C14.1616 10.5616 10.4221 14.3129 10.4221 18.9396C10.4221 20.9682 11.1403 22.8297 12.338 24.2787L4.6001 32.057C4.96121 32.4494 5.33637 32.8257 5.72958 33.188L13.4675 25.4077L14.605 24.2626L24.0742 14.7435L25.2117 13.5984L32.9495 5.82017V5.82218ZM13.4755 23.1356C12.5667 21.9825 12.023 20.5254 12.023 18.9416C12.023 15.2004 15.0463 12.1696 18.7738 12.1696C20.3487 12.1696 21.7951 12.7109 22.9427 13.6186L13.4735 23.1376L13.4755 23.1356Z' fill='#FFCE00'/>
        </svg>
      </Content>
    </Container>
  ))
}
