import { Box, Typography, Button } from '@mui/material'
import BackgroundImage from '~/assets/images/login-background.webp'
import LogoDesperta from '~/assets/images/logo/horizontal-black.svg'
import { theme } from '~/utils'
import { ContractCard } from './components'
import { IconArrowRight, IconLogout } from '@tabler/icons-react'
import { GridColumn } from '~/styles'
import { useAuth, useContract } from '~/contexts'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const ContractList: React.FC = () => {
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { contracts, selectedContract, contractsIsLoading, setSelectedContract } = useContract()

  useEffect(() => {
    if(contracts.length === 1) {
      setSelectedContract(contracts[0])
      navigate(`/app/contracts/${contracts[0]._id}/`)
    }
  }, [contracts])
  
  return (
    <Box component='main' sx={{ 
      width: '100%',
      minHeight: '100vh', 
      height: '100%', 
      backgroundImage: `url(${BackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flexFlow: 'column',
      paddingBottom: '2rem'
    }}>
      <Box sx={{
        width: '100%',
        minHeight: '90vh',
        height: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
        padding: '2rem 1rem'
      }}>
        {contracts.length > 0 ? 
          <Typography sx={{ fontWeight: '600', color: theme.palette.black[700], fontSize: '2rem', paddingBottom: '2rem' }} variant='h2'>Selecione a unidade consumidora:</Typography>
          : <Typography sx={{ fontWeight: '600', color: theme.palette.black[700], fontSize: '2rem', paddingBottom: '2rem' }} variant='h2'>Você não possui unidades consumidoras</Typography>
        }

        <Box>
          <GridColumn columns={4} padding='0 1rem' responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 2 }, { breakWidth: theme.breakpoints.values.sm, columns: 1 }]} >
            {contracts.map((item) => {
              return (
                <ContractCard
                  key={item._id}
                  contract={item}
                  $selected={selectedContract?._id === item._id} 
                  
                />
              )
            })}
          </GridColumn>

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '2rem 0', flexFlow: 'column' }}>
            {contracts.length > 0 ? (
              <Button
                disabled={!selectedContract?._id || contractsIsLoading}
                endIcon={<IconArrowRight />} 
                color='purple' 
                sx={{ borderRadius: '2rem' }} 
                size='large'
                onClick={() => navigate(`/app/contracts/${selectedContract?._id}/`)}
              >
                Acessar unidade selecionada
              </Button>
            ) : (
              <Button onClick={() => logout()} color='purple' size='large' endIcon={<IconLogout />}  sx={{ borderRadius: '2rem' }} >
                Sair
              </Button>
            )}
           
          </Box>
        </Box>

      </Box>
      <img style={{ bottom: '4rem', left: '0', right: '0', margin: '0 auto' }} src={LogoDesperta} alt='logo desperta' />
    </Box>
  )
}
