import { ReactNode } from 'react';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { theme } from '~/utils';


export function WaitingModalCashback() {
  return (
    <Box sx={{ display: 'flex', flexFlow: "column", gap: '24px' }}>
      <CircularProgress size='50px' sx={{ alignSelf: 'center', margin: 3, color: "#000" }} />
      <Typography sx={{ fontSize: '24px', color: theme.palette.grey['700'], lineHeight: '40px', textAlign: 'center', fontWeight: '600' }}>Aguarde</Typography>
      <Divider />
      <Typography sx={{ fontSize: '16px', color: theme.palette.grey['400'], lineHeight: '25px', textAlign: 'center' }}>Estamos processando a ação </Typography>
    </Box>
  );
}
