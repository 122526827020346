import { SvgIcon, SvgIconProps } from '@mui/material'

export const WomanTouchingFloatingPhone: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='256' height='180' viewBox='0 0 256 180' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M256 156.823H0V156.952H256V156.823Z' fill='#EBEBEB' />
      <path d='M37.719 161.709H26.8595V161.837H37.719V161.709Z' fill='#EBEBEB' />
      <path d='M131.128 160.318H87.6237V160.447H131.128V160.318Z' fill='#EBEBEB' />
      <path d='M54.1594 166.477H34.6471V166.605H54.1594V166.477Z' fill='#EBEBEB' />
      <path d='M230.349 165.615H208.235V165.743H230.349V165.615Z' fill='#EBEBEB' />
      <path d='M206.561 165.615H200.433V165.743H206.561V165.615Z' fill='#EBEBEB' />
      <path d='M189.896 163.449H178.12V163.577H189.896V163.449Z' fill='#EBEBEB' />
      <path d='M172.201 163.449H147.4V163.577H172.201V163.449Z' fill='#EBEBEB' />
      <path d='M38.4154 156.823H46.3002L46.3002 95.9969H38.4154L38.4154 156.823Z' fill='#F0F0F0' />
      <path d='M115.313 95.9971H46.3002V156.823H115.313V95.9971Z' fill='#F5F5F5' />
      <path d='M115.937 118.953H55.3472V151.486H115.937V118.953Z' fill='#FAFAFA' />
      <path d='M115.937 104.789H55.3472V115.499H115.937V104.789Z' fill='#FAFAFA' />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.7'>
        <path d='M54.8044 151.486H55.3472L55.3472 118.953H54.8044L54.8044 151.486Z' fill='#E6E6E6' />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.7'>
        <path d='M54.8044 115.499H55.3472V104.788H54.8044V115.499Z' fill='#E6E6E6' />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.7'>
        <path d='M38.4102 99.9385L115.482 99.9385V98.5631L38.4102 98.5631V99.9385Z' fill='#E6E6E6' />
      </g>
      <path d='M37.4938 99.1431H46.3002V95.1656H37.4938V99.1431Z' fill='#F0F0F0' />
      <path d='M116.234 95.1655H46.3002V99.1429H116.234V95.1655Z' fill='#F5F5F5' />
      <path d='M66.9952 83.4848C66.555 82.8559 66.2979 82.1167 66.2528 81.3498C66.1857 80.5742 66.2532 79.7928 66.4525 79.0403C66.8545 77.5522 67.5703 76.1679 68.5517 74.9808C69.0316 74.4024 69.5444 73.8523 70.0877 73.3334C70.3744 73.0921 70.6509 72.8458 70.9427 72.6097C71.2239 72.3682 71.5179 72.142 71.8234 71.9323C72.4277 71.5121 73.0532 71.1232 73.6973 70.7673C74.3629 70.4388 75.0182 70.1155 75.7146 69.8538C75.2026 70.4286 74.6906 70.9315 74.1786 71.4396C73.6666 71.9477 73.1802 72.466 72.6682 72.9792C72.1562 73.4925 71.7056 74.0057 71.2141 74.5189C70.9734 74.7704 70.7584 75.0321 70.5331 75.3041C70.296 75.5525 70.0738 75.8147 69.8675 76.0893C68.9997 77.1289 68.2716 78.278 67.7018 79.5073C67.4217 80.1058 67.2105 80.7343 67.072 81.3806C66.9176 82.0713 66.8916 82.7846 66.9952 83.4848Z' fill='#E6E6E6' />
      <path d='M55.0298 81.3345C55.2491 80.7237 55.3617 80.0796 55.3626 79.4304C55.3612 79.1299 55.3389 78.8298 55.296 78.5323C55.2352 78.2466 55.1548 77.9653 55.0554 77.6906C54.594 76.5981 53.8942 75.623 53.0074 74.8372C52.7923 74.6216 52.5363 74.4369 52.3059 74.2316C52.0768 74.017 51.832 73.8197 51.5738 73.6414C51.0618 73.2821 50.5754 72.8562 50.0378 72.5226C49.7715 72.3429 49.5258 72.1633 49.2339 71.9734C48.9421 71.7835 48.7219 71.5885 48.4147 71.4192C47.8441 71.0575 47.2921 70.6669 46.761 70.249C47.4352 70.3498 48.1034 70.4868 48.7629 70.6596C49.0947 70.7365 49.42 70.8395 49.7357 70.9675C50.0531 71.0907 50.3706 71.2139 50.688 71.3473L51.1642 71.5475C51.3178 71.6193 51.4663 71.7117 51.6199 71.7938C51.9219 71.958 52.2189 72.1377 52.521 72.307C52.8227 72.4866 53.1103 72.689 53.3811 72.9126C53.6525 73.1384 53.9392 73.3386 54.1952 73.5901C55.3063 74.5815 56.0678 75.9065 56.3661 77.3673C56.4053 77.5512 56.4241 77.7388 56.4224 77.9267C56.4224 78.1166 56.4224 78.3065 56.4224 78.4913C56.3894 78.8598 56.3138 79.2231 56.1971 79.5741C55.9783 80.258 55.5741 80.8675 55.0298 81.3345Z' fill='#E6E6E6' />
      <path d='M55.5776 81.3345C55.8515 79.9738 55.8845 78.5753 55.6749 77.2031C55.4652 75.9058 55.0265 74.6562 54.3795 73.5131L53.8675 72.6663C53.6781 72.3943 53.4631 72.1531 53.2736 71.8657L52.9767 71.4551L52.6336 71.0805C52.4083 70.829 52.1831 70.5672 51.9629 70.3055C51.4816 69.818 50.9747 69.3407 50.4832 68.8274C49.9917 68.3142 49.4234 67.8831 48.9165 67.3391C49.6127 67.5698 50.2963 67.837 50.9645 68.1397C51.6135 68.493 52.2425 68.8819 52.8487 69.3047C53.1354 69.5408 53.4272 69.782 53.7088 70.0284L54.1338 70.3979L54.5127 70.8238C54.7584 71.1113 55.0247 71.3986 55.2448 71.7014L55.8592 72.6766C56.6314 74.0304 57.0274 75.5669 57.0061 77.1261C57.0029 78.6479 56.501 80.1265 55.5776 81.3345Z' fill='#E6E6E6' />
      <path d='M66.3398 82.094C65.3213 80.8157 64.4617 79.4181 63.7798 77.9318C63.0813 76.4459 62.5176 74.9 62.0953 73.3129C61.6831 71.7149 61.4462 70.0766 61.3888 68.4271C61.3434 66.7705 61.5948 65.1191 62.1312 63.5515C62.2745 65.1938 62.4537 66.7488 62.72 68.2936C62.9862 69.8384 63.36 71.3729 63.744 72.9126C64.128 74.4522 64.5785 75.9457 65.024 77.4699C65.4694 78.9942 65.92 80.5082 66.3398 82.094Z' fill='#E6E6E6' />
      <path d='M65.8074 81.8373C64.5904 80.6632 63.5038 79.3607 62.5664 77.9523C61.6179 76.5509 60.784 75.0748 60.073 73.5386C59.361 71.992 58.8003 70.3798 58.3988 68.7246C57.9981 67.0611 57.8735 65.3428 58.0301 63.6387C58.5421 65.2656 59.0541 66.8052 59.6788 68.3295C60.3034 69.8537 60.9485 71.3318 61.6192 72.8252C62.29 74.3187 63.0119 75.7916 63.7236 77.2851C64.4352 78.7785 65.1469 80.272 65.8074 81.8373Z' fill='#E6E6E6' />
      <path d='M67.8912 83.7926C66.4402 82.9163 65.089 81.8838 63.8617 80.7133C62.628 79.5587 61.4877 78.3077 60.4518 76.972C59.4135 75.6233 58.5065 74.178 57.7433 72.6558C56.9858 71.1225 56.4826 69.4758 56.2534 67.7803C57.1187 69.2481 57.9789 70.6286 58.9056 71.9784C59.8323 73.3281 60.8102 74.6266 61.7984 75.9301C62.7865 77.2337 63.8464 78.4962 64.8397 79.8049C65.8329 81.1136 66.8979 82.4172 67.8912 83.7926Z' fill='#E6E6E6' />
      <path d='M64.7578 81.5552C63.3577 80.854 62.0562 79.9705 60.8871 78.9275C59.7092 77.8947 58.6356 76.7481 57.6819 75.5043C56.7258 74.25 55.9166 72.8896 55.2704 71.4499C54.6267 70.0056 54.2386 68.46 54.1235 66.8823C54.9069 68.2526 55.6237 69.5613 56.4531 70.8136C57.2826 72.0658 58.1274 73.2821 59.0131 74.4676C59.8989 75.6532 60.8563 76.8284 61.8189 77.9934C62.7815 79.1584 63.785 80.3286 64.7578 81.5552Z' fill='#E6E6E6' />
      <path d='M67.2614 82.9871C66.6863 82.5768 66.2021 82.0519 65.8391 81.4451C65.4761 80.8384 65.2421 80.163 65.152 79.4613C64.9615 78.0301 65.1929 76.5744 65.8176 75.2734L66.0326 74.791L66.2937 74.3394L66.8416 73.4515C67.0532 73.1778 67.2682 72.9109 67.4867 72.6509C67.6973 72.3834 67.9248 72.1297 68.1676 71.8913C68.6529 71.4213 69.1658 70.9809 69.7036 70.5724C70.2525 70.1836 70.8235 69.8272 71.4137 69.5049C71.0714 70.0741 70.7006 70.6257 70.3027 71.1574C69.9238 71.6707 69.5859 72.2352 69.207 72.7433C68.8281 73.2514 68.5465 73.8159 68.183 74.3342L67.758 75.1451L67.5328 75.5454L67.3638 75.9611C66.8419 77.0296 66.5373 78.1915 66.4678 79.3792C66.4249 80.6296 66.6979 81.8706 67.2614 82.9871Z' fill='#E6E6E6' />
      <path d='M66.3142 81.3498C65.284 80.3433 64.5354 79.0832 64.1433 77.6957C63.7132 76.2572 63.692 74.7269 64.0819 73.2769C64.2759 72.5514 64.578 71.8593 64.9779 71.2241C65.3655 70.5944 65.8375 70.0211 66.3808 69.5202C67.4226 68.5372 68.68 67.813 70.0518 67.4058C69.0909 68.338 68.1978 69.3379 67.3792 70.3978C67.0018 70.9031 66.6694 71.4406 66.3859 72.0042C66.1025 72.5508 65.8809 73.1275 65.7254 73.7234C65.4026 74.9304 65.3105 76.1876 65.4541 77.4289C65.6019 78.7626 65.8903 80.0769 66.3142 81.3498Z' fill='#E6E6E6' />
      <path d='M63.8105 80.1335L55.7977 72.425L56.1203 79.9847L63.8105 80.1335Z' fill='#E6E6E6' />
      <path d='M72.5657 79.3123H51.2819L54.0671 95.1655H69.7804L72.5657 79.3123Z' fill='#F5F5F5' />
      <path d='M59.2691 79.3123H51.2819L54.0671 95.1655H58.2655L59.2691 79.3123Z' fill='#EBEBEB' />
      <path d='M94.6432 0H51.6813V54.134H94.6432V0Z' fill='#F0F0F0' />
      <path d='M104.131 0H53.33V54.134H104.131V0Z' fill='#F5F5F5' />
      <path d='M57.5846 4.25968V49.864H99.8758V4.25968H57.5846Z' fill='white' />
      <path d='M222.945 156.823H157.588L151.787 48.509H217.144L222.945 156.823Z' fill='#EBEBEB' />
      <path d='M224.942 156.823H159.585L153.784 48.509H219.141L224.942 156.823Z' fill='#F5F5F5' />
      <path d='M192.164 145.651H186.327L186.563 59.6819H192.399L192.164 145.651Z' fill='white' />
      <path d='M207.601 145.651H201.764L202.004 59.6819H207.836L207.601 145.651Z' fill='white' />
      <path d='M176.722 145.651H170.885L171.126 59.6819H176.963L176.722 145.651Z' fill='white' />
      <path d='M128 180C182.826 180 227.272 177.399 227.272 174.19C227.272 170.982 182.826 168.381 128 168.381C73.1737 168.381 28.7283 170.982 28.7283 174.19C28.7283 177.399 73.1737 180 128 180Z' fill='#F5F5F5' />
      <path d='M198.028 111.504L197.367 123.587L197.878 123.616L198.54 111.532L198.028 111.504Z' fill='#F3CC04' />
      <path d='M198.593 101.254L198.252 107.48L198.764 107.508L199.104 101.282L198.593 101.254Z' fill='#F3CC04' />
      <path d='M187.802 146.621H127.785C126.877 146.632 125.977 146.455 125.14 146.1C124.304 145.746 123.55 145.223 122.925 144.562C122.3 143.902 121.818 143.12 121.509 142.264C121.2 141.408 121.07 140.498 121.129 139.589L128.041 13.2768C128.17 11.3838 129.005 9.60892 130.381 8.30585C131.757 7.00277 133.572 6.26722 135.465 6.24579H195.487C196.392 6.23901 197.289 6.41924 198.122 6.77525C198.955 7.13125 199.706 7.6554 200.328 8.31503C200.95 8.97467 201.43 9.75564 201.738 10.6093C202.046 11.463 202.175 12.371 202.117 13.2768L195.2 139.589C195.073 141.479 194.241 143.251 192.871 144.553C191.5 145.856 189.69 146.594 187.802 146.621Z' fill='#F3CC04' />
      <path opacity='0.8' d='M184.172 146.621H127.785C126.877 146.632 125.977 146.455 125.14 146.1C124.304 145.746 123.55 145.223 122.925 144.562C122.3 143.902 121.818 143.12 121.509 142.264C121.2 141.408 121.07 140.498 121.129 139.59L128.041 13.2769C128.17 11.3839 129.005 9.60897 130.381 8.30589C131.757 7.00281 133.572 6.26726 135.465 6.24583H191.857C192.762 6.2383 193.66 6.41801 194.493 6.77376C195.327 7.1295 196.078 7.65363 196.7 8.31343C197.322 8.97322 197.802 9.75451 198.11 10.6085C198.417 11.4625 198.546 12.3709 198.487 13.2769L191.575 139.59C191.446 141.479 190.613 143.25 189.241 144.552C187.87 145.855 186.06 146.593 184.172 146.621Z' fill='white' />
      <path d='M156.227 29.1455H140.032C139.65 29.1485 139.271 29.0724 138.919 28.9218C138.567 28.7713 138.25 28.5497 137.988 28.2708C137.725 27.9919 137.523 27.6617 137.394 27.3009C137.265 26.9402 137.211 26.5565 137.236 26.174C137.29 25.3768 137.642 24.6291 138.22 24.0795C138.799 23.53 139.563 23.2187 140.36 23.2076H156.554C156.936 23.204 157.315 23.2795 157.667 23.4294C158.019 23.5794 158.336 23.8005 158.598 24.079C158.861 24.3575 159.063 24.6873 159.192 25.0479C159.322 25.4084 159.375 25.7918 159.35 26.174C159.296 26.9717 158.945 27.72 158.366 28.2704C157.788 28.8208 157.024 29.1332 156.227 29.1455Z' fill='white' />
      <path d='M159.35 26.174C159.258 27.6129 158.626 28.9637 157.581 29.9549C156.537 30.946 155.156 31.5039 153.718 31.5165C153.029 31.5245 152.346 31.3895 151.712 31.1199C151.077 30.8504 150.506 30.4522 150.032 29.9503C149.559 29.4485 149.195 28.8539 148.962 28.204C148.729 27.5541 148.633 26.8629 148.68 26.174C148.771 24.735 149.403 23.3842 150.448 22.3931C151.493 21.402 152.873 20.844 154.312 20.8314C155.001 20.8234 155.684 20.9585 156.318 21.228C156.952 21.4976 157.524 21.8958 157.997 22.3976C158.47 22.8994 158.835 23.494 159.068 24.1439C159.3 24.7938 159.396 25.485 159.35 26.174Z' fill='#F3CC04' />
      <path d='M155.187 48.1397H138.993C138.61 48.1427 138.231 48.0665 137.88 47.916C137.528 47.7655 137.211 47.5438 136.949 47.2649C136.686 46.986 136.484 46.6559 136.355 46.2951C136.225 45.9343 136.172 45.5507 136.197 45.1682C136.251 44.371 136.602 43.6233 137.181 43.0737C137.76 42.5241 138.523 42.2128 139.32 42.2018H155.515C155.897 42.1981 156.276 42.2736 156.628 42.4236C156.979 42.5735 157.296 42.7947 157.559 43.0732C157.821 43.3517 158.024 43.6815 158.153 44.042C158.282 44.4025 158.336 44.7859 158.31 45.1682C158.257 45.9659 157.906 46.7142 157.327 47.2646C156.749 47.815 155.985 48.1274 155.187 48.1397Z' fill='white' />
      <path d='M149.448 48.9066C151.617 46.7918 151.756 43.4091 149.758 41.351C147.761 39.2929 144.384 39.3389 142.215 41.4536C140.046 43.5684 139.907 46.9511 141.905 49.0092C143.902 51.0673 147.279 51.0214 149.448 48.9066Z' fill='#F3CC04' />
      <path opacity='0.3' d='M149.448 48.9066C151.617 46.7918 151.756 43.4091 149.758 41.351C147.761 39.2929 144.384 39.3389 142.215 41.4536C140.046 43.5684 139.907 46.9511 141.905 49.0092C143.902 51.0673 147.279 51.0214 149.448 48.9066Z' fill='#FAFAFA' />
      <path d='M136.914 86.1278H153.088C153.886 86.1154 154.649 85.8031 155.228 85.2527C155.806 84.7022 156.157 83.954 156.211 83.1563C156.237 82.7736 156.183 82.3897 156.053 82.0289C155.924 81.668 155.721 81.3379 155.458 81.0593C155.195 80.7808 154.877 80.5597 154.525 80.4101C154.173 80.2605 153.793 80.1855 153.411 80.1899H137.242C136.444 80.2008 135.68 80.5119 135.1 81.0614C134.521 81.6109 134.168 82.3586 134.113 83.1563C134.089 83.539 134.143 83.9228 134.273 84.2836C134.403 84.6443 134.605 84.9744 134.868 85.2533C135.131 85.5321 135.448 85.7536 135.8 85.9041C136.152 86.0545 136.531 86.1307 136.914 86.1278Z' fill='white' />
      <path d='M143.065 86.8871C145.233 84.7723 145.372 81.3896 143.375 79.3315C141.378 77.2734 138 77.3193 135.832 79.4341C133.663 81.5488 133.524 84.9316 135.521 86.9897C137.519 89.0478 140.896 89.0018 143.065 86.8871Z' fill='#F3CC04' />
      <path opacity='0.7' d='M143.065 86.8871C145.233 84.7723 145.372 81.3896 143.375 79.3315C141.378 77.2734 138 77.3193 135.832 79.4341C133.663 81.5488 133.524 84.9316 135.521 86.9897C137.519 89.0478 140.896 89.0018 143.065 86.8871Z' fill='#FAFAFA' />
      <path d='M152.064 105.122H135.875C135.492 105.125 135.113 105.049 134.761 104.898C134.409 104.748 134.092 104.526 133.829 104.248C133.566 103.969 133.363 103.639 133.233 103.278C133.104 102.917 133.049 102.533 133.074 102.151C133.129 101.353 133.481 100.605 134.061 100.056C134.64 99.5063 135.405 99.1952 136.202 99.1843H152.392C152.774 99.1799 153.154 99.2548 153.506 99.4045C153.858 99.5541 154.176 99.7752 154.439 100.054C154.702 100.332 154.905 100.662 155.034 101.023C155.164 101.384 155.218 101.768 155.192 102.151C155.138 102.949 154.787 103.698 154.207 104.249C153.627 104.799 152.862 105.111 152.064 105.122Z' fill='white' />
      <path d='M155.192 102.151C155.1 103.59 154.469 104.94 153.424 105.931C152.379 106.923 150.999 107.481 149.56 107.493C148.871 107.501 148.188 107.366 147.553 107.097C146.919 106.827 146.347 106.429 145.873 105.927C145.4 105.425 145.035 104.831 144.801 104.181C144.568 103.531 144.471 102.84 144.517 102.151C144.611 100.712 145.244 99.363 146.288 98.3724C147.332 97.3818 148.711 96.823 150.149 96.808C150.838 96.7993 151.522 96.9339 152.157 97.2031C152.792 97.4723 153.364 97.8704 153.838 98.3723C154.312 98.8742 154.677 99.4691 154.91 100.119C155.143 100.77 155.239 101.461 155.192 102.151Z' fill='#F3CC04' />
      <path d='M137.953 67.1336H154.148C154.945 67.1213 155.709 66.8089 156.288 66.2585C156.866 65.7081 157.217 64.9598 157.271 64.1621C157.296 63.7794 157.243 63.3956 157.113 63.0347C156.983 62.6739 156.781 62.3438 156.518 62.0652C156.255 61.7866 155.937 61.5656 155.585 61.4159C155.232 61.2663 154.853 61.1914 154.47 61.1957H138.281C137.484 61.2068 136.72 61.518 136.141 62.0676C135.563 62.6172 135.212 63.3649 135.158 64.1621C135.132 64.5446 135.186 64.9283 135.315 65.289C135.445 65.6498 135.647 65.98 135.909 66.2589C136.171 66.5378 136.488 66.7594 136.84 66.9099C137.192 67.0604 137.571 67.1366 137.953 67.1336Z' fill='white' />
      <path d='M135.168 64.1622C135.121 64.8512 135.217 65.5424 135.45 66.1923C135.683 66.8422 136.047 67.4368 136.521 67.9386C136.994 68.4404 137.566 68.8386 138.2 69.1082C138.834 69.3777 139.517 69.5128 140.206 69.5048C141.644 69.4922 143.025 68.9342 144.07 67.9431C145.114 66.952 145.746 65.6012 145.838 64.1622C145.885 63.4733 145.789 62.7821 145.556 62.1322C145.323 61.4823 144.959 60.8877 144.485 60.3859C144.012 59.8841 143.44 59.4858 142.806 59.2163C142.172 58.9467 141.489 58.8117 140.8 58.8197C139.362 58.8323 137.981 59.3902 136.936 60.3813C135.892 61.3725 135.26 62.7233 135.168 64.1622Z' fill='#F3CC04' />
      <path opacity='0.7' d='M135.168 64.1622C135.121 64.8512 135.217 65.5424 135.45 66.1923C135.683 66.8422 136.047 67.4368 136.521 67.9386C136.994 68.4404 137.566 68.8386 138.2 69.1082C138.834 69.3777 139.517 69.5128 140.206 69.5048C141.644 69.4922 143.025 68.9342 144.07 67.9431C145.114 66.952 145.746 65.6012 145.838 64.1622C145.885 63.4733 145.789 62.7821 145.556 62.1322C145.323 61.4823 144.959 60.8877 144.485 60.3859C144.012 59.8841 143.44 59.4858 142.806 59.2163C142.172 58.9467 141.489 58.8117 140.8 58.8197C139.362 58.8323 137.981 59.3902 136.936 60.3813C135.892 61.3725 135.26 62.7233 135.168 64.1622Z' fill='#FAFAFA' />
      <path d='M191.698 25.2861H167.695C167.543 25.2864 167.391 25.2553 167.251 25.1946C167.111 25.134 166.984 25.0451 166.88 24.9335C166.775 24.8218 166.694 24.6899 166.643 24.5457C166.591 24.4016 166.569 24.2483 166.579 24.0955C166.601 23.7768 166.742 23.4779 166.973 23.2583C167.204 23.0387 167.51 22.9143 167.828 22.9099H191.831C191.984 22.9089 192.135 22.9394 192.275 22.9995C192.415 23.0596 192.542 23.148 192.647 23.2592C192.751 23.3704 192.832 23.5021 192.884 23.646C192.935 23.7898 192.957 23.9429 192.947 24.0955C192.927 24.4151 192.787 24.7152 192.555 24.9359C192.323 25.1566 192.017 25.2816 191.698 25.2861Z' fill='white' />
      <path d='M183.7 29.4431H167.47C167.317 29.4441 167.165 29.4135 167.024 29.3532C166.883 29.2928 166.756 29.204 166.651 29.0923C166.546 28.9806 166.465 28.8484 166.413 28.7039C166.361 28.5594 166.339 28.4057 166.349 28.2524C166.371 27.9337 166.511 27.6349 166.743 27.4153C166.974 27.1957 167.279 27.0713 167.598 27.0669H183.834C183.986 27.0659 184.138 27.0964 184.278 27.1564C184.419 27.2165 184.546 27.3048 184.651 27.416C184.756 27.5271 184.837 27.6587 184.889 27.8026C184.942 27.9465 184.964 28.0996 184.955 28.2524C184.933 28.5725 184.792 28.8726 184.559 29.0932C184.327 29.3138 184.02 29.4387 183.7 29.4431Z' fill='white' />
      <path d='M184.51 101.262H163.518C163.365 101.263 163.213 101.232 163.073 101.171C162.933 101.11 162.806 101.021 162.702 100.91C162.597 100.798 162.517 100.666 162.465 100.522C162.413 100.378 162.392 100.225 162.401 100.072C162.422 99.7527 162.562 99.4531 162.794 99.2333C163.026 99.0134 163.332 98.8895 163.651 98.8863H184.617C184.77 98.8853 184.921 98.9158 185.062 98.9758C185.202 99.0358 185.329 99.1241 185.434 99.2353C185.539 99.3464 185.62 99.478 185.673 99.6219C185.725 99.7658 185.747 99.9189 185.738 100.072C185.717 100.388 185.579 100.684 185.352 100.904C185.125 101.124 184.825 101.252 184.51 101.262Z' fill='white' />
      <path d='M173.675 105.415H163.328C163.175 105.416 163.024 105.385 162.883 105.325C162.743 105.265 162.616 105.177 162.511 105.066C162.406 104.954 162.325 104.823 162.272 104.679C162.22 104.535 162.198 104.382 162.207 104.229C162.229 103.91 162.369 103.611 162.601 103.392C162.832 103.172 163.137 103.048 163.456 103.043H173.819C173.972 103.043 174.123 103.073 174.264 103.133C174.404 103.193 174.531 103.281 174.636 103.393C174.741 103.504 174.822 103.635 174.875 103.779C174.927 103.923 174.949 104.076 174.94 104.229C174.918 104.55 174.775 104.851 174.541 105.071C174.306 105.291 173.997 105.414 173.675 105.415Z' fill='white' />
      <path d='M190.561 46.0611H166.559C166.24 46.0655 165.935 46.1898 165.703 46.4094C165.472 46.6291 165.331 46.9279 165.309 47.2466C165.3 47.3995 165.323 47.5526 165.375 47.6965C165.427 47.8403 165.509 47.9719 165.614 48.0831C165.719 48.1942 165.846 48.2825 165.986 48.3426C166.127 48.4026 166.278 48.4331 166.431 48.4321H190.433C190.752 48.4277 191.057 48.3034 191.289 48.0837C191.52 47.8641 191.661 47.5653 191.683 47.2466C191.692 47.0937 191.669 46.9406 191.617 46.7967C191.565 46.6528 191.484 46.5212 191.378 46.4101C191.273 46.299 191.147 46.2106 191.006 46.1506C190.865 46.0906 190.714 46.0601 190.561 46.0611Z' fill='white' />
      <path d='M183.02 41.9041H166.789C166.471 41.9085 166.165 42.0329 165.934 42.2525C165.702 42.4721 165.562 42.7709 165.54 43.0897C165.53 43.2425 165.552 43.3958 165.603 43.5399C165.655 43.684 165.736 43.816 165.84 43.9276C165.945 44.0393 166.071 44.1282 166.212 44.1888C166.352 44.2495 166.503 44.2806 166.656 44.2803H182.892C183.211 44.2746 183.516 44.1492 183.747 43.9287C183.979 43.7083 184.119 43.4088 184.141 43.0897C184.151 42.9366 184.129 42.7832 184.077 42.6389C184.025 42.4947 183.944 42.3628 183.838 42.2515C183.733 42.1402 183.606 42.0519 183.465 41.9921C183.324 41.9324 183.173 41.9024 183.02 41.9041Z' fill='white' />
      <path d='M177.93 63.2744H165.642C165.489 63.2747 165.338 63.2435 165.198 63.1829C165.058 63.1222 164.931 63.0333 164.827 62.9217C164.722 62.8101 164.641 62.6781 164.59 62.5339C164.538 62.3898 164.516 62.2366 164.526 62.0837C164.547 61.7646 164.687 61.4651 164.919 61.2452C165.15 61.0253 165.456 60.9014 165.775 60.8982H178.063C178.217 60.8951 178.37 60.924 178.513 60.983C178.655 61.0421 178.784 61.1301 178.891 61.2415C178.997 61.3529 179.08 61.4852 179.133 61.6301C179.187 61.7751 179.209 61.9295 179.2 62.0837C179.178 62.4064 179.035 62.7087 178.799 62.9297C178.563 63.1506 178.253 63.2738 177.93 63.2744Z' fill='white' />
      <path d='M189.619 63.2744H182.369C182.216 63.2754 182.064 63.2448 181.923 63.1845C181.782 63.1241 181.655 63.0353 181.55 62.9236C181.445 62.8119 181.364 62.6797 181.312 62.5352C181.26 62.3907 181.238 62.237 181.248 62.0837C181.27 61.765 181.41 61.4662 181.642 61.2466C181.873 61.027 182.179 60.9026 182.497 60.8982H189.752C189.905 60.8972 190.056 60.9277 190.196 60.9878C190.337 61.0479 190.463 61.1363 190.568 61.2475C190.673 61.3587 190.753 61.4904 190.805 61.6342C190.857 61.7781 190.878 61.9311 190.869 62.0837C190.847 62.4029 190.706 62.7023 190.475 62.9228C190.244 63.1433 189.938 63.2687 189.619 63.2744Z' fill='white' />
      <path d='M181.622 67.4263H165.376C165.223 67.4272 165.072 67.3968 164.931 67.3367C164.791 67.2767 164.664 67.1884 164.559 67.0772C164.454 66.9661 164.373 66.8345 164.32 66.6906C164.268 66.5467 164.246 66.3936 164.255 66.2407C164.277 65.922 164.417 65.6232 164.649 65.4036C164.88 65.184 165.185 65.0596 165.504 65.0552H181.76C181.913 65.0542 182.064 65.0847 182.205 65.1447C182.345 65.2048 182.472 65.2931 182.577 65.4042C182.682 65.5154 182.764 65.647 182.816 65.7908C182.868 65.9347 182.89 66.0879 182.881 66.2407C182.859 66.5612 182.717 66.8615 182.484 67.0813C182.25 67.3012 181.942 67.4244 181.622 67.4263Z' fill='white' />
      <path d='M161.28 122.078H133.632C133.417 122.084 133.203 122.043 133.005 121.96C132.806 121.877 132.627 121.753 132.48 121.596C132.332 121.44 132.219 121.254 132.147 121.05C132.076 120.847 132.048 120.631 132.065 120.416C132.096 119.969 132.293 119.55 132.617 119.242C132.942 118.934 133.37 118.76 133.816 118.753H161.464C161.679 118.748 161.893 118.788 162.092 118.871C162.29 118.954 162.469 119.078 162.617 119.235C162.764 119.392 162.878 119.578 162.949 119.781C163.02 119.985 163.048 120.201 163.031 120.416C163.001 120.863 162.805 121.282 162.48 121.591C162.156 121.899 161.727 122.073 161.28 122.078Z' fill='white' />
      <path d='M185.928 122.078H168.96C168.745 122.084 168.531 122.043 168.333 121.96C168.134 121.877 167.955 121.753 167.808 121.596C167.66 121.44 167.547 121.254 167.475 121.05C167.404 120.847 167.376 120.631 167.393 120.416C167.424 119.969 167.621 119.55 167.945 119.242C168.27 118.934 168.698 118.76 169.144 118.753H186.112C186.327 118.748 186.541 118.788 186.739 118.871C186.938 118.954 187.117 119.078 187.264 119.235C187.412 119.392 187.525 119.578 187.597 119.781C187.668 119.985 187.696 120.201 187.679 120.416C187.648 120.862 187.451 121.281 187.127 121.589C186.802 121.897 186.374 122.072 185.928 122.078Z' fill='white' />
      <path d='M154.368 127.878H133.325C133.11 127.883 132.896 127.843 132.698 127.759C132.499 127.676 132.32 127.552 132.172 127.396C132.025 127.239 131.912 127.053 131.84 126.849C131.769 126.646 131.741 126.43 131.758 126.215C131.788 125.768 131.984 125.348 132.309 125.04C132.634 124.732 133.062 124.558 133.509 124.552H154.547C154.762 124.547 154.976 124.587 155.175 124.671C155.373 124.754 155.552 124.877 155.7 125.034C155.847 125.191 155.961 125.377 156.032 125.581C156.103 125.784 156.131 126 156.114 126.215C156.084 126.661 155.888 127.08 155.565 127.388C155.242 127.696 154.814 127.871 154.368 127.878Z' fill='white' />
      <path d='M177.219 133.708H133.007C132.792 133.713 132.579 133.673 132.38 133.59C132.182 133.506 132.003 133.383 131.855 133.226C131.707 133.069 131.594 132.883 131.523 132.68C131.451 132.476 131.423 132.26 131.441 132.045C131.47 131.599 131.666 131.18 131.99 130.872C132.313 130.564 132.74 130.389 133.187 130.382H177.398C177.613 130.377 177.826 130.418 178.025 130.501C178.224 130.584 178.402 130.708 178.55 130.864C178.698 131.021 178.811 131.207 178.882 131.411C178.954 131.614 178.982 131.83 178.964 132.045C178.935 132.491 178.739 132.91 178.415 133.218C178.092 133.526 177.665 133.701 177.219 133.708Z' fill='white' />
      <path d='M171.587 84.0493H164.48C164.161 84.0537 163.856 84.1781 163.625 84.3977C163.393 84.6173 163.253 84.9162 163.231 85.2349C163.222 85.3877 163.244 85.5409 163.296 85.6847C163.349 85.8286 163.43 85.9602 163.535 86.0714C163.64 86.1825 163.767 86.2708 163.907 86.3309C164.048 86.3909 164.199 86.4214 164.352 86.4204H171.459C171.777 86.416 172.083 86.2916 172.314 86.072C172.545 85.8524 172.686 85.5536 172.708 85.2349C172.717 85.082 172.695 84.9289 172.642 84.785C172.59 84.6411 172.509 84.5095 172.404 84.3984C172.299 84.2872 172.172 84.1989 172.031 84.1389C171.891 84.0788 171.739 84.0484 171.587 84.0493Z' fill='white' />
      <path d='M184.264 84.0494H176.026C175.707 84.0538 175.402 84.1781 175.17 84.3978C174.939 84.6174 174.798 84.9162 174.776 85.2349C174.767 85.3878 174.789 85.5409 174.842 85.6848C174.894 85.8287 174.975 85.9603 175.08 86.0714C175.186 86.1825 175.312 86.2709 175.453 86.3309C175.593 86.3909 175.745 86.4214 175.898 86.4204H184.136C184.454 86.416 184.76 86.2917 184.991 86.0721C185.223 85.8524 185.363 85.5536 185.385 85.2349C185.395 85.0819 185.373 84.9284 185.321 84.7842C185.269 84.64 185.188 84.5081 185.082 84.3968C184.977 84.2855 184.85 84.1972 184.709 84.1374C184.568 84.0776 184.417 84.0477 184.264 84.0494Z' fill='white' />
      <path d='M180.941 79.8924H164.71C164.391 79.8956 164.085 80.0195 163.854 80.2394C163.622 80.4592 163.482 80.7588 163.461 81.0779C163.451 81.2307 163.473 81.384 163.525 81.5281C163.576 81.6723 163.657 81.8042 163.762 81.9159C163.866 82.0275 163.993 82.1164 164.133 82.1771C164.273 82.2377 164.425 82.2689 164.577 82.2685H180.813C181.132 82.2629 181.437 82.1374 181.669 81.9169C181.9 81.6965 182.04 81.3971 182.062 81.0779C182.071 80.925 182.049 80.7719 181.997 80.628C181.944 80.4841 181.863 80.3525 181.758 80.2414C181.653 80.1303 181.526 80.0419 181.386 79.9819C181.245 79.9219 181.094 79.8914 180.941 79.8924Z' fill='white' />
      <path d='M91.136 49.4226C89.4617 50.8134 87.7312 52.3274 86.1389 53.8465C85.3401 54.6112 84.5619 55.3862 83.84 56.1868C83.1401 56.9392 82.4982 57.7438 81.92 58.5938L81.8227 58.7375L81.7766 58.8196C81.7345 58.8845 81.6985 58.9532 81.6691 59.0249C81.5974 59.184 81.5309 59.3841 81.4592 59.5945C81.3228 60.061 81.2167 60.5359 81.1417 61.0161C80.982 62.0876 80.8795 63.1669 80.8345 64.2494C80.727 66.4665 80.727 68.7811 80.768 71.0444L78.3718 71.4036C77.7717 69.0818 77.3607 66.7149 77.143 64.3264C77.0287 63.0862 76.9996 61.8395 77.056 60.5953C77.0968 59.9232 77.1823 59.2545 77.312 58.5938C77.3836 58.2213 77.4811 57.8543 77.6038 57.4955C77.6806 57.2953 77.7369 57.1106 77.8393 56.9001L77.9827 56.5922C78.0339 56.4998 78.0697 56.4434 78.1107 56.3613C78.7375 55.2258 79.4695 54.1518 80.2969 53.1537C81.0638 52.1957 81.8789 51.2775 82.7392 50.4028C84.4242 48.6735 86.2237 47.0601 88.1254 45.5735L91.136 49.4226Z' fill='#B55B52' />
      <path d='M90.2401 43.2692C87.6186 44.8089 83.6558 48.8119 83.6558 48.8119L88.5146 54.6575C88.5146 54.6575 95.4113 50.5107 96.251 48.2269C97.6538 44.3829 92.7182 41.7963 90.2401 43.2692Z' fill='#F3CC04' />
      <path d='M80.6605 70.1769L81.2544 73.6514L78.034 73.1844C78.034 73.1844 77.3735 70.8236 79.058 70.0127L80.6605 70.1769Z' fill='#B55B52' />
      <path d='M80.0666 75.7658L77.1687 74.8625L78.034 73.1843L81.2545 73.6514L80.0666 75.7658Z' fill='#B55B52' />
      <path d='M109.732 30.9929C109.732 31.347 109.926 31.6344 110.162 31.6395C110.398 31.6447 110.577 31.3573 110.572 31.0083C110.566 30.6593 110.377 30.3668 110.142 30.3616C109.906 30.3565 109.727 30.6439 109.732 30.9929Z' fill='#263238' />
      <path d='M110.039 30.3669L110.894 30.136C110.894 30.136 110.464 30.8032 110.039 30.3669Z' fill='#263238' />
      <path d='M110.034 31.342C110.363 32.1551 110.801 32.9199 111.334 33.6156C111.152 33.7408 110.944 33.8248 110.726 33.862C110.507 33.8992 110.284 33.8887 110.07 33.8311L110.034 31.342Z' fill='#A02724' />
      <path d='M110.177 28.6475C110.144 28.6575 110.108 28.6575 110.075 28.6475C109.823 28.5552 109.552 28.5234 109.286 28.5545C109.019 28.5857 108.763 28.6791 108.539 28.8271C108.517 28.8441 108.492 28.8565 108.466 28.8637C108.439 28.8708 108.412 28.8725 108.384 28.8687C108.357 28.8649 108.331 28.8556 108.307 28.8414C108.284 28.8272 108.263 28.8084 108.247 28.786C108.214 28.7417 108.2 28.6861 108.208 28.6314C108.215 28.5766 108.244 28.5271 108.288 28.4935C108.565 28.3085 108.882 28.1905 109.212 28.1486C109.543 28.1068 109.878 28.1423 110.193 28.2523C110.245 28.2699 110.287 28.3071 110.312 28.356C110.337 28.4049 110.342 28.4616 110.326 28.514C110.313 28.5464 110.293 28.5754 110.267 28.5986C110.242 28.6218 110.211 28.6385 110.177 28.6475Z' fill='#263238' />
      <path d='M99.2973 34.76C99.5328 37.4133 98.6471 41.7603 96.6503 43.2383C96.6503 43.2383 98.1555 46.0302 102.979 46.682C108.288 47.3954 105.795 44.4033 105.795 44.4033C102.973 43.3256 103.27 41.1957 103.987 39.2712L99.2973 34.76Z' fill='#B55B52' />
      <path d='M108.723 46.4663C111.247 47.154 113.9 47.8161 116.501 48.4063C117.811 48.6885 119.122 48.9195 120.433 49.2018C121.743 49.484 123.059 49.6636 124.365 49.833L124.877 49.8997L125.215 49.9357C125.45 49.9357 125.727 49.9357 125.983 49.9357C126.578 49.8941 127.17 49.8135 127.754 49.6944C129.022 49.4321 130.275 49.1014 131.507 48.7039C134.031 47.9136 136.591 46.959 139.095 45.9736L140.232 48.1137C137.872 49.6619 135.395 51.0226 132.823 52.1835C131.493 52.7861 130.125 53.3005 128.727 53.7232C127.98 53.9455 127.219 54.117 126.449 54.2364C126.024 54.2944 125.597 54.3304 125.169 54.3442H124.472L123.96 54.3031C121.141 54.1074 118.336 53.7337 115.564 53.1843C112.799 52.6681 110.065 52.0034 107.372 51.193L108.723 46.4663Z' fill='#B55B52' />
      <path d='M138.429 46.8412L139.172 44.3213L141.901 45.3477C141.901 45.3477 141.276 49.1352 138.936 48.0883L138.429 46.8412Z' fill='#B55B52' />
      <path d='M140.754 42.6482L143.539 42.9613L141.901 45.3477L139.172 44.3213L140.754 42.6482Z' fill='#B55B52' />
      <path d='M91.4637 169.381L95.8516 169.705L97.1572 159.605L92.7693 159.281L91.4637 169.381Z' fill='#B55B52' />
      <path d='M59.1053 149.802L62.0852 153.118L70.4103 147.349L67.4305 144.039L59.1053 149.802Z' fill='#B55B52' />
      <path d='M64.2919 154.996C64.2392 155.003 64.1859 155.003 64.1332 154.996C63.6212 154.821 63.2372 153.831 63.0682 153.256C63.0626 153.237 63.0629 153.216 63.0691 153.196C63.0753 153.177 63.0871 153.16 63.103 153.147C63.1189 153.135 63.1382 153.127 63.1585 153.125C63.1787 153.124 63.199 153.128 63.2167 153.138C63.3754 153.22 64.7886 153.99 64.8346 154.555C64.8396 154.616 64.8286 154.678 64.8025 154.733C64.7765 154.789 64.7364 154.837 64.6862 154.873C64.5725 154.957 64.4335 155.001 64.2919 154.996ZM63.3447 153.42C63.6007 154.18 63.913 154.678 64.1998 154.77C64.2619 154.788 64.3276 154.789 64.3904 154.774C64.4533 154.759 64.511 154.727 64.5582 154.683C64.581 154.668 64.5996 154.647 64.6121 154.623C64.6247 154.599 64.6308 154.572 64.6298 154.544C64.6042 154.247 63.9028 153.744 63.3447 153.42Z' fill='#F3CC04' />
      <path d='M64.4609 153.626C63.9929 153.616 63.5328 153.502 63.1143 153.292C63.0989 153.282 63.0862 153.268 63.0773 153.252C63.0684 153.236 63.0635 153.218 63.0631 153.2C63.0627 153.181 63.0679 153.163 63.0779 153.147C63.0879 153.132 63.1024 153.12 63.1195 153.112C63.1604 153.087 64.2459 152.486 64.8449 152.692C64.9231 152.717 64.9948 152.758 65.055 152.814C65.1152 152.87 65.1623 152.939 65.1931 153.015C65.2341 153.088 65.2484 153.173 65.2334 153.256C65.2185 153.338 65.1752 153.413 65.1111 153.467C64.9183 153.591 64.6893 153.647 64.4609 153.626ZM63.3959 153.2C63.9079 153.41 64.7476 153.508 64.978 153.313C65.0139 153.277 65.0548 153.225 64.978 153.097C64.959 153.048 64.9294 153.004 64.8913 152.967C64.8533 152.931 64.8077 152.903 64.7579 152.887C64.2826 152.849 63.8074 152.958 63.3959 153.2Z' fill='#F3CC04' />
      <path d='M63.4215 152.63L60.9639 148.258C60.9235 148.182 60.8571 148.124 60.777 148.094C60.697 148.063 60.6088 148.063 60.5287 148.093L56.8986 149.489C56.8058 149.53 56.7228 149.591 56.6553 149.667C56.5878 149.743 56.5373 149.833 56.5073 149.93C56.4772 150.027 56.4682 150.13 56.481 150.231C56.4937 150.332 56.5279 150.429 56.5812 150.516C57.4669 152.025 57.9636 152.707 59.0439 154.621C59.7095 155.802 60.7335 158.383 61.522 160.082C62.3104 161.781 63.9796 161.108 63.8004 160.339C63.1983 158.081 63.1142 155.716 63.5546 153.42C63.604 153.15 63.5569 152.87 63.4215 152.63Z' fill='#263238' />
      <path d='M97.7613 169.848C97.2968 169.839 96.8341 169.788 96.3789 169.694C96.3581 169.69 96.3393 169.679 96.3247 169.663C96.3101 169.648 96.3005 169.628 96.297 169.607C96.2937 169.586 96.2973 169.563 96.3075 169.544C96.3176 169.525 96.3337 169.509 96.3533 169.499C96.553 169.397 98.3347 168.473 98.8621 168.796C98.9118 168.824 98.9535 168.864 98.9831 168.913C99.0127 168.961 99.0293 169.017 99.031 169.073C99.0408 169.169 99.0273 169.266 98.9917 169.355C98.956 169.445 98.8992 169.524 98.8262 169.587C98.5105 169.791 98.1355 169.883 97.7613 169.848ZM96.7373 169.546C97.705 169.705 98.4269 169.664 98.7034 169.422C98.7497 169.381 98.7855 169.329 98.8077 169.271C98.83 169.213 98.8381 169.151 98.8314 169.089C98.8319 169.066 98.8266 169.044 98.8158 169.024C98.805 169.004 98.7892 168.988 98.7699 168.976C98.4678 168.817 97.4592 169.197 96.727 169.546H96.7373Z' fill='#F3CC04' />
      <path d='M96.3994 169.694C96.3794 169.701 96.3579 169.701 96.3379 169.694C96.3229 169.685 96.311 169.671 96.3037 169.654C96.2964 169.638 96.2941 169.62 96.297 169.602C96.297 169.52 96.5069 167.724 97.4899 167.724H97.577C97.8586 167.749 97.9405 167.893 97.961 168.011C98.0429 168.524 96.9779 169.463 96.425 169.7C96.4162 169.699 96.4075 169.698 96.3994 169.694ZM97.495 167.908C96.8602 167.908 96.6093 168.935 96.5325 169.402C97.0803 169.073 97.8022 168.329 97.7562 168.026C97.7562 168.026 97.7562 167.934 97.5565 167.914L97.495 167.908Z' fill='#F3CC04' />
      <path d='M96.1281 169.079H91.1924C91.1066 169.079 91.0236 169.109 90.958 169.164C90.8924 169.22 90.8485 169.297 90.834 169.381L90.1735 173.292C90.1604 173.39 90.1683 173.49 90.1968 173.584C90.2252 173.679 90.2736 173.767 90.3386 173.841C90.4036 173.915 90.4837 173.975 90.5736 174.016C90.6634 174.056 90.761 174.077 90.8596 174.077C92.5799 174.047 93.4196 173.944 95.5802 173.944C96.9114 173.944 99.6763 174.082 101.514 174.082C103.352 174.082 103.501 172.261 102.738 172.091C99.3179 171.352 97.9867 170.326 96.8551 169.351C96.6523 169.177 96.3949 169.081 96.1281 169.079Z' fill='#263238' />
      <path opacity='0.2' d='M97.1572 159.61L96.4301 165.224L92.0935 164.496L92.7642 159.281L97.1572 159.61Z' fill='black' />
      <path opacity='0.2' d='M67.4254 144.039L70.4052 147.354L66.9646 149.736L63.8772 146.497L67.4254 144.039Z' fill='black' />
      <path d='M89.2519 69.7458L107.008 72.1374C113.894 47.1489 111.862 46.4766 111.862 46.4766C110.531 45.852 109.163 45.3104 107.766 44.8548L107.525 44.7779C106.378 44.4289 106.138 44.3827 104.607 44.0337C101.98 43.5667 99.5431 43.2074 96.5325 42.9508C96.3431 42.9508 96.1485 42.9149 95.9591 42.9046C95.4164 42.8687 94.8788 42.843 94.3565 42.8225C94.0084 42.8225 93.6551 42.8225 93.3325 42.8225H91.602C91.2595 42.8302 90.9224 42.9095 90.6122 43.0552C90.3021 43.201 90.0256 43.4099 89.8006 43.6688C89.5756 43.9277 89.4069 44.2308 89.3054 44.5587C89.2038 44.8867 89.1717 45.2322 89.2109 45.5733C90.7316 58.6963 90.2503 63.0227 89.2519 69.7458Z' fill='#F3CC04' />
      <path opacity='0.2' d='M107.52 52.1272C107.643 53.2871 110.316 55.4067 111.242 54.837C111.509 53.4462 111.703 52.2709 111.841 51.3266C110.5 51.2239 107.873 49.7408 107.52 52.1272Z' fill='black' />
      <path d='M104.637 46.2766C104.509 42.1709 114.437 46.6923 116.716 47.7136L114.176 54.5958C114.176 54.5958 110.853 54.4829 108.739 53.2563C107.536 52.52 106.532 51.498 105.817 50.2806C105.102 49.0633 104.697 47.6881 104.637 46.2766Z' fill='#F3CC04' />
      <path d='M97.792 29.8535C98.1197 34.1953 98.1658 36.0378 100.408 38.2959C103.772 41.6883 109.071 40.4874 110.054 36.1712C110.94 32.2862 110.254 25.8402 105.958 24.0542C105.036 23.6402 104.022 23.4744 103.017 23.5732C102.011 23.6719 101.048 24.0318 100.224 24.6174C99.3993 25.2029 98.7413 25.9938 98.3149 26.9121C97.8884 27.8303 97.7082 28.8442 97.792 29.8535Z' fill='#B55B52' />
      <path d='M98.171 25.2706C93.819 24.1775 93.6807 27.8367 93.7729 29.7664C93.865 31.6961 90.6651 32.6199 90.6651 35.0371C90.6651 38.1883 93.0356 40.0666 87.2705 41.0212C82.3911 41.827 80.8859 47.9394 88.1051 51.5165C96.0974 55.4682 108.155 49.905 106.691 44.6137C104.448 36.4485 108.478 36.4741 108.032 31.9065C107.699 28.5911 110.833 28.4269 109.706 25.6607C108.222 22.0425 97.7921 18.8657 98.171 25.2706Z' fill='#263238' />
      <path d='M93.1328 31.3933C92.5338 28.2319 92.8614 26.138 94.1056 25.1578C95.7082 23.8901 98.1709 24.9884 98.2733 25.0346L98.0634 25.5016C98.0634 25.5016 95.7747 24.4752 94.4179 25.5581C93.3376 26.4151 93.0765 28.3448 93.6346 31.2958L93.1328 31.3933Z' fill='#263238' />
      <path d='M88.2125 40.1179C87.0888 40.0824 85.9792 39.8568 84.9305 39.4507L85.12 38.9734C85.1558 38.9734 88.6067 40.3488 90.2041 39.0196C90.9824 38.373 91.2025 37.1618 90.8646 35.4271L91.3766 35.3296C91.7555 37.2747 91.4739 38.6552 90.5421 39.4353C89.8684 39.9275 89.0446 40.1689 88.2125 40.1179Z' fill='#263238' />
      <path d='M106.849 31.6038C106.808 32.4137 107.017 33.2167 107.448 33.903C108.042 34.873 108.846 34.6933 109.107 33.8722C109.368 33.0511 109.322 31.7321 108.518 31.029C107.715 30.3259 106.931 30.7057 106.849 31.6038Z' fill='#B55B52' />
      <path d='M102.334 71.5062C102.334 71.5062 95.4317 100.759 92.2062 117.536C88.8065 135.258 68.6541 149.715 68.6541 149.715L64.2305 145.153C64.2305 145.153 77.2046 133.769 80.5377 117.223C87.3012 83.6386 82.7239 76.8744 89.2417 69.7458L102.334 71.5062Z' fill='#263238' />
      <path d='M69.033 150.372L63.5085 144.676L65.0599 143.002L71.4753 148.663L69.033 150.372Z' fill='#F3CC04' />
      <path opacity='0.2' d='M98.6676 77.8598C93.3018 82.5301 93.8804 99.1018 94.4436 106.569C96.6912 95.9507 99.3997 84.1262 100.987 77.285C100.419 76.8642 99.6506 77.0027 98.6676 77.8598Z' fill='black' />
      <path d='M106.762 72.1529C106.762 72.1529 109.865 106.862 108.17 120.103C106.409 133.872 97.4182 163.834 97.4182 163.834L91.136 163.018C91.136 163.018 96.256 133.323 97.1929 119.692C98.1862 104.809 93.7677 70.4233 93.7677 70.4233L106.762 72.1529Z' fill='#263238' />
      <path d='M98.7905 164.198L89.769 163.028L89.9073 160.359L99.6967 161.309L98.7905 164.198Z' fill='#F3CC04' />
      <path d='M89.088 68.6734L88.1152 70.1463C88.0435 70.2643 88.1766 70.408 88.3814 70.4337L107.008 72.9638C107.167 72.9638 107.31 72.9228 107.331 72.8253L107.658 71.2856C107.684 71.1779 107.551 71.0649 107.377 71.0393L89.3798 68.622C89.3308 68.6052 89.2783 68.6011 89.2273 68.6101C89.1763 68.6191 89.1284 68.6408 89.088 68.6734Z' fill='#F3CC04' />
      <path opacity='0.3' d='M89.088 68.6734L88.1152 70.1463C88.0435 70.2643 88.1766 70.408 88.3814 70.4337L107.008 72.9638C107.167 72.9638 107.31 72.9228 107.331 72.8253L107.658 71.2856C107.684 71.1779 107.551 71.0649 107.377 71.0393L89.3798 68.622C89.3308 68.6052 89.2783 68.6011 89.2273 68.6101C89.1763 68.6191 89.1284 68.6408 89.088 68.6734Z' fill='black' />
      <path d='M91.2999 70.9982L90.8187 70.9366C90.7214 70.9366 90.6548 70.8648 90.6702 70.8032L91.1105 68.776C91.1105 68.7195 91.2129 68.6836 91.3102 68.6939L91.7915 68.7606C91.8887 68.7606 91.9553 68.8325 91.9451 68.8889L91.4996 70.9161C91.4894 70.9777 91.3972 71.0136 91.2999 70.9982Z' fill='#263238' />
      <path d='M103.475 72.666L102.994 72.5993C102.897 72.5993 102.83 72.5274 102.84 72.471L103.286 70.4438C103.286 70.3873 103.388 70.3463 103.486 70.3617L103.967 70.4233C104.064 70.4233 104.131 70.4951 104.115 70.5567L103.675 72.5839C103.66 72.6403 103.573 72.6814 103.475 72.666Z' fill='#263238' />

    </SvgIcon>
  )
}
