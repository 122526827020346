import { Box, Button, Typography } from '@mui/material'
import { IconCheck, IconX } from '@tabler/icons-react'
import { useContract } from '~/contexts'

export type ActionButtonsProps = {
  toggleModalCancel: () => void
  toggleModalUnify: () => void
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ toggleModalCancel, toggleModalUnify }) => {
  const { selectedContract } = useContract()
  const isActive = selectedContract?.singleAccount?.active
  
  return (
    <Box sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'start'
    }}>
      {
        isActive?
          <Button startIcon={<IconX color='#EF4444' />} variant={'outlined'} color={'error'} onClick={toggleModalCancel}>
            <Typography variant='body1' fontWeight={500}>{'Desativar conta única'}</Typography>
          </Button> :
          <Button startIcon={<IconCheck color='#434343' /> } variant={'contained'} color={'primary'} onClick={toggleModalUnify}>
            <Typography variant='body1' fontWeight={500}>{'Ativar conta única'}</Typography>
          </Button>
      }
     
    </Box>
  )
}
