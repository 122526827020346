import { useEffect, useMemo, useState } from 'react'
import { IconCopy, IconDownload, IconEye } from '@tabler/icons-react'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { useToggle } from '~/hooks'
import { useContract } from '~/contexts'
import { copyToClipboard } from '~/utils/clipboard'
import { BarcodeIcon, PixIcon } from '~/assets/icons'
import { InvoicePaymentType } from '../PaymentDrawer'
import { InvoicePaymentModel, useListAllInvoicesCustomerUserMutation } from '~/graphql/types'
import { ECustomerInvoiceStatus, formatDate, formatMoney, theme, transformMoney } from '~/utils'
import { Card, Table, TableHeaderApi, PageLoader, InvoiceStatus, PaymentDrawer } from '~/components'

type PaymentInfo = {
  type: InvoicePaymentType
  paymentInfo: InvoicePaymentModel & { amountCents: number }
}

export const InvoiceHistory: React.FC = () => {
  const [invoiceData, setInvoiceData] = useState<PaymentInfo | null>(null)
  const { selectedContract } = useContract()
  const { isTrue: isOpen, toggle: toggleDrawer } = useToggle()
  const [getListInvoices, { loading: listInvoicesIsLoading, data }] = useListAllInvoicesCustomerUserMutation({
    variables: {
      filters: {
        contractId: selectedContract?._id
      }
    }
  })

  // const [users] = useState([{ createdAt: new Date(), venciment: new Date(), accountCust: 1230, status: 'ABC', _id: '12312312' }])
  // const paginationData = useApiPagination({ pageSize: 20, currentPage: 0 })
  // const [filterName] = useState('')
  // const debouncedFilterName = useDebounce(filterName, 1000)
  // useEffect(() => {
  //   paginationData.setFiltersTable({ name: debouncedFilterName })
  //   paginationData.setMetadata((prev) => ({ ...prev, currentPage: 0 }))
  // }, [debouncedFilterName])

  useEffect(() => {
    getListInvoices()

    return () => setInvoiceData(null)
  }, [])

  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Data',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{formatDate(value)}</>
      },
      {
        Header: 'Vencimento',
        accessor: 'dueDate',
        Cell: ({ value }) => <>{formatDate(value) || 'Indisponível'}</>
      },
      {
        Header: 'Pagamento',
        accessor: 'paymentDate',
        Cell: ({ value }) => <>{formatDate(value) || '-'}</>
      },
      {
        Header: () => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: '#999' }}>
            <PixIcon width={16} height={16} />
            Pix
          </Box>
        ),
        accessor: 'pix',
        Cell: ({ row: { original } }: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#999' }}>
            <IconButton
              disabled={original.status === ECustomerInvoiceStatus.paid || original.status === ECustomerInvoiceStatus.canceled || original?.payment === null}
              onClick={() => copyToClipboard(original?.payment?.pixText as any)}
            >
              <IconCopy />
            </IconButton>
            <IconButton
              disabled={original.status === ECustomerInvoiceStatus.paid || original.status === ECustomerInvoiceStatus.canceled || original?.payment === null}
              onClick={() => {
                setInvoiceData({ paymentInfo: { ...original?.payment, amountCents: original?.amountCents }, type: 'pix' } as PaymentInfo)
                toggleDrawer()
              }}><IconEye /></IconButton>
          </Box>
        )
      },
      {
        Header: () => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: '#999' }}>
            <BarcodeIcon width={16} height={16} />
            Cod.Barras
          </Box>
        ),
        accessor: 'barcode',
        Cell: ({ row: { original } }: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#999' }}>
            <IconButton
              disabled={original.status === ECustomerInvoiceStatus.paid || original.status === ECustomerInvoiceStatus.canceled || original?.payment === null}
              onClick={() => copyToClipboard(original?.payment?.digitableLine as any)}
            >
              <IconCopy />
            </IconButton>
            <IconButton
              disabled={original.status === ECustomerInvoiceStatus.paid || original.status === ECustomerInvoiceStatus.canceled || original?.payment === null}
              onClick={() => {
                setInvoiceData({ paymentInfo: original?.payment, type: 'barcode' } as PaymentInfo)
                toggleDrawer()
              }}><IconEye /></IconButton>
          </Box>
        )
      },
      {
        Header: 'Valor da fatura',
        accessor: 'amountCents',
        Cell: ({ value }) => <div style={{ height: '53px', display: 'flex', alignItems: 'center' }}>{formatMoney(transformMoney(value, 'toReal'))}</div>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <><InvoiceStatus status={value} /></>
      },
      {
        accessor: '_id',
        Cell: ({ value }) => (
          <a target='_blank' download href={`${import.meta.env.VITE_APP_REST_API_URL}customer-area/download/invoice/${value}`} rel='noreferrer'>
            <IconButton>
              <IconDownload />
            </IconButton>
          </a>
        )
      },
    ]
  }, [toggleDrawer])

  const dataWithMemo = useMemo(() => data?.listAllInvoicesCustomerUser.data || [], [data?.listAllInvoicesCustomerUser.data])
  const defaultColumnHiddens = isLowerMd ? ['venciment'].concat(isLowerSm ? ['accountCust'] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    },
    defaultColumn: {
      Filter: <></>
    },
  },
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  return (
    <>
      <Card sx={{ marginTop: '2rem', padding: '0 2rem' }}>

        {listInvoicesIsLoading ?
          <PageLoader />
          : (
            <>
              <TableHeaderApi
                styles={{ hasBorderBottom: true, hasBorderTop: false }}
                startElement={(
                  <>
                    <Typography color={theme.palette.grey['800']} fontSize='1.6rem' variant='h6' >Histórico de faturas</Typography>
                  </>
                )}
                // endElement={
                //   (
                //     <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}> 
                //       <Button color='secondary'>Filtros</Button>
                //     </Box>

              //   )
              // }
              />

              <Table
                tableLayout={isLowerSm ? 'auto' : 'fixed'}
                // isLoading={listInvoicesIsLoading}
                // paginationData={dataWithMemo}
                tableMethods={tableMethods}
                columns={columns}
              />
            </>
          )}
      </Card>

      {invoiceData && (
        <PaymentDrawer
          isOpen={isOpen}
          toggle={toggleDrawer}
          paymentType={invoiceData?.type}
          paymentInfo={invoiceData?.paymentInfo}
        />
      )}
    </>
  )
}
