
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import { theme, formatMoney, transformMoney } from '~/utils';

interface InfoModalCashbackProps {
  customerInvoiceInfo: {
    balanceCashbackAfterInvoicedPaidCents: number;
    balanceCashbackCents: number;
    invoiceId: string;
    oldInvoiceValueCents: number;
  } | undefined
  setAuthenticantionIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggle: () => void;
}

export function InfoModalCashback({ customerInvoiceInfo, setAuthenticantionIsOpen, toggle }: InfoModalCashbackProps) {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box>
      <Divider sx={{ margin: '0 0 1.6rem 0' }} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', padding: '0 0 .6rem 0', flexWrap: 'wrap' }}>
        <Typography sx={{ color: theme.palette.grey['600'] }}>Seu saldo atual na carteira é de</Typography>
        <Typography>{formatMoney(transformMoney(customerInvoiceInfo?.balanceCashbackCents || 0, 'toReal'))}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', padding: '0 0 .6rem 0', flexWrap: 'wrap' }}>
        <Typography sx={{ color: theme.palette.grey['600'] }}>Sua fatura mais antiga em aberto tem o valor de</Typography>
        <Typography>
          {formatMoney(transformMoney((customerInvoiceInfo?.oldInvoiceValueCents || 0), 'toReal'))}
          {/* - {lastInvoice?.dueDate} */}
        </Typography>
      </Box>

      <Typography sx={{ color: theme.palette.grey['600'], padding: '1rem 0 0 0' }}>Confirmar ação?</Typography>
      <Typography sx={{ color: theme.palette.grey['600'], padding: ' 0', fontSize: '12px' }}>
        Seu saldo pós pagamento será de {formatMoney(transformMoney((customerInvoiceInfo?.balanceCashbackAfterInvoicedPaidCents || 0), 'toReal'))}
        {/* {formatMoney(transformMoney((balance - lastInvoice), 'toReal'))} */}
      </Typography>

      <Divider sx={{ margin: '2rem 0' }} />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Button onClick={() => {
          toggle();
          setAuthenticantionIsOpen(false)
        }} color='secondary'>Cancelar</Button>
        <Button onClick={() => {
          setAuthenticantionIsOpen(true)
        }}

        >
          Confirmar
        </Button>
      </Box>
    </Box>
  );
}
