import styled from '@emotion/styled'
import { darken } from 'polished'
import { CSSProperties } from 'react'
import { Link } from 'react-router-dom'

type GridColumnProps = {
  columns: number
  gap?: CSSProperties['gap']
  padding?: CSSProperties['padding']
  responsive?: {
    breakWidth: number,
    columns: number
  }[]
}

export const GridColumn = styled.div<GridColumnProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  gap: ${(props) => props.gap ?? '1rem'};
  padding: ${(props) => props.padding ?? '0'};

  ${(props) => props.responsive?.map((item) => `
    @media(max-width: ${item.breakWidth}px) {
      grid-template-columns: repeat(${item.columns}, 1fr);
    }
  `)}
`

export const ButtonWithNoStyle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`

type LinkWithNoStyleProps = {
  color?: string
}
export const LinkWithNoStyle = styled(Link)<LinkWithNoStyleProps>`
  text-decoration: none;
  
  * {
    color: ${(props) => props.color ?? props.theme.palette.info.dark};
    transition: color .2s ease-in-out;

    &:hover {
      color: ${(props) => props.color ? darken('0.15',props.color || 'currentColor') : darken('0.15',props.theme.palette.info.dark)};
    }
  }
`

