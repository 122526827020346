import { AUTH_TOKEN } from '~/constants'

export const storeToken = (token: string) => {
  return window.localStorage.setItem(AUTH_TOKEN, token)
}

export const storeSessionToken = (token: string) => {
  return window.sessionStorage.setItem(AUTH_TOKEN, token)
}

export const getToken = () => {
  return window.localStorage.getItem(AUTH_TOKEN) || window.sessionStorage.getItem(AUTH_TOKEN)
}

export const removeToken = () => {
  window.sessionStorage.removeItem(AUTH_TOKEN)
  return window.localStorage.removeItem(AUTH_TOKEN)
}

