import { Container } from './styles'

type StatusProps = {
  text: string;
  color: string;
  background?: string;
}
export const Status: React.FC<StatusProps> = ({ color, text, background }) => {
  return (
    <Container background={background} color={color}>
      <span>{text}</span>
    </Container>
  )
}
