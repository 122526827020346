import { SvgIcon, SvgIconProps } from '@mui/material'

export const WomanHoldingGiantPencil: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='258' height='180' viewBox='0 0 258 180' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M257.5 156.736H0.5V156.865H257.5V156.736Z' fill='#EBEBEB' />
      <path d='M44.4881 161.64H27.4644V161.769H44.4881V161.64Z' fill='#EBEBEB' />
      <path d='M116.15 161.707H88.466V161.836H116.15V161.707Z' fill='#EBEBEB' />
      <path d='M64.7294 164.298H54.8658V164.427H64.7294V164.298Z' fill='#EBEBEB' />
      <path d='M229.744 164.236H219.86V164.365H229.744V164.236Z' fill='#EBEBEB' />
      <path d='M215.28 164.236H199.711V164.365H215.28V164.236Z' fill='#EBEBEB' />
      <path d='M191.137 163.386H169.262V163.515H191.137V163.386Z' fill='#EBEBEB' />
      <path d='M216.318 6.63013H156.643V78.0408H216.318V6.63013Z' fill='#E6E6E6' />
      <path d='M217.809 6.63013H158.134V78.0408H217.809V6.63013Z' fill='#F5F5F5' />
      <path d='M215.136 75.0269V9.63843L160.811 9.63843V75.0269H215.136Z' fill='white' />
      <path d='M215.131 75.0269V9.63843H214.005V75.0269H215.131Z' fill='#E6E6E6' />
      <path d='M216.699 59.5364H159.239V63.5803H216.699V59.5364Z' fill='#F5F5F5' />
      <path d='M220.723 1.64844H153.729C153.619 1.64844 153.515 1.69186 153.438 1.76915C153.361 1.84643 153.317 1.95126 153.317 2.06056C153.317 2.16986 153.361 2.27469 153.438 2.35198C153.515 2.42926 153.619 2.47268 153.729 2.47268H220.723C220.832 2.47268 220.937 2.42926 221.014 2.35198C221.091 2.27469 221.135 2.16986 221.135 2.06056C221.135 1.95126 221.091 1.84643 221.014 1.76915C220.937 1.69186 220.832 1.64844 220.723 1.64844Z' fill='#E6E6E6' />
      <path d='M221.134 4.12123C222.27 4.12123 223.19 3.19866 223.19 2.06062C223.19 0.922569 222.27 0 221.134 0C219.999 0 219.078 0.922569 219.078 2.06062C219.078 3.19866 219.999 4.12123 221.134 4.12123Z' fill='#E6E6E6' />
      <path d='M157.198 4.36843C157.051 4.36843 156.91 4.30982 156.805 4.20548C156.701 4.10114 156.643 3.95963 156.643 3.81207V2.61691C156.644 2.46977 156.703 2.32904 156.807 2.22499C156.911 2.12094 157.051 2.06189 157.198 2.06055C157.345 2.06055 157.486 2.11916 157.591 2.2235C157.695 2.32784 157.753 2.46936 157.753 2.61691V3.81207C157.753 3.95963 157.695 4.10114 157.591 4.20548C157.486 4.30982 157.345 4.36843 157.198 4.36843Z' fill='#E6E6E6' />
      <path d='M214.514 4.36843C214.367 4.36843 214.226 4.30982 214.122 4.20548C214.018 4.10114 213.959 3.95963 213.959 3.81207V2.61691C213.96 2.46977 214.019 2.32904 214.123 2.22499C214.227 2.12094 214.367 2.06189 214.514 2.06055C214.661 2.06055 214.803 2.11916 214.907 2.2235C215.011 2.32784 215.069 2.46936 215.069 2.61691V3.81207C215.069 3.95963 215.011 4.10114 214.907 4.20548C214.803 4.30982 214.661 4.36843 214.514 4.36843Z' fill='#E6E6E6' />
      <path opacity='0.5' d='M220.59 2.06055H159.239V32.0528H220.59V2.06055Z' fill='#E0E0E0' />
      <path d='M163.818 4.12123C164.954 4.12123 165.874 3.19866 165.874 2.06062C165.874 0.922569 164.954 0 163.818 0C162.683 0 161.762 0.922569 161.762 2.06062C161.762 3.19866 162.683 4.12123 163.818 4.12123Z' fill='#E6E6E6' />
      <path d='M46.6109 85.7112H24.843L26.7705 60.175H44.6834L46.6109 85.7112Z' fill='#EBEBEB' />
      <path d='M41.8359 85.7112H38.094L37.2973 60.175H40.7513L41.8359 85.7112Z' fill='#F5F5F5' />
      <path d='M36.8192 85.7114H34.6399V156.736H36.8192V85.7114Z' fill='#F0F0F0' />
      <path d='M36.552 96.1743H34.9072C34.1066 96.1743 33.4577 96.8247 33.4577 97.6271V102.99C33.4577 103.792 34.1066 104.443 34.9072 104.443H36.552C37.3525 104.443 38.0014 103.792 38.0014 102.99V97.6271C38.0014 96.8247 37.3525 96.1743 36.552 96.1743Z' fill='#F0F0F0' />
      <path d='M36.552 109.424H34.9072C34.1066 109.424 33.4577 110.074 33.4577 110.877V116.239C33.4577 117.042 34.1066 117.692 34.9072 117.692H36.552C37.3525 117.692 38.0014 117.042 38.0014 116.239V110.877C38.0014 110.074 37.3525 109.424 36.552 109.424Z' fill='#F0F0F0' />
      <path d='M39.1734 156.736H32.2806C32.2806 156.345 32.4355 155.97 32.7112 155.694C32.9869 155.417 33.3608 155.262 33.7507 155.262H37.7033C38.0932 155.262 38.4671 155.417 38.7428 155.694C39.0185 155.97 39.1734 156.345 39.1734 156.736Z' fill='#F0F0F0' />
      <path d='M36.8192 85.7114H34.6399V87.0457H36.8192V85.7114Z' fill='#E0E0E0' />
      <path d='M50.0033 64.5229L110.162 64.5229L110.162 4.06446L50.0033 4.06446L50.0033 64.5229Z' fill='#F0F0F0' />
      <path d='M50.0033 4.06445H48.9651V64.5229H50.0033V4.06445Z' fill='#E0E0E0' />
      <path d='M55.3181 60.7007L104.852 60.7007V7.88194L55.3181 7.88194L55.3181 60.7007Z' fill='#FAFAFA' />
      <path d='M77.2351 48.9963H82.9405C88.9813 48.9963 93.8784 44.0883 93.8784 38.0339V30.5487C93.8784 24.4943 88.9813 19.5862 82.9405 19.5862L77.2351 19.5862C71.1942 19.5862 66.2971 24.4943 66.2971 30.5487V38.0339C66.2971 44.0883 71.1942 48.9963 77.2351 48.9963Z' fill='#F5F5F5' />
      <path d='M209.965 87.2104H187.067V156.731H209.965V87.2104Z' fill='#E6E6E6' />
      <path d='M198.786 156.736H209.965V147.138H187.067L198.786 156.736Z' fill='#FAFAFA' />
      <path d='M118.643 87.2104H95.7442V156.731H118.643V87.2104Z' fill='#E6E6E6' />
      <path d='M209.96 87.2104H108.887V153.428H209.96V87.2104Z' fill='#FAFAFA' />
      <path d='M120.067 156.736H108.892V147.138H131.791L120.067 156.736Z' fill='#FAFAFA' />
      <path d='M201.294 111.886H117.563V128.459H201.294V111.886Z' fill='#F0F0F0' />
      <path d='M201.294 132.003H117.563V148.575H201.294V132.003Z' fill='#F0F0F0' />
      <path d='M181.351 113.658H137.502C136.654 113.658 135.84 113.321 135.241 112.72C134.641 112.119 134.304 111.304 134.304 110.454H184.548C184.549 110.875 184.466 111.292 184.306 111.681C184.146 112.07 183.91 112.424 183.613 112.721C183.316 113.019 182.963 113.255 182.575 113.416C182.187 113.577 181.771 113.659 181.351 113.658Z' fill='#FAFAFA' />
      <path d='M201.294 91.7747H117.563V108.347H201.294V91.7747Z' fill='#F0F0F0' />
      <path d='M181.351 93.5468H137.502C136.653 93.5454 135.84 93.2067 135.241 92.605C134.641 92.0032 134.304 91.1877 134.304 90.3374H184.548C184.549 90.7586 184.466 91.1758 184.306 91.5652C184.146 91.9546 183.91 92.3084 183.613 92.6065C183.316 92.9046 182.964 93.1411 182.576 93.3024C182.187 93.4638 181.771 93.5468 181.351 93.5468Z' fill='#FAFAFA' />
      <path d='M181.351 133.775H137.502C136.653 133.774 135.84 133.435 135.241 132.833C134.641 132.232 134.304 131.416 134.304 130.566H184.548C184.549 130.987 184.466 131.404 184.306 131.794C184.146 132.183 183.91 132.537 183.613 132.835C183.316 133.133 182.964 133.37 182.576 133.531C182.187 133.692 181.771 133.775 181.351 133.775Z' fill='#FAFAFA' />
      <path d='M129 180C184.04 180 228.659 177.389 228.659 174.168C228.659 170.948 184.04 168.337 129 168.337C73.9596 168.337 29.3405 170.948 29.3405 174.168C29.3405 177.389 73.9596 180 129 180Z' fill='#F5F5F5' />
      <path d='M39.3327 71.7816H116.042C113.765 71.7534 111.582 70.8677 109.927 69.3005C108.272 67.7333 107.266 65.5994 107.109 63.3228L105.752 40.4087H29.0475L30.3994 63.3228C30.5567 65.5994 31.5629 67.7333 33.218 69.3005C34.8731 70.8677 37.0559 71.7534 39.3327 71.7816Z' fill='#F3CC04' />
      <path opacity='0.6' d='M39.3327 71.7816H116.042C113.765 71.7534 111.582 70.8677 109.927 69.3005C108.272 67.7333 107.266 65.5994 107.109 63.3228L105.752 40.4087H29.0475L30.3994 63.3228C30.5567 65.5994 31.5629 67.7333 33.218 69.3005C34.8731 70.8677 37.0559 71.7534 39.3327 71.7816Z' fill='white' />
      <path d='M121.727 25.1653H53.442C52.3534 25.1524 51.274 25.3662 50.2721 25.7931C49.2703 26.2201 48.3677 26.8508 47.6217 27.6455C46.8757 28.4402 46.3025 29.3814 45.9382 30.4096C45.574 31.4379 45.4268 32.5307 45.5058 33.6189L47.274 63.3279C47.3546 64.4165 47.2085 65.5099 46.8448 66.5389C46.4812 67.5678 45.908 68.5097 45.1617 69.3047C44.4153 70.0997 43.5122 70.7304 42.5096 71.1568C41.507 71.5832 40.4269 71.796 39.3378 71.7816H116.042C117.131 71.796 118.211 71.5832 119.214 71.1568C120.216 70.7304 121.12 70.0997 121.866 69.3047C122.612 68.5097 123.185 67.5678 123.549 66.5389C123.913 65.5099 124.059 64.4165 123.978 63.3279L124.168 66.5424L122.225 33.6395C122.142 32.5493 122.286 31.4536 122.648 30.4222C123.01 29.3908 123.583 28.4463 124.33 27.6491C125.076 26.8518 125.981 26.2192 126.985 25.7915C127.989 25.3639 129.071 25.1506 130.162 25.1653H121.727Z' fill='#F3CC04' />
      <path d='M130.162 25.1653H53.442C55.7186 25.191 57.9021 26.0752 59.5576 27.6418C61.2131 29.2084 62.2191 31.3424 62.3753 33.619L68.9802 145.248H145.684L139.095 33.6396C138.941 31.3602 137.937 29.2225 136.281 27.652C134.626 26.0816 132.441 25.1938 130.162 25.1653Z' fill='#F3CC04' />
      <path opacity='0.8' d='M130.162 25.1653H53.442C55.7186 25.191 57.9021 26.0752 59.5576 27.6418C61.2131 29.2084 62.2191 31.3424 62.3753 33.619L68.9802 145.248H145.684L139.095 33.6396C138.941 31.3602 137.937 29.2225 136.281 27.652C134.626 26.0816 132.441 25.1938 130.162 25.1653Z' fill='white' />
      <path d='M88.2192 56.9399H74.002L73.1436 42.6804H87.366L88.2192 56.9399ZM75.544 55.2605H86.4254L85.824 44.3547H74.9221L75.544 55.2605Z' fill='white' />
      <path d='M90.2033 90.4817H75.9706L75.1277 76.217H89.3603L90.2033 90.4817ZM77.5435 88.8023H88.4248L87.7823 77.8964H76.901L77.5435 88.8023Z' fill='white' />
      <path d='M92.1822 124.023H77.9495L77.086 109.759H91.3186L92.1822 124.023ZM79.5275 122.344H90.4089L89.7612 111.438H78.8798L79.5275 122.344Z' fill='white' />
      <path d='M127.89 42.6753H95.1685V44.3547H127.89V42.6753Z' fill='white' />
      <path d='M124.415 48.9705H95.5386V50.6499H124.415V48.9705Z' fill='white' />
      <path d='M128.63 55.2605H95.9087V56.9399H128.63V55.2605Z' fill='white' />
      <path d='M129.869 76.217H97.1474V77.8964H129.869V76.217Z' fill='white' />
      <path d='M116.726 82.5122H97.5175V84.1916H116.726V82.5122Z' fill='white' />
      <path d='M107.808 88.8022H97.8927V90.4816H107.808V88.8022Z' fill='white' />
      <path d='M130.614 88.8022H114.253V90.4816H130.614V88.8022Z' fill='white' />
      <path d='M131.848 109.759H99.1263V111.438H131.848V109.759Z' fill='white' />
      <path d='M132.223 116.054H99.5015V117.733H132.223V116.054Z' fill='white' />
      <path d='M117.97 122.344H99.8716V124.023H117.97V122.344Z' fill='white' />
      <path d='M82.0358 53.581C81.6802 53.5814 81.3379 53.445 81.0798 53.1998L77.6 49.8925C77.467 49.7664 77.3601 49.6152 77.2855 49.4476C77.2108 49.2801 77.1698 49.0994 77.1648 48.9159C77.1546 48.5454 77.2918 48.1861 77.546 47.9169C77.8003 47.6478 78.1508 47.4909 78.5204 47.4807C78.8901 47.4706 79.2487 47.608 79.5172 47.8628L81.9947 50.2068L87.5716 44.2671C87.8251 43.9972 88.1753 43.8394 88.5449 43.8283C88.9146 43.8172 89.2735 43.9537 89.5428 44.2078C89.812 44.4619 89.9695 44.8129 89.9806 45.1834C89.9917 45.5539 89.8554 45.9136 89.6019 46.1834L83.0689 53.1432C82.9437 53.278 82.7927 53.3862 82.625 53.4614C82.4572 53.5366 82.2761 53.5773 82.0923 53.581H82.0358Z' fill='#F3CC04' />
      <path d='M84.0198 87.1228C83.664 87.1243 83.3214 86.9876 83.0638 86.7416L79.5686 83.4343C79.4309 83.3097 79.3195 83.1587 79.2408 82.9903C79.1621 82.822 79.1178 82.6395 79.1104 82.4537C79.103 82.2679 79.1327 82.0826 79.1978 81.9084C79.2629 81.7343 79.362 81.575 79.4894 81.4398C79.6168 81.3046 79.7698 81.1963 79.9395 81.1211C80.1092 81.046 80.2922 81.0056 80.4777 81.0024C80.6631 80.9991 80.8474 81.033 81.0197 81.102C81.1919 81.1711 81.3486 81.274 81.4807 81.4046L83.9633 83.7485L89.5351 77.8088C89.7886 77.539 90.1387 77.3811 90.5084 77.37C90.8781 77.3589 91.237 77.4954 91.5063 77.7496C91.7755 78.0037 91.933 78.3546 91.9441 78.7251C91.9551 79.0956 91.8189 79.4553 91.5654 79.7252L85.0324 86.6849C84.908 86.8193 84.7579 86.9273 84.591 87.0026C84.4241 87.0778 84.2439 87.1187 84.061 87.1228H84.0198Z' fill='#F3CC04' />
      <path d='M85.9987 120.67C85.6423 120.669 85.2998 120.531 85.0427 120.284L81.5475 116.976C81.4098 116.852 81.2984 116.701 81.2197 116.532C81.141 116.364 81.0967 116.181 81.0893 115.996C81.0819 115.81 81.1116 115.625 81.1767 115.45C81.2418 115.276 81.3409 115.117 81.4683 114.982C81.5957 114.847 81.7487 114.738 81.9184 114.663C82.0881 114.588 82.2711 114.548 82.4565 114.544C82.642 114.541 82.8263 114.575 82.9986 114.644C83.1708 114.713 83.3275 114.816 83.4596 114.947L85.9422 117.29L91.5191 111.351C91.7802 111.126 92.1152 111.006 92.4594 111.014C92.8035 111.022 93.1325 111.158 93.3829 111.394C93.6332 111.631 93.7872 111.953 93.8151 112.297C93.843 112.64 93.7429 112.983 93.534 113.257L87.001 120.217C86.8753 120.351 86.7242 120.458 86.5565 120.534C86.3889 120.609 86.208 120.65 86.0244 120.654L85.9987 120.67Z' fill='#F3CC04' />
      <path d='M159.715 41.714L149.732 44.1733L179.524 165.642L189.506 163.183L159.715 41.714Z' fill='#F3CC04' />
      <path opacity='0.2' d='M159.71 41.713L156.67 42.4619L186.461 163.931L189.501 163.182L159.71 41.713Z' fill='black' />
      <path d='M188.752 164.99L181.015 166.896C180.395 167.048 180.016 167.676 180.168 168.298L181.464 173.581C181.617 174.202 182.243 174.582 182.863 174.43L190.6 172.524C191.22 172.371 191.599 171.743 191.447 171.121L190.151 165.839C189.999 165.217 189.372 164.837 188.752 164.99Z' fill='#F3CC04' />
      <path opacity='0.7' d='M188.752 164.99L181.015 166.896C180.395 167.048 180.016 167.676 180.168 168.298L181.464 173.581C181.617 174.202 182.243 174.582 182.863 174.43L190.6 172.524C191.22 172.371 191.599 171.743 191.447 171.121L190.151 165.839C189.999 165.217 189.372 164.837 188.752 164.99Z' fill='white' />
      <path d='M188.812 161.435L179.323 163.773C178.75 163.914 178.399 164.494 178.54 165.069L179.389 168.531C179.53 169.105 180.109 169.457 180.683 169.315L190.171 166.978C190.745 166.837 191.095 166.256 190.955 165.681L190.105 162.22C189.965 161.645 189.385 161.294 188.812 161.435Z' fill='#E0E0E0' />
      <path d='M159.717 41.7018L149.735 44.1642L150.907 35.793L151.657 30.425L154.808 34.8296L159.717 41.7018Z' fill='#E0E0E0' />
      <path d='M154.808 34.8296L150.907 35.793L151.657 30.425L154.808 34.8296Z' fill='#F3CC04' />
      <path d='M185.499 33.7581C185.448 36.849 184.851 42.6754 186.476 44.5763C182.742 45.8068 179.395 47.9948 176.766 50.923C174.54 47.2757 177.445 45.313 177.445 45.313C180.647 44.2827 180.451 41.8666 179.778 39.6463L185.499 33.7581Z' fill='#FFB573' />
      <path d='M175.82 47.7756C176.668 44.6177 174.278 44.1695 178.262 42.624C181.377 41.3877 187.262 41.2125 188.645 42.2068C189.41 42.7631 188.357 44.7104 188.357 44.7104L175.82 47.7756Z' fill='#263238' />
      <path d='M193.086 47.0955C193.317 47.4664 193.481 47.7497 193.666 48.0743C193.851 48.3988 194.006 48.6976 194.18 49.0118C194.509 49.6403 194.818 50.2688 195.116 50.9076C195.712 52.18 196.231 53.4834 196.709 54.8022C197.669 57.4734 198.358 60.2349 198.765 63.0446L198.899 64.1059C198.925 64.2552 198.945 64.5231 198.961 64.755C198.976 64.9868 198.961 65.198 198.961 65.404C198.949 66.164 198.892 66.9226 198.791 67.6759C198.611 69.0977 198.339 70.4423 198.04 71.7971C197.433 74.4396 196.666 77.0425 195.743 79.5914C195.62 79.937 195.376 80.226 195.056 80.4043C194.737 80.5827 194.363 80.6383 194.005 80.5607C193.648 80.483 193.33 80.2775 193.113 79.9825C192.895 79.6875 192.792 79.3232 192.823 78.9577C193.029 76.3408 193.271 73.7032 193.461 71.1274C193.553 69.8395 193.625 68.5516 193.646 67.3359C193.646 66.7331 193.646 66.1356 193.589 65.6359L193.543 65.2907L193.476 64.9559L193.286 64.0595C192.737 61.6453 191.998 59.2785 191.076 56.9813C190.613 55.8273 190.115 54.6837 189.59 53.5555C189.33 52.9888 189.057 52.4325 188.773 51.8864L188.341 51.0725L187.935 50.3255L187.863 50.1915C187.489 49.4998 187.398 48.689 187.611 47.9313C187.823 47.1737 188.322 46.5288 189.001 46.1338C189.68 45.7388 190.486 45.6248 191.248 45.8159C192.01 46.007 192.667 46.4882 193.08 47.1573L193.086 47.0955Z' fill='#FFB573' />
      <path d='M191.708 80.632L192.135 79.9211L193.337 77.8914L195.542 79.1432C196 81.2038 194.602 83.0274 194.602 83.0274L193.98 83.5426C193.768 83.7274 193.494 83.8244 193.213 83.8139C192.932 83.8034 192.666 83.6861 192.469 83.4859L191.991 83.0429C191.69 82.7256 191.5 82.3193 191.449 81.8846C191.398 81.45 191.489 81.0105 191.708 80.632Z' fill='#FFB573' />
      <path d='M162.41 79.5811L162.055 78.2932C161.972 77.9902 161.774 77.732 161.503 77.5741C161.232 77.4161 160.91 77.371 160.606 77.4483L159.064 77.845L158.113 78.082C157.613 78.2127 157.169 78.5031 156.848 78.9093C156.527 79.3154 156.347 79.8153 156.335 80.3332V81.1008C156.332 81.4351 156.457 81.7578 156.683 82.0036C156.909 82.2494 157.22 82.3999 157.553 82.4247L158.504 82.5174C159.93 82.2897 161.238 81.5853 162.215 80.5186C162.321 80.3899 162.394 80.2373 162.428 80.074C162.462 79.9107 162.456 79.7415 162.41 79.5811Z' fill='#FFB573' />
      <path d='M190.516 44.8289C193.214 46.0292 195.686 48.8883 197.712 54.1789C197.064 56.358 190.377 59.928 190.377 59.928C190.377 59.928 182.035 42.2016 190.516 44.8289Z' fill='#F3CC04' />
      <path d='M168.193 51.603C168.218 53.5658 164.975 58.1146 168.707 60.7522C169.03 64.724 169.467 68.634 170.315 75.1507H190.274C188.377 67.9386 190.017 63.0961 193.06 50.4233C193.234 49.7424 193.258 49.0318 193.133 48.3403C193.007 47.6487 192.734 46.9926 192.332 46.4168C191.929 45.841 191.408 45.3591 190.802 45.0041C190.197 44.6491 189.522 44.4294 188.824 44.36C188.028 44.2827 187.185 44.2157 186.326 44.1694C183.308 43.9527 180.278 43.9527 177.259 44.1694C176.278 44.2569 175.265 44.3806 174.294 44.5145C172.596 44.7625 171.044 45.6158 169.923 46.9179C168.803 48.22 168.188 49.8834 168.193 51.603Z' fill='#F3CC04' />
      <path d='M174.803 49.6918C174.849 51.1651 174.803 52.5767 174.803 54.0191C174.803 55.4615 174.71 56.8885 174.618 58.3309C174.458 61.2552 174.115 64.1665 173.59 67.0473C173.528 67.4131 173.43 67.7943 173.343 68.1652C173.255 68.5361 173.178 68.9071 173.06 69.2986L172.896 69.8704L172.808 70.1589C172.775 70.2884 172.734 70.4157 172.685 70.5401C172.47 71.0949 172.175 71.6155 171.811 72.0856C171.31 72.7376 170.762 73.3522 170.171 73.9247C169.632 74.4656 169.082 74.955 168.527 75.4701C166.341 77.3575 164.018 79.0794 161.577 80.6217C161.271 80.8146 160.906 80.8909 160.548 80.8367C160.19 80.7825 159.863 80.6014 159.627 80.3263C159.392 80.0512 159.262 79.7004 159.262 79.3377C159.263 78.975 159.393 78.6245 159.629 78.3498C161.464 76.2532 163.356 74.1719 165.119 72.1113C165.551 71.5962 165.972 71.081 166.373 70.5659C166.749 70.1083 167.093 69.6245 167.401 69.1183C167.524 68.9277 167.601 68.6958 167.535 68.8246C167.468 68.9534 167.535 68.7577 167.535 68.701L167.581 68.4743L167.679 68.0261C167.75 67.7376 167.792 67.4131 167.843 67.0989C167.894 66.7846 167.956 66.4704 167.987 66.1407C168.336 63.5391 168.501 60.7985 168.599 58.0579C168.696 55.3173 168.758 52.5252 168.773 49.8154C168.769 49.4183 168.843 49.0242 168.99 48.6557C169.138 48.2871 169.356 47.9513 169.633 47.6674C170.193 47.094 170.957 46.7669 171.757 46.758C172.557 46.7491 173.328 47.0592 173.9 47.62C174.472 48.1808 174.799 48.9465 174.808 49.7485L174.803 49.6918Z' fill='#FFB573' />
      <path d='M172.603 44.973C170.141 46.1991 167.699 48.5791 166.686 54.0294C167.329 56.1879 176.129 60.3452 176.129 60.3452C176.129 60.3452 181.027 41.8202 172.603 44.973Z' fill='#F3CC04' />
      <path d='M173.142 31.7904C173.209 32.1767 173.055 32.5373 172.798 32.5837C172.541 32.6301 172.284 32.3622 172.217 31.9707C172.15 31.5792 172.31 31.2237 172.562 31.1773C172.813 31.131 173.081 31.3988 173.142 31.7904Z' fill='#263238' />
      <path d='M172.69 31.172L171.708 31.0896C171.708 31.0896 172.299 31.7232 172.69 31.172Z' fill='#263238' />
      <path d='M173.05 32.2229C172.848 33.1834 172.518 34.1122 172.068 34.9841C172.293 35.0895 172.539 35.1442 172.788 35.1442C173.036 35.1442 173.282 35.0895 173.507 34.9841L173.05 32.2229Z' fill='#ED893E' />
      <path d='M172.125 29.1321C172.066 29.1316 172.009 29.1097 171.965 29.0703C171.944 29.0487 171.926 29.023 171.914 28.9948C171.903 28.9665 171.897 28.9361 171.897 28.9054C171.897 28.8748 171.903 28.8444 171.914 28.8161C171.926 28.7878 171.944 28.7621 171.965 28.7406C172.216 28.4634 172.529 28.2498 172.879 28.1173C173.228 27.9848 173.604 27.9371 173.975 27.9782C174.004 27.9837 174.033 27.9951 174.058 28.0116C174.083 28.0281 174.104 28.0494 174.121 28.0744C174.138 28.0993 174.149 28.1273 174.155 28.1568C174.161 28.1862 174.161 28.2166 174.155 28.246C174.142 28.3077 174.107 28.3621 174.055 28.3977C174.003 28.4333 173.939 28.4473 173.877 28.4366C173.583 28.4099 173.287 28.4523 173.012 28.5605C172.737 28.6687 172.491 28.8397 172.294 29.06C172.249 29.1055 172.188 29.1315 172.125 29.1321Z' fill='#263238' />
      <path d='M185.864 27.9523C186.378 32.7947 186.686 34.8348 184.718 37.7402C181.752 42.1448 175.789 41.8615 173.872 37.3126C172.145 33.1914 171.605 25.9793 175.903 23.1665C176.827 22.5347 177.901 22.1575 179.016 22.0726C180.132 21.9877 181.25 22.1981 182.259 22.6828C183.268 23.1675 184.132 23.9095 184.765 24.8343C185.397 25.7592 185.776 26.8344 185.864 27.9523Z' fill='#FFB573' />
      <path d='M177.074 31.4915C173.99 30.9454 173.245 27.396 174.386 25.8505C169.19 26.0824 165.689 20.256 171.302 16.9332C176.915 13.6105 181.87 18.7569 181.469 20.699C188.382 20.0705 192.433 25.7733 188.665 30.013C193.635 30.8218 201.078 36.437 195.614 42.3355C203.001 43.4224 203.802 66.6044 183.695 62.4831C162.497 58.1352 180.914 39.6464 180.914 39.6464C180.914 39.6464 175.543 38.1318 177.074 31.4915Z' fill='#263238' />
      <path d='M196.766 43.845L196.329 43.5771C198.195 40.5377 198.503 37.7713 197.244 35.3346C195.625 32.2437 191.698 30.42 188.65 30.2449L188.681 29.7297C191.867 29.91 195.995 31.7904 197.701 35.0925C199.027 37.6683 198.704 40.6871 196.766 43.845Z' fill='#263238' />
      <path d='M190.922 30.5333C192.695 30.2397 191.857 27.3394 190.552 26.5718C189.246 25.8042 182.991 27.0509 183.679 29.0497C184.368 31.0485 188.542 30.93 190.922 30.5333Z' fill='white' />
      <path d='M191.24 29.8017C193.008 29.4617 192.299 27.1126 190.973 26.376C189.647 25.6393 184.183 26.1854 184.928 28.1584C185.674 30.1314 188.876 30.2705 191.24 29.8017Z' fill='#F3CC04' />
      <path d='M172.716 26.4379C171.532 26.4219 170.387 26.0104 169.462 25.2685C167.987 24.1197 167.175 22.3991 167.406 20.8846C167.545 19.9212 168.146 18.5973 170.243 17.7576L170.434 18.2367C168.953 18.8291 168.085 19.7821 167.915 20.9567C167.73 22.2961 168.465 23.8261 169.796 24.8615C170.414 25.3776 171.158 25.7194 171.951 25.8516C172.744 25.9838 173.558 25.9017 174.309 25.6137L174.504 26.0928C173.937 26.3255 173.329 26.4428 172.716 26.4379Z' fill='#263238' />
      <path d='M178.293 32.0582C178.061 33.3255 177.989 33.8664 177.331 34.4589C177.144 34.6685 176.899 34.8166 176.626 34.8839C176.354 34.9511 176.068 34.9344 175.805 34.8359C175.542 34.7373 175.315 34.5616 175.154 34.3316C174.992 34.1016 174.904 33.8282 174.9 33.547C174.797 32.3828 175.193 30.5076 176.37 30.1161C177.547 29.7246 178.524 30.7961 178.293 32.0582Z' fill='#FFB573' />
      <path d='M175.455 36.0919L175.039 35.3449C175.021 35.3144 175.012 35.2797 175.012 35.2445C175.012 35.2092 175.021 35.1746 175.039 35.144L175.455 34.4022C175.473 34.37 175.499 34.3432 175.531 34.3245C175.562 34.3058 175.599 34.2959 175.635 34.2959C175.672 34.2959 175.708 34.3058 175.74 34.3245C175.771 34.3432 175.797 34.37 175.815 34.4022L176.231 35.144C176.249 35.1746 176.258 35.2092 176.258 35.2445C176.258 35.2797 176.249 35.3144 176.231 35.3449L175.815 36.0919C175.797 36.1241 175.771 36.151 175.74 36.1697C175.708 36.1883 175.672 36.1982 175.635 36.1982C175.599 36.1982 175.562 36.1883 175.531 36.1697C175.499 36.151 175.473 36.1241 175.455 36.0919Z' fill='white' />
      <path d='M170.326 75.1353C170.326 75.1353 169.621 100.965 168.681 117.955C167.684 133.549 166.404 170.016 166.404 170.016L170.336 170.222C170.336 170.222 179.033 139.535 178.668 120.994C188.876 95.0614 185.206 75.9131 185.206 75.9131L170.326 75.1353Z' fill='#FFB573' />
      <path d='M166.697 168.378C166.609 168.315 166.504 168.281 166.396 168.281C166.288 168.281 166.183 168.315 166.095 168.378C164.208 169.782 161.923 170.544 159.573 170.552C156.962 170.665 157.604 172.098 161.341 172.577C165.736 173.123 166.676 174.029 168.095 174.385C169.513 174.74 170.639 173.658 170.613 170.671C170.611 170.569 170.579 170.47 170.52 170.386C170.461 170.303 170.379 170.238 170.284 170.202C169.021 169.737 167.817 169.125 166.697 168.378Z' fill='#263238' />
      <path d='M175.543 75.1353C175.543 75.1353 182.739 105.705 186.907 119.954C191.292 134.893 194.766 168.986 194.741 170.114H198.143C198.143 170.114 202.538 142.481 197.115 119.938C196.601 83.3623 190.31 75.161 190.31 75.161L175.543 75.1353Z' fill='#FFB573' />
      <path d='M194.653 168.893C194.565 168.857 194.467 168.85 194.374 168.874C194.281 168.898 194.199 168.952 194.139 169.027C193.26 170.171 192.623 171.438 190.027 172.551C187.879 173.468 189.066 174.725 192.428 174.266C196.38 173.72 196.437 174.364 197.768 174.364C199.099 174.364 199.726 173.035 198.755 170.145C198.724 170.049 198.667 169.964 198.591 169.899C198.515 169.833 198.422 169.79 198.323 169.774C197.069 169.63 195.837 169.334 194.653 168.893Z' fill='#263238' />
      <path d='M170.326 75.1354C170.326 75.1354 166.933 97.7712 167.411 128.711C178.143 128.65 188.807 127.001 199.058 123.817C199.058 123.817 199.212 83.7281 190.284 75.1508C185.129 73.7599 170.444 74.682 170.326 75.1354Z' fill='#263238' />
      <path opacity='0.2' d='M170.721 124.317C170.688 124.317 170.656 124.304 170.632 124.281C170.608 124.258 170.594 124.227 170.593 124.193C169.632 104.437 171.965 76.5467 171.986 76.284C171.986 76.2668 171.991 76.25 171.998 76.2345C172.005 76.219 172.016 76.2052 172.029 76.1941C172.042 76.1829 172.057 76.1746 172.074 76.1697C172.09 76.1648 172.108 76.1634 172.125 76.1655C172.142 76.1668 172.158 76.1715 172.173 76.1792C172.188 76.1869 172.202 76.1975 172.213 76.2104C172.224 76.2233 172.232 76.2383 172.237 76.2545C172.242 76.2707 172.244 76.2877 172.243 76.3046C172.217 76.588 169.889 104.458 170.85 124.214C170.851 124.231 170.849 124.248 170.844 124.264C170.838 124.281 170.829 124.296 170.818 124.308C170.806 124.321 170.792 124.331 170.776 124.338C170.761 124.344 170.744 124.348 170.727 124.348L170.721 124.317Z' fill='white' />
      <path opacity='0.2' d='M184.152 104.561L187.077 89.0342L188.254 92.7484L184.152 104.561Z' fill='black' />
    </SvgIcon>
  )
}
