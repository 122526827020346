import { EProposalStatus } from '~/graphql/types'
import { Container } from './styles'
import { ECustomerInvoiceStatus, translateInvoiceStatus } from '~/utils'

type InvoiceStatusProps = {
  status: ECustomerInvoiceStatus
}
export const InvoiceStatus: React.FC<InvoiceStatusProps> = ({ status }) => {
  return (
    <Container status={status || EProposalStatus.finalized}>
      <span>{translateInvoiceStatus(status || ECustomerInvoiceStatus.pending)}</span>
    </Container>
  )
}
