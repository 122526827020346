import styled from '@emotion/styled'

export const Container = styled.div`
  position: relative;

  .slick-slide {
    margin: 0 24px;
    height: inherit !important;
    width: 100% inherit !important;
  }

  .slick-list {
    margin: 0 -24px;
  }

  .slick-track {
    display: flex !important;
    width: 100%;
  }

  .slick-prev {
    right: 80px;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .slick-next {
    right: 30px;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .slick-arrow {
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    border: 0;
    border-radius: 50%;
    background: transparent;
    transition: background .1s ease-in-out;

    top: 40px;
    margin: auto 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(03,03,03,0.1);
    }
  }
`
