import styled from '@emotion/styled'
import { colorCustomerStatus } from '~/utils'

type ContainerProps = {
  status: string;
}
export const Container = styled.div<ContainerProps>`
  span {
    ${(props) => colorCustomerStatus(props.status)}
    padding: .2rem .6rem;
    border-radius: .4rem;
    font-weight: 600;
    font-size: .8rem;
  }
`
