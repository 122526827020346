/* eslint-disable no-nested-ternary */
import { useMemo } from 'react'
import { BankCodes, banks, BankType } from '~/constants/banks'

export const useBank = (activeBankCode?: BankCodes) => {

  const selectedBank: BankType = useMemo(() => {
    if(!activeBankCode) return banks[0]
    return banks[activeBankCode]
  }, [activeBankCode])

  const bankOptions = useMemo(() => {
    return Object.values(banks).map((bank) => {
      return { value: bank.code, label: `${bank.code} - ${bank.name}` }
    }).sort((a: any, b: any) => a.value - b.value)
  }, [])

  const accountLabel = useMemo(() => {
    // check if "Caixa Economica" is the active bank
    return selectedBank?.code === '104' ? 'Operação + Conta' : 'Conta'
  }, [selectedBank])

  const agencyLabel = useMemo(() => {
    return selectedBank?.agencyDigit ? 'Agência + Digito' : 'Agência' 
  }, [selectedBank])

  const agencyMask: string = useMemo(() => {
    return selectedBank?.agencyMask
  }, [selectedBank])

  const accountMask: string = useMemo(() => {
    return selectedBank?.accountMask
  }, [selectedBank])

  return {
    bankOptions,
    accountLabel,
    agencyLabel,
    agencyMask,
    accountMask,
    selectedBank
  }
}
