import { Dialog } from '../Dialog'
import { Divider, Typography, Button, Box, useMediaQuery, CircularProgress } from '@mui/material'
import { theme } from '~/utils'
import { ContainerWithScroll, Ol } from './styles'
import { IconInfoCircleFilled } from '@tabler/icons-react'
import { ERewardType, useUserContractChangeBenefitMutation } from '~/graphql/types'
import { useContract } from '~/contexts'
import { toast } from 'react-toastify'

type ChangeToDiscountProps = {
  isVisible: boolean
  toggle: Function
}

export const ChangeToDiscount: React.FC<ChangeToDiscountProps> = ({ isVisible, toggle }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { selectedContract, setSelectedContract, setContracts } = useContract()
  const [changeToDiscount, { loading }] = useUserContractChangeBenefitMutation({
    variables: {
      params: {
        contractId: selectedContract?._id || '',
        rewardType: ERewardType.discount
      }
    },
    onCompleted(data) {
      if(selectedContract?._id) {
        setSelectedContract({ ...selectedContract, reward: data.userContractChangeBenefit.reward || selectedContract?.reward as any })
        setContracts(prev => prev.map(item => item._id === data.userContractChangeBenefit._id ? { ...item, reward: data.userContractChangeBenefit.reward || selectedContract?.reward as any } : item ))
      }
      toast.success('Sua recompensa foi alterada para desconto!')
      toggle()
    },
    onError() {
      toast.error('Não foi possível alterar seu modelo de recompensa.')
    }
  })

  return (
    <Dialog 
      titleIcon={<IconInfoCircleFilled style={{ color: theme.palette.info.dark }} size={32} />} 
      title='Alterar para desconto'
      onClose={() => toggle()} 
      isVisible={isVisible}
    >
      <Divider sx={{ margin: '0 0 1.6rem 0' }}/>
      <Typography sx={{ color: theme.palette.grey['600'], paddingBottom: '1.6rem' }}>Antes de confirmar, esteja ciente:</Typography>

      <ContainerWithScroll>
        <Ol>
          <li>Ao alterar seu modelo de recompensa, você perde todos os benefícios do cashback, o acesso à sua carteira digital na sua área do cliente e encerra a sua participação no programa;</li>
          <li>O saldo de cashback não utilizado será perdido caso  seja inferior a R$ 5,00 (cinco reais);</li>
          <li>Caso seja superior a R$5,00, todo o saldo restante na sua carteira digital será automaticamente transferido para sua conta bancária vinculada, e poderá ficar disponível em até 48h a partir da realização da transferência;</li>
          <li>A partir da alteração do programa de recompensa para desconto, sua próxima fatura já virá com o percentual de desconto aplicado diretamente na mesma.</li>
        </Ol>
      </ContainerWithScroll>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', paddingTop: '2rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Button onClick={() => toggle()} color='secondary'>Voltar</Button>
        <Button 
          endIcon={loading ? <CircularProgress size={24} color='inherit' /> : <></>} 
          disabled={loading} 
          variant='contained' 
          color='info' onClick={() => changeToDiscount()} 
          type='submit'
        >
          Alterar para desconto
        </Button>
      </Box>
    </Dialog>
  )
}
