import { Dialog, Input } from '~/components'
import { Divider, Typography, Button, Box, useMediaQuery, CircularProgress } from '@mui/material'
import { formatMoney, theme, transformMoney } from '~/utils'
import { CustomerCashbackInvoicePayDto, useCustomerInvoicePayWithCashbackAvaliableLazyQuery, useCustomerInvoicePayWithCashbackAvaliableQuery, useCustomerPayInvoicesWithCashbackMutation, useListAllInvoicesCustomerUserMutation, useWalletInformationsQuery } from '~/graphql/types'
import { useContract } from '~/contexts'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IconLock } from '@tabler/icons-react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CompletedPaymentModalCashback, ConfirmPaymentModalCashback, InfoModalCashback, WaitingModalCashback } from './components'

type PayInvoceWithCashbackModalProps = {
  isVisible: boolean
  toggle: () => void
  balance: number
  setBalance: React.Dispatch<React.SetStateAction<number>>
}
export const PayInvoceWithCashbackModal: React.FC<PayInvoceWithCashbackModalProps> = ({ isVisible, toggle, setBalance }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [authenticantionIsOpen, setAuthenticantionIsOpen] = useState(false)
  const [paymentCompleted, setPaymentCompleted] = useState(false)
  const [splitData, setSplitData] = useState<null | any>(null)
  const { selectedContract } = useContract()
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const schema = yup.object({
    password: yup.string().required('Senha é obrigatória')
  }).required()

  const formMethods = useForm<CustomerCashbackInvoicePayDto>({
    resolver: yupResolver(schema)
  })




  // const { data: walletDta, loading: walletIsLoading } = useWalletInformationsQuery()


  // const [getListInvoices, { loading: listInvoicesIsLoading, data: listInvoicesData }] = useListAllInvoicesCustomerUserMutation({
  //   variables: {
  //     filters: {
  //       contractId: selectedContract?._id,
  //     }
  //   }
  // })
  const { data: customerInvoiceData, loading: customerInvoiceLoading } = useCustomerInvoicePayWithCashbackAvaliableQuery()
  const customerInvoiceInfo = customerInvoiceData?.customerInvoicePayWithCashbackAvaliable
  const [customerPayInvoiceWithCashback, { loading: customerPayinvoiceLoading }] = useCustomerPayInvoicesWithCashbackMutation({
  })

  function onSubmit(data: CustomerCashbackInvoicePayDto) {
    console.log(data);
    customerPayInvoiceWithCashback({
      variables: {
        params: {
          invoceIds: Array(customerInvoiceInfo?.invoiceId || '') || [''],
          password: data.password || ''
        }
      },
      onCompleted(data) {
        // toast.success('Sua fatura foi paga com sucesso!')

        setBalance(data.customerPayInvoicesWithCashback.balanceCashbackAfterInvoicedPaidCents)
        setPaymentCompleted(true)
      },
      onError(error) {
        formMethods.setError(
          'password',
          {
            message: error.message,
          }
        )


      },
    })
  }
  const dialogTitle = useMemo(() => {
    return !paymentCompleted
      ? !customerInvoiceLoading
        ? !authenticantionIsOpen
          ? 'Usar casback na fatura'
          : 'Autenticação por senha'
        : undefined
      : undefined
  }, [paymentCompleted, customerInvoiceLoading, authenticantionIsOpen])

  return (

    <Dialog
      disabledCloseButton={customerInvoiceLoading}
      title={dialogTitle}
      onClose={() => { toggle(), setAuthenticantionIsOpen(false), setPaymentCompleted(false) }}
      isVisible={isVisible}>
      {!paymentCompleted ?
        <>
          {!customerInvoiceLoading || !customerPayinvoiceLoading ?
            <>
              {!authenticantionIsOpen ?
                (
                  <InfoModalCashback
                    toggle={toggle}
                    customerInvoiceInfo={customerInvoiceInfo}
                    setAuthenticantionIsOpen={setAuthenticantionIsOpen} />
                )
                :
                (
                  <FormProvider {...formMethods}>
                    <ConfirmPaymentModalCashback
                      // formMethods={formMethods}
                      onSubmit={onSubmit}
                      toggle={toggle}
                      setAuthenticantionIsOpen={setAuthenticantionIsOpen}
                      setSplitData={setSplitData}
                      customerPayinvoiceLoading={customerPayinvoiceLoading}
                    />
                  </FormProvider>
                )
              }
            </>
            :
            <WaitingModalCashback />
          }
        </>
        :
        <CompletedPaymentModalCashback
          toggle={toggle}
          setAuthenticantionIsOpen={setAuthenticantionIsOpen}
          setPaymentCompleted={setPaymentCompleted}
        />
      }

    </Dialog>
  )
}
