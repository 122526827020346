import styled from '@emotion/styled'

export const Container = styled.div`
  position: relative;
  width: max-content;
  z-index: 999;
`

export type DropProps = {
  $options?: {
    top?: string,
    left?: string
  }
}
export const Drop = styled.div<DropProps>`
  position: absolute;
  top: ${(props) => props.$options?.top ?? '40px'};
  left: ${(props) => props.$options?.left ?? '0'};
`
