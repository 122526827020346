import React, { useCallback, useEffect } from 'react'
import { UseWizardResult } from '~/hooks'

export type WizardProps = {
  screens: React.ComponentType<UseWizardResult>[],
  onFinish?: () => void,
  onExit?: () => void
  wizardHelpers: UseWizardResult
  resetOnUnmount?: boolean
}

export type PropsWithWizard<P = {}> = P & UseWizardResult & Pick<WizardProps, 'onExit' | 'onFinish'>
export type WizardHelpers = UseWizardResult & Pick<WizardProps, 'onExit' | 'onFinish'>

export const Wizard: React.FC<WizardProps> = (props) => {
  const { screens, wizardHelpers, resetOnUnmount = true } = props

  useEffect(() => {
    return resetOnUnmount ? () => {
      wizardHelpers.resetWizard()
    } : undefined
  }, [])

  const getScreenToRender = useCallback((currentScreen: number) => {
    if (screens.length < currentScreen) {
      throw new Error('currentScreen is greater or equal than screen.length')
    }

    const CurrentScreen = screens[currentScreen]

    return (
      <>
        <CurrentScreen {...wizardHelpers} {...props} />
      </>
    )
  }, [screens, wizardHelpers])

  return getScreenToRender(wizardHelpers.currentPage)
}
