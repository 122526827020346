import { useState } from 'react'
import { IconCopy, IconInfoCircleFilled, IconPointFilled } from '@tabler/icons-react'
import { Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import { Banner } from '~/components'
import { theme, trimString } from '~/utils'
import { copyToClipboard } from '~/utils/clipboard'
import { InvoicePaymentModel } from '~/graphql/types'

export type BarcodePaymentProps = {
  paymentInfo: InvoicePaymentModel
}

export const BarcodePayment: React.FC<BarcodePaymentProps> = ({ paymentInfo }) => {
  const [loading, setLoading] = useState(true)
  const { barcode, digitableLine } = paymentInfo
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{
      gap: 3,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Banner
        type='warning'
        icon={<IconInfoCircleFilled size={isLowerSm ? 30 : 40} style={{ color: theme.palette.yellow.main }} />}
        text='Recomendamos que realize a transação por Pix para agilizar o pagamento.'
      />

      <Typography variant={isLowerSm ? 'body2' : 'body1'}>Utilize o código de barras para efetuar o pagamento:</Typography>

      <Box sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E6E6E6',
        alignItems: 'center',
        borderRadius: 2
      }}>
        <CircularProgress sx={{ margin: '8px 0px', display: loading ? 'block' : 'none' }} size={32} />
        <img style={{ display: loading ? 'none' : 'block' }} onLoad={() => setLoading(false)} width='100%' src={barcode || ''} />
      </Box>

      <Typography variant={isLowerSm ? 'body2' : 'body1'}>Linha Digitável</Typography>

      {digitableLine && (
        <Box sx={{
          padding: 2,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          border: '1px solid #E6E6E6'
        }}>
          <Typography
            variant={isLowerSm ? 'body2' : 'body1'}>
            {isLowerSm ? trimString(digitableLine, { maxTextLength: 30, textCutLength: 20 }) : digitableLine}
          </Typography>
        </Box>
      )}

      <Typography variant={isLowerSm ? 'body2' : 'body1'}>
        <b>IMPORTANTE:</b> Após o pagamento, vamos notificá-lo por e-mail para informar que seu pagamento foi confirmado.
      </Typography>

      <Button
        startIcon={<IconCopy />}
        sx={{ alignSelf: isLowerSm ? 'inherit' : 'start' }}
        onClick={() => copyToClipboard(digitableLine || '')}
      >
        Copiar linha digitável
      </Button>

      <Box sx={{
        padding: 3,
        gap: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E6E6E6',
        background: '#FAFAFA'
      }}>
        <Typography variant={isLowerSm ? 'body2' : 'body1'}><b>IMPORTANTE:</b></Typography>

        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <IconPointFilled size={32} style={{ color: theme.palette.yellow.main, justifySelf: 'start' }} />
          <Typography variant={isLowerSm ? 'body3' : 'body2'}>
            Pagamentos com Boleto Bancário levam até 3 dias úteis para serem compensados e então terem os pagamentos confirmados.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <IconPointFilled size={32} style={{ color: theme.palette.yellow.main, justifySelf: 'start' }} />
          <Typography variant={isLowerSm ? 'body3' : 'body2'}>
            Atenção ao vencimento do boleto. Você pode pagar o boleto pelo seu internet banking, em qualquer banco ou casa lotérica até 30/09/2023.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <IconPointFilled size={32} style={{ color: theme.palette.yellow.main, justifySelf: 'start' }} />
          <Typography variant={isLowerSm ? 'body3' : 'body2'}>
            Depois do pagamento, verifique seu e-mail para receber os dados do seu pagamento. (verifique também a caixa de SPAM)
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
