import { SvgIcon, SvgIconProps } from '@mui/material'

export const PixIcon: React.FC<SvgIconProps> = ({ width, height, fill, ...props }) => {
  return (
    <SvgIcon  width={width || 20} height={height || 20} viewBox='0 0 20 20' fill={fill || 'none'} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M15.7831 15.3085C14.9986 15.3085 14.2609 15.0034 13.7059 14.4483L10.6954 11.4379C10.4854 11.2279 10.1183 11.2272 9.90749 11.4379L6.90812 14.4374C6.35308 14.9923 5.61535 15.2975 4.83082 15.2975H4.4696L8.27902 19.1069C9.46554 20.2934 11.3889 20.2934 12.5754 19.1069L16.3738 15.3085H15.7831ZM11.6796 18.2119C11.3456 18.5451 10.9011 18.7295 10.4277 18.7295H10.4269C9.95343 18.7295 9.50804 18.5451 9.17492 18.2119L6.95402 15.991C7.26164 15.8108 7.5464 15.5907 7.80394 15.3332L10.3019 12.8352L12.8109 15.3442C13.1092 15.6434 13.4432 15.8916 13.8061 16.0845L11.6796 18.2119V18.2119ZM12.5755 0.889863C11.389 -0.296621 9.46562 -0.296621 8.2791 0.889863L4.46972 4.69924H4.83093C5.61539 4.69924 6.3532 5.00525 6.9082 5.55939L9.90757 8.55881C10.1252 8.77725 10.4787 8.77639 10.6955 8.55881L13.706 5.54834C14.2609 4.99416 14.9987 4.68818 15.7832 4.68818H16.3739L12.5755 0.889863ZM12.811 4.65338L10.302 7.16236L7.80316 4.66354C7.54562 4.40604 7.26175 4.18674 6.9541 4.00658L9.17492 1.78482C9.86593 1.09557 10.9904 1.09467 11.6796 1.78482L13.8062 3.91221C13.4432 4.106 13.1093 4.3542 12.8109 4.65338H12.811Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M19.5361 7.85042L17.2557 5.57007H15.7828C15.2422 5.57007 14.7127 5.79019 14.3303 6.17265L11.3198 9.18315C11.0385 9.46362 10.6704 9.60385 10.3015 9.60385C9.93268 9.60385 9.56385 9.46362 9.28334 9.18315L6.28393 6.18374C5.90143 5.80038 5.37197 5.58112 4.83053 5.58112H3.58795L1.31857 7.85042C0.13209 9.03694 0.13209 10.9603 1.31857 12.1468L3.58791 14.4153L4.83049 14.4162C5.37189 14.4162 5.90143 14.196 6.28393 13.8136L9.2833 10.815C9.82725 10.2702 10.7758 10.2711 11.3197 10.8142L14.3302 13.8246C14.7127 14.2071 15.2422 14.4264 15.7828 14.4264H17.2556L19.5361 12.1468C20.7226 10.9603 20.7226 9.03694 19.5361 7.85042H19.5361ZM5.38807 12.9178C5.24107 13.0656 5.03877 13.1489 4.83053 13.1489H4.1124L2.21436 11.2519C1.52338 10.5608 1.52338 9.43639 2.21436 8.74628L4.11232 6.84753H4.83049C5.03873 6.84753 5.24103 6.93167 5.38889 7.0787L8.30842 9.99823L5.38807 12.9178ZM18.6403 11.2519L16.7322 13.1608H15.7828C15.5754 13.1608 15.3723 13.0767 15.2261 12.9296L12.2955 9.99823L15.2261 7.06765C15.3723 6.92061 15.5754 6.83647 15.7828 6.83647H16.7322L18.6403 8.74628C18.9751 9.0803 19.1587 9.52479 19.1587 9.99823C19.1587 10.4725 18.9751 10.917 18.6403 11.2519H18.6403Z' fill='#999999' />
    </SvgIcon>
  )
}
