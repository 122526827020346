import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import { theme } from '~/utils';

interface CompletedPaymentModalCashbackProps {
  setAuthenticantionIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPaymentCompleted: React.Dispatch<React.SetStateAction<boolean>>
  toggle: () => void;
}

export function CompletedPaymentModalCashback({ setAuthenticantionIsOpen, toggle, setPaymentCompleted }: CompletedPaymentModalCashbackProps) {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box sx={{ display: 'flex', flexFlow: "column", gap: '24px' }}>

      <Typography sx={{ fontSize: '24px', color: theme.palette.grey['700'], lineHeight: '40px', textAlign: 'center', fontWeight: '600' }}>Sucesso</Typography>
      <Divider />
      <Typography sx={{ fontSize: '16px', color: theme.palette.grey['400'], lineHeight: '25px', textAlign: 'center' }}>Sua última fatura consta como paga na Desperta!</Typography>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Button onClick={() => {
          toggle()
          setAuthenticantionIsOpen(false)
          setPaymentCompleted(false)
        }} >Entendi</Button>
      </Box>
    </Box>
  );
}
