import { Dialog } from '../Dialog'
import { Divider, Typography, Button, Box, useMediaQuery, CircularProgress, Grid } from '@mui/material'
import { displayCpfCnpj, displayPhone, formatAddressToText, theme } from '~/utils'
import { ContainerWithScroll, Ol, Ul } from './styles'
import { IconInfoCircleFilled } from '@tabler/icons-react'
import {  ERewardType, useUserContractChangeBenefitMutation, BankGraphqlDto, useCustomerAreaCreateDigitalAccountMutation, ECustomerPersonType } from '~/graphql/types'
import { useAuth, useContract } from '~/contexts'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Input } from '../Form/Input'
import { Select } from '../Form/Select'
import { useBank } from '~/hooks'
import { Radio } from '../Form/Radio'
import { bankAccountTypeOptions } from '~/utils/options'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { bankAccountSchema, bankAgencySchema } from '~/utils/yupSchema'

type ChangeToCashbackProps = {
  isVisible: boolean
  toggle: Function
}

export const ChangeToCashback: React.FC<ChangeToCashbackProps> = ({ isVisible, toggle }) => {
  const { authUser } = useAuth()
  const { selectedContract, setSelectedContract, setContracts } = useContract()

  const schema = yup.object().shape({
    code: yup.string().required(),
    account: bankAccountSchema,
    type: yup.string().required(),
    agency: bankAgencySchema,
  }).default(null)

  const [step, setStep] = useState<'select' | 'form' | null>(null)

  useEffect(() => {
    const heWasCashback = Boolean(authUser?.bank?.account)
    setStep(heWasCashback ? 'select' : 'form')
  }, [authUser])

  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const formMethods = useForm<BankGraphqlDto>({
    resolver: yupResolver(schema)
  })

  const { code } = useWatch({ control: formMethods.control })

  const { accountLabel, agencyLabel, bankOptions, agencyMask, accountMask } = useBank(code)
  const [addBankAccount, { loading: addBankAccountLoading }] = useCustomerAreaCreateDigitalAccountMutation({
    onCompleted() {
      toast.success('Seus dados bancários foram salvos!')
      setStep('select')
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const [changeToCashback, { loading: contractChangeLoading }] = useUserContractChangeBenefitMutation({
    variables: {
      params: {
        contractId: selectedContract?._id || '',
        rewardType: ERewardType.cashback
      }
    },
    onCompleted(data) {
      if(selectedContract?._id) {
        setSelectedContract({ ...selectedContract, reward: data.userContractChangeBenefit.reward || selectedContract?.reward as any })
        setContracts(prev => prev.map(item => item._id === data.userContractChangeBenefit._id ? { ...item, reward: data.userContractChangeBenefit.reward || selectedContract?.reward as any } : item ))
      }
      toast.success('Sua recompensa foi alterada para cashback!')
      toggle()
    },
    onError(error) {
      toast.error(error.message)
    }
  })

  function onSubmit(data: BankGraphqlDto) {
    
    addBankAccount({
      variables: {
        params: {
          accountOwner: authUser?.personType || ECustomerPersonType.pf as any,
          bank: {
            ...data
          }
        }
      }
    })
  }

  return (
    <Dialog 
      titleIcon={step === 'select' ? <IconInfoCircleFilled style={{ color: theme.palette.info.dark }} size={32} /> : <></>} 
      title={step === 'select' ? 'Alterar para Cashback' : 'Dados para cashback'}
      onClose={() => toggle()} 
      isVisible={isVisible}
    >
      {step == 'select' && (
        <>
          <Divider sx={{ margin: '0 0 1.6rem 0' }}/>
          <Typography sx={{ color: theme.palette.grey['600'], paddingBottom: '1.6rem' }}>Antes de confirmar, esteja ciente:</Typography>

          <ContainerWithScroll>
            <Ol>
              <li>A partir da alteração do programa de recompensa para cashback, sua próxima fatura deixará de vir com percentual de desconto aplicado e você começará a receber faturas com valor integral;</li>
              <li>A partir de agora, a cada fatura paga, você receberá parte do valor total da sua fatura em forma de cashback, isto é, como crédito diretamente na sua carteira digital;</li>
              <li>Ao alterar seu modelo de recompensa, você passará a ter acesso à sua carteira digital na sua área do cliente, onde poderá acompanhar seu saldo e extrato de cashback.</li>
            </Ol>
          </ContainerWithScroll>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', paddingTop: '2rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
            <Button onClick={() => toggle()} color='secondary'>Voltar</Button>
            <Button 
              endIcon={contractChangeLoading ? <CircularProgress size={24} color='inherit' /> : <></>} 
              disabled={contractChangeLoading} 
              variant='contained' 
              color='info' 
              onClick={() => changeToCashback()} 
              type='submit'
            >
                Quero cashback
            </Button>
          </Box>
        </>
      )}

      {step === 'form' && (
        <>
          <Divider sx={{ margin: '0 0 1.6rem 0' }}/>

          <Typography sx={{ color: theme.palette.grey['600'], paddingBottom: '1.6rem' }}>Antes de seguir com a adesão, informe e revise os dados abaixo:</Typography>

          <Ul>
            <li><Typography><span>Nome:</span> {authUser?.name}</Typography></li>
            <li><Typography><span>CPF:</span> {displayCpfCnpj(authUser?.document || '')}</Typography></li>
            <li><Typography><span>Endereço:</span> {formatAddressToText(authUser?.address)} </Typography></li>
            <li><Typography><span>E-mail:</span> {authUser?.email}</Typography></li>
            <li><Typography><span>Telefone:</span> {displayPhone(authUser?.phone || '')}</Typography></li>
          </Ul>

          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Select label='Banco' name='code' options={bankOptions} />
                </Grid>

                <Grid item xs={12}>
                  <Input name='agency' label={agencyLabel} customMask={agencyMask} />
                </Grid>
                <Grid item xs={12}>
                  <Input name='account' label={accountLabel} customMask={accountMask} />
                </Grid>

                <Grid item xs={12}>
                  <Radio row name='type' options={bankAccountTypeOptions} />
                </Grid>
              </Grid>

              <Divider sx={{ margin: '1.6rem 0 0 0' }} />
       
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', paddingTop: '2rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
                <Button onClick={() => toggle()} color='secondary'>Voltar</Button>
                <Button 
                  endIcon={addBankAccountLoading ? <CircularProgress size={24} color='inherit' /> : <></>} 
                  disabled={!formMethods.formState.isValid || addBankAccountLoading} 
                  variant='contained' 
                  color='info' 
                  type='submit'
                >
                  Quero cashback
                </Button>
              </Box>

            </form>
          </FormProvider>
        </>
      )}
      
    </Dialog>
  )
}
