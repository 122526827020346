import { Drawer } from '@mui/material'
import { drawerWidth } from '../..'
import { styled, Theme, CSSObject } from '@mui/material/styles'

const openDrawerStyle = (innerTheme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: innerTheme.transitions.create('width', {
    easing: innerTheme.transitions.easing.sharp,
    duration: innerTheme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedDrawerStyle = (innerTheme: Theme): CSSObject => ({
  transition: innerTheme.transitions.create('width', {
    easing: innerTheme.transitions.easing.sharp,
    duration: innerTheme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${innerTheme.spacing(7)})`,
  [innerTheme.breakpoints.up('sm')]: {
    width: `calc(${innerTheme.spacing(8)})`,
  },
})

export const DrawerHeader = styled('div')(({ theme: innerTheme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: innerTheme.spacing(0, 1),
  ...innerTheme.mixins.toolbar,
}))

export const StyleDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme: innerTheme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    display: 'flex',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...open && {
      ...openDrawerStyle(innerTheme),
      '& .MuiDrawer-paper': openDrawerStyle(innerTheme),
    },
    ...!open && {
      ...closedDrawerStyle(innerTheme),
      '& .MuiDrawer-paper': closedDrawerStyle(innerTheme),
    },
  }),
)
