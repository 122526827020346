import styled from '@emotion/styled'

export const ContainerTooltip = styled.div`
  background: ${(props) => props.theme.palette.grey['100']};
  padding: .6rem 1.2rem;
  border-radius: .4rem;

  display: flex;
  flex-flow: column;
  gap: .6rem;
`
