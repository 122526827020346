import { FormProvider, useForm } from 'react-hook-form'
import { Box, CircularProgress, Typography, useMediaQuery, Button } from '@mui/material'
import { AuthCustomerUserArgs } from '~/graphql/types'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { IconArrowRight, IconIdBadge, IconLock } from '@tabler/icons-react'
import { theme } from '~/utils'
import { LinkWithNoStyle } from '~/styles'
import { Checkbox, Input } from '~/components'
import { useAuth } from '~/contexts'

export type LoginFormData = AuthCustomerUserArgs & {
  rememberMe: boolean
}

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
    rememberMe: yup.boolean().required(),
  })
  .required()

export const LoginForm: React.FC = () => {
  const { authLogin, loginIsLoading } = useAuth()

  const formMethods = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      rememberMe: true
    }
  })

  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <FormProvider {...formMethods}>
      <form style={{ width: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={formMethods.handleSubmit(authLogin)}>

        <Input
          icons={{ start: !isLowerSm ? { element: <IconIdBadge style={{ color: '#999999' }} />, background: '#FAFAFA' } : undefined }} 
          label='CPF' 
          name='username'
          mask='cpfCnpj'
        />
        <Input
          icons={{
            start: !isLowerSm ? { element: <IconLock style={{ color: '#999999' }} />, background: '#FAFAFA' } : undefined
          }}
          type='password'
          label='Senha'
          name='password'
          style={{ margin: '24px 0px 8px 0px' }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '36px'
          }}
        >
          <Checkbox name='rememberMe' label='Lembrar senha' />
          <LinkWithNoStyle to='/recover-password'>
            <Typography variant='body2'>Esqueci a senha</Typography>
          </LinkWithNoStyle>
        </Box>

        <Button
          disabled={loginIsLoading}
          style={{ fontSize: '16px', fontWeight: 600 }}
          variant='contained'
          color='primary'
          type='submit'
          endIcon={loginIsLoading ? <CircularProgress size={24} color='inherit' /> : (
            <>
              {!isLowerSm && 
              <IconArrowRight />
              }
            </>
          )}
        >
          Entrar
        </Button>
      </form>
    </FormProvider>
  )
}
