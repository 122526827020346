/* eslint-disable quote-props */
export const banks: BankOptions = {
  '001': {
    code: '001',
    name: 'Banco do Brasil',
    agencyLength: 4,
    agencyMask: '####-#',
    agencyDigit: true,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true
  },
  '003': {
    code: '003',
    name: 'Banco da Amazonia',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 6,
    accountMask: '######-#',
    accountDigit: true
  },
  '004': {
    code: '004',
    name: 'Banco do Nordeste',
    agencyLength: 3,
    agencyMask: '###',
    agencyDigit: false,
    accountLength: 6,
    accountMask: '######-#',
    accountDigit: true
  },
  '021': {
    code: '021',
    name: 'Banestes',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true
  },
  '033': {
    code: '033',
    name: 'Banco Santander',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true
  },
  '037': {
    code: '037',
    name: 'Banpará',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########-#',
    accountDigit: true,
  },
  '041': {
    code: '041',
    name: 'Banrisul',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########-#',
    accountDigit: true,
  },
  '047': {
    code: '047',
    name: 'Banco Banese',
    agencyLength: 3,
    agencyMask: '###',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '070': {
    code: '070',
    name: 'BRB',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########-#',
    accountDigit: true,
  },
  '077': {
    code: '077',
    name: 'Banco Inter',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########-#',
    accountDigit: true,
  },
  '082': {
    code: '082',
    name: 'Banco Topazio',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '084': {
    code: '084',
    name: 'Uniprime Norte do Paraná',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 6,
    accountMask: '######-#',
    accountDigit: true,
  },
  '085': {
    code: '085',
    name: 'ViaCredi',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 11,
    accountMask: '###########-#',
    accountDigit: true,
  },
  '97': {
    code: '97',
    name: 'Cooperativa Central de Credito Noroeste Brasileiro',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '099': {
    code: '099',
    name: 'Uniprime',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 6,
    accountMask: '######-#',
    accountDigit: true,
  },
  '104': {
    code: '104',
    name: 'Caixa Econômica F.',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 11,
    accountMask: '###########-#',
    accountDigit: true,
  },
  '133': {
    code: '133',
    name: 'Cresol',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '136': {
    code: '136',
    name: 'Unicred',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '173': {
    code: '173',
    name: 'BRL Trust DTVM',
    agencyLength: 3,
    agencyMask: '###',
    agencyDigit: false,
    accountLength: 6,
    accountMask: '######-#',
    accountDigit: true,
  },
  '197': {
    code: '197',
    name: 'Banco Stone',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '208': {
    code: '208',
    name: 'Banco BTG Pactual',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '212': {
    code: '212',
    name: 'Banco Original',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '218': {
    code: '218',
    name: 'BS2',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '237': {
    code: '237',
    name: 'Bradesco / Next',
    agencyLength: 4,
    agencyMask: '####-#',
    agencyDigit: true,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '260': {
    code: '260',
    name: 'Nubank',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 10,
    accountMask: '##########-#',
    accountDigit: true,
  },
  '274': {
    code: '274',
    name: 'Money Plus',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '290': {
    code: '290',
    name: 'PagSeguro',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '301': {
    code: '301',
    name: 'DOCK IP S.A.',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 10,
    accountMask: '##########-#',
    accountDigit: true,
  },
  '323': {
    code: '323',
    name: 'Mercado Pago',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 10,
    accountMask: '##########-#',
    accountDigit: true,
  },
  '332': {
    code: '332',
    name: 'Acesso Soluções de Pagamento',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########-#',
    accountDigit: true,
  },
  '336': {
    code: '336',
    name: 'Banco C6',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '341': {
    code: '341',
    name: 'Itaú Unibanco',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########-#',
    accountDigit: true,
  },
  '364': {
    code: '364',
    name: 'Gerencianet Pagamentos do Brasil',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '376': {
    code: '376',
    name: 'Jp Morgan',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 11,
    accountMask: '###########-#',
    accountDigit: true,
  },
  '383': {
    code: '383',
    name: 'Juno',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 10,
    accountMask: '##########-#',
    accountDigit: true,
  },
  '384': {
    code: '384',
    name: 'Global SCM',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 11,
    accountMask: '###########',
    accountDigit: false,
  },
  '389': {
    code: '389',
    name: 'Mercantil do Brasil',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '403': {
    code: '403',
    name: 'Cora',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '422': {
    code: '422',
    name: 'Safra',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########-#',
    accountDigit: true,
  },
  '613': {
    code: '613',
    name: 'Banco Omni',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 6,
    accountMask: '######-#',
    accountDigit: true,
  },
  '633': {
    code: '633',
    name: 'Rendimento',
    agencyLength: 4,
    agencyMask: '####-#',
    agencyDigit: true,
    accountLength: 10,
    accountMask: '##########',
    accountDigit: false,
  },
  '655': {
    code: '655',
    name: 'Votorantim',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 10,
    accountMask: '##########-#',
    accountDigit: true,
  },
  '735': {
    code: '735',
    name: 'Neon',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 10,
    accountMask: '##########-#',
    accountDigit: true,
  },
  '707': {
    code: '707',
    name: 'Banco Daycoval',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 7,
    accountMask: '#######-#',
    accountDigit: true,
  },
  '745': {
    code: '745',
    name: 'Citibank',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '########',
    accountDigit: false,
  },
  '746': {
    code: '746',
    name: 'Modal',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########-#',
    accountDigit: true,
  },
  '748': {
    code: '748',
    name: 'Sicredi',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########',
    accountDigit: false,
  },
  '752': {
    code: '752',
    name: 'BNP Paribas Brasil',
    agencyLength: 3,
    agencyMask: '###',
    agencyDigit: false,
    accountLength: 8,
    accountMask: '######-###',
    accountDigit: true,
  },
  '756': {
    code: '756',
    name: 'Sicoob (Bancoob)',
    agencyLength: 4,
    agencyMask: '####',
    agencyDigit: false,
    accountLength: 9,
    accountMask: '#########-#',
    accountDigit: true,
  }
}

export type BankType = {
  code: string,
  name: string,
  agencyDigit: boolean,
  agencyMask: string,
  agencyLength: number,
  accountLength: number,
  accountDigit: boolean,
  accountMask: string
}

export type BankOptions = {
[K: string]: BankType
}

export type BankCodes = keyof typeof banks
