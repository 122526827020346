export const formatMoney = (value: number) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)  
}

export const transformMoney = (value: number, type: 'toCents' | 'toReal' = 'toCents') => {

  if(type === 'toCents') {
    return Number((value * 100).toFixed(2))
  }

  if(type === 'toReal') {
    return value / 100
  }

  return value
}

export const transformPercentage = (value?: number, type: 'toDisplay' | 'toApi' = 'toApi') => {

  if(!value) {
    return 0
  }

  if(type === 'toApi') {
    return (value || 0) / 100
  }

  if(type === 'toDisplay') {
    return (value || 0) * 100
  }

  return value
}
