import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`

const scale = keyframes`
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
`

const animationBottom = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, 1.1px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const animationBottomRight = keyframes`
0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(1.1px, 1.1px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const animationRight = keyframes`
0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(1.1px, 0, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const animationTop = keyframes`
0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, -1.1px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const animationTopLeft = keyframes`
0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(-1.1px, -1.1px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const animationBottomLeft = keyframes`
0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(-1.1px, 1.1px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    transform: translate3d(0, 0, 0);
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 65px);
`

export const Content = styled.div`
  width: 100%;
  max-width: 200px;
  /* animation: ${rotate} 2.5s linear infinite; */
  animation: ${scale} 1.4s linear infinite;
  padding: 1rem;

  .a {
    animation: ${animationBottom} 1.4s ease infinite;
  }

  .b {
    animation: ${animationBottomRight} 1.4s ease infinite;
    animation-delay: .2s;
  }

  .c {
    animation: ${animationRight} 1.4s ease infinite;
    animation-delay: .4s;
  }

  .d {
    animation: ${animationTop} 1.4s ease infinite;
    animation-delay: .6s;
  }

  .e {
    animation: ${animationTopLeft} 1.4s ease infinite;
    animation-delay: .8s;
  }

  .f {
    animation: ${animationTopLeft} 1.4s ease infinite;
    animation-delay: 1s;
  }

  .g{
    animation: ${animationBottomLeft} 1.4s ease infinite;
    animation-delay: 1.2s;
  }

`
