import styled from '@emotion/styled'
import { lighten } from 'polished'

type ContainerProps = {
  color: string;
  background?: string;
}
export const Container = styled.div<ContainerProps>`

  span {
    color: ${(props) => props.color};
    background: ${(props) => props.background ?? lighten('0.3', props.color)};
    padding: .2rem .6rem;
    border-radius: .4rem;
    font-weight: 600;
    font-size: .8rem;
  }

`
