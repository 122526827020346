import { CheckCircle } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

export type UnifiedEnergyBillSuccessProps = {
  

  setIsVisible: (visible: boolean) => void
}

export const UnifiedEnergyBillSuccess: React.FC<UnifiedEnergyBillSuccessProps> = ({ setIsVisible }) => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <CheckCircle sx={{ fontSize: 100, marginTop: 2, color: '#34C759' }}  />
      <Typography sx={{ marginTop: 3, marginBottom: 1 }}fontWeight={600} variant='h3'>Sucesso!</Typography>
      <Typography sx={{ marginBottom: 3 }} color='grey.400'>Sua conta única está ativada</Typography>
      <Button onClick={() => setIsVisible(false)}>Entendi</Button>
    </Box>
  )
}
