import React, { useState, useMemo, useCallback, PropsWithChildren, useContext } from 'react'
import { SidebarState } from './types'
import { useMediaQuery } from '@mui/material'
import { theme } from '~/utils'

export const SidebarContext = React.createContext<SidebarState>({
  isVisible: false,
  toggleSidebar: () => null,
  setSidebar: () => null
})

export const SidebarProvider: React.FC<PropsWithChildren> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isVisible, setIsVisible] = useState(isMobile ? false : true)

  const toggleSidebar = useCallback(() => setIsVisible(!isVisible), [isVisible])

  const providerValue = useMemo<SidebarState>(() => ({
    isVisible,
    toggleSidebar,
    setSidebar: setIsVisible
  }), [isVisible, toggleSidebar])

  return (
    <SidebarContext.Provider value={providerValue}>
      {props.children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => useContext(SidebarContext)
